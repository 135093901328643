export const Wall = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5 3.75H2.5C2.33424 3.75 2.17527 3.81585 2.05806 3.93306C1.94085 4.05027 1.875 4.20924 1.875 4.375V15.625C1.875 15.7908 1.94085 15.9497 2.05806 16.0669C2.17527 16.1842 2.33424 16.25 2.5 16.25H17.5C17.6658 16.25 17.8247 16.1842 17.9419 16.0669C18.0592 15.9497 18.125 15.7908 18.125 15.625V4.375C18.125 4.20924 18.0592 4.05027 17.9419 3.93306C17.8247 3.81585 17.6658 3.75 17.5 3.75ZM6.875 11.25V8.75H13.125V11.25H6.875ZM3.125 11.25V8.75H5.625V11.25H3.125ZM14.375 8.75H16.875V11.25H14.375V8.75ZM16.875 7.5H10.625V5H16.875V7.5ZM9.375 5V7.5H3.125V5H9.375ZM3.125 12.5H9.375V15H3.125V12.5ZM10.625 15V12.5H16.875V15H10.625Z"
      fill="#3E9B4F"
    />
  </svg>
);
