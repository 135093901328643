import React, { useState } from "react";
import { Typography, Button } from "@mui/material";
import { styled } from "@mui/system";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CustomButton from "../button/customButton";
import { theme } from "../../theme/theme";
import { CheckCircle } from "../../assets/svg/CheckCircle";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const PlanContainer = styled("div")(
  ({ theme, isUpgrade, subscription, noScroll }) => ({
    width: "25%",
    padding: theme.spacing(2),
    border: isUpgrade ? "0" : "1px solid #C1D0FF",
    backgroundColor: subscription
      ? "#ffff"
      : isUpgrade
        ? theme.palette.primary.main
        : "#E1E9FF",
    borderRadius: "8px",
    color: isUpgrade ? "#fff" : theme.palette.text.primary,
    maxHeight: noScroll ? "auto" : "55vh",
    overflowY: "auto",
    scrollbarWidth: "none", // Hide scrollbar for Firefox
    "&::-webkit-scrollbar": {
      display: "none", // Hide scrollbar for Chrome, Safari, and Edge
    },

    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
  })
);

const PlanInfo = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const FeatureList = styled("ul")(({ theme }) => ({
  paddingLeft: theme.spacing(0),
  listStyleType: "disc",
}));

const AccordionItem = styled("div")(({ theme, isExpanded }) => ({
  marginBottom: theme.spacing(1),
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: isExpanded ? theme.palette.action.hover : "transparent",
  cursor: "pointer",
}));

const AccordionTitle = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

const ActionRow = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "8px",
  marginBottom: "20px",
});

const CancelButton = styled(Button)(({ theme }) => ({
  fontWeight: 400,
  boxShadow: "none",
  height: 48,
  marginTop: 16,
  backgroundColor: "transparent",
  border: "1px solid #FF3B30",
  color: "#FF3B30",
  "&:hover": {
    border: "1px solid #FF3B30",
  },

  [theme.breakpoints.up("xl")]: {
    width: 204,
  },

  [theme.breakpoints.down("xl")]: {
    width: "auto",
    lineHeight: "normal",
  },
}));

const Plan = ({
  name,
  noScroll,
  price,
  subscription,
  cancelSubscription,
  keyFeatures,
  cancel,
  isUpgrade,
  translate,
  originalPrice = null,
  wrapperStyles,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setExpandedIndex(index === expandedIndex ? null : index);
  };

  const onTranslate = (text) => {
    if (translate) {
      return t(text);
    }

    return text;
  };

  return (
    <PlanContainer
      subscription={subscription}
      noScroll={noScroll}
      isUpgrade={isUpgrade}
      sx={wrapperStyles || undefined}
    >
      <PlanInfo>
        {!isUpgrade && !subscription && (
          <Typography variant="h6" color={theme.palette.primary.main}>
            {t("Current Plan")}
          </Typography>
        )}
        {subscription ? (
          <Typography
            sx={{
              fontSize: 16,
              color: "#202020",
              display: "flex",
              alignItems: "center",
              gap: "8px",
              mb: 3,
            }}
            variant="h6"
          >
            {onTranslate(name)}
            <CheckCircle />
          </Typography>
        ) : (
          <Typography variant="h6">{onTranslate(name)}</Typography>
        )}
        <div style={{ display: "flex", alignItems: "flex-end" }}>
          <Typography
            variant="h4"
            style={{ marginRight: 4, fontWeight: "bold" }}
          >
            {price}
          </Typography>
          <Typography
            variant="body2"
            style={{ textDecoration: "line-through", marginRight: 4 }}
          >
            {originalPrice}
          </Typography>
          <Typography sx={{ paddingBottom: "6px" }} variant="body2">
            {t("per month")}
          </Typography>
        </div>

        {cancel && !isUpgrade && (
          <CancelButton onClick={cancelSubscription} variant="outlined">
            {t("Cancel Subscription")}
          </CancelButton>
        )}
      </PlanInfo>

      {isUpgrade && (
        <ActionRow>
          <CustomButton
            onClick={() => navigate("/my-account?section=subscription")}
            text={"Upgrade"}
          />
          <Typography variant="body2">
            {t("Know more about this plan")}
          </Typography>
        </ActionRow>
      )}
      {keyFeatures && keyFeatures.length > 0 && (
        <div>
          <Typography variant="body1" sx={{ opacity: 0.7, color: "white" }}>
            {t("Key Features")}:
          </Typography>
          <FeatureList
            id="FeatureList"
            sx={{
              maxHeight: "100px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {keyFeatures.map((feature, index) => (
              <AccordionItem
                key={index}
                isExpanded={expandedIndex === index}
                onClick={() => handleAccordionClick(index)}
              >
                <AccordionTitle>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <CheckCircleIcon
                      fontSize="small"
                      style={{ marginRight: 8 }}
                    />
                    <Typography variant="body2">
                      <strong>{onTranslate(feature.feature)}</strong>
                    </Typography>
                  </div>
                  {expandedIndex === index ? (
                    <Typography style={{ color: "#fff" }}>▲</Typography>
                  ) : (
                    <Typography style={{ color: "#fff" }}>▼</Typography>
                  )}
                </AccordionTitle>
                {expandedIndex === index && (
                  <Typography variant="body2" style={{ marginTop: 8 }}>
                    {onTranslate(feature.description)}
                  </Typography>
                )}
              </AccordionItem>
            ))}
          </FeatureList>
        </div>
      )}
    </PlanContainer>
  );
};

export default Plan;
