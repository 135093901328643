import React, { useRef } from "react";
import UploadImage from "./ImageUpload";
import UploadedImages from "./UploadedImages";
import { styled } from "@mui/material";
import { useTranslation } from "react-i18next";

const HiddenInput = styled("input")({
    display: "none",
});

const Upload = ({ onFileSelect, onFileDelete, images }) => {
    const { t } = useTranslation();
    const inputRef = useRef(null);

    const handleFiles = (files) => {
        const file = files[0];
        if (
            file &&
            [
                "image/jpeg",
                "image/png",
                "video/mp4",
                "video/x-msvideo",
            ].includes(file.type) &&
            file.size <= 100 * 1024 * 1024
        ) {
            onFileSelect(file);
        } else {
            alert(
                t(
                    "Please upload a JPG, PNG, AVI, or MP4 file, no larger than 100MB."
                )
            );
        }
    };

    const handleChange = (e) => {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            handleFiles(e.target.files);
        }
    };

    return (
        <>
            {images.length ? (
                <UploadedImages
                    inputRef={inputRef}
                    images={images}
                    onDelete={onFileDelete}
                />
            ) : (
                <UploadImage
                    btnText={t("Uplad Video")}
                    description={t("AVI, MP4 file size no more than 100MB")}
                    inputRef={inputRef}
                    handleFiles={handleFiles}
                />
            )}
            <HiddenInput
                ref={inputRef}
                type="file"
                accept="image/jpeg,image/png,video/mp4,video/x-msvideo"
                onChange={handleChange}
            />
        </>
    );
};

export default Upload;
