import React from "react";

const ChatDots = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.25 4.5H3.75003C3.3522 4.5 2.97067 4.65804 2.68937 4.93934C2.40806 5.22064 2.25003 5.60218 2.25003 6V21C2.2483 21.286 2.32921 21.5665 2.48305 21.8076C2.63689 22.0488 2.8571 22.2404 3.11721 22.3594C3.31543 22.4517 3.53138 22.4997 3.75003 22.5C4.10216 22.4992 4.4426 22.3736 4.71096 22.1456L4.7194 22.1391L7.78128 19.5H20.25C20.6479 19.5 21.0294 19.342 21.3107 19.0607C21.592 18.7794 21.75 18.3978 21.75 18V6C21.75 5.60218 21.592 5.22064 21.3107 4.93934C21.0294 4.65804 20.6479 4.5 20.25 4.5ZM7.87503 13.125C7.65252 13.125 7.43502 13.059 7.25001 12.9354C7.06501 12.8118 6.92081 12.6361 6.83566 12.4305C6.75051 12.225 6.72824 11.9988 6.77164 11.7805C6.81505 11.5623 6.9222 11.3618 7.07953 11.2045C7.23687 11.0472 7.43732 10.94 7.65555 10.8966C7.87378 10.8532 8.09998 10.8755 8.30555 10.9606C8.51111 11.0458 8.68681 11.19 8.81043 11.375C8.93405 11.56 9.00003 11.7775 9.00003 12C9.00003 12.2984 8.8815 12.5845 8.67052 12.7955C8.45954 13.0065 8.1734 13.125 7.87503 13.125ZM12 13.125C11.7775 13.125 11.56 13.059 11.375 12.9354C11.19 12.8118 11.0458 12.6361 10.9607 12.4305C10.8755 12.225 10.8532 11.9988 10.8966 11.7805C10.9401 11.5623 11.0472 11.3618 11.2045 11.2045C11.3619 11.0472 11.5623 10.94 11.7806 10.8966C11.9988 10.8532 12.225 10.8755 12.4305 10.9606C12.6361 11.0458 12.8118 11.19 12.9354 11.375C13.059 11.56 13.125 11.7775 13.125 12C13.125 12.2984 13.0065 12.5845 12.7955 12.7955C12.5845 13.0065 12.2984 13.125 12 13.125ZM16.125 13.125C15.9025 13.125 15.685 13.059 15.5 12.9354C15.315 12.8118 15.1708 12.6361 15.0857 12.4305C15.0005 12.225 14.9782 11.9988 15.0216 11.7805C15.0651 11.5623 15.1722 11.3618 15.3295 11.2045C15.4869 11.0472 15.6873 10.94 15.9056 10.8966C16.1238 10.8532 16.35 10.8755 16.5555 10.9606C16.7611 11.0458 16.9368 11.19 17.0604 11.375C17.184 11.56 17.25 11.7775 17.25 12C17.25 12.2984 17.1315 12.5845 16.9205 12.7955C16.7095 13.0065 16.4234 13.125 16.125 13.125Z"
        fill="#3E63DD"
      />
    </svg>
  );
};

export default ChatDots;
