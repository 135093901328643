import React from "react";
import LoggedInLayout from "../LoggedInLayout/LoggedInLayout";
import OwnerAccountSection from "../../components/sections/OwnerAccount/OwnerAccount";
import LoggedInFooter from "../LoggedInFooter/LoggedInFooter";

const OwnerAccount = () => {
  return (
    <LoggedInLayout hideSubscription>
      <LoggedInFooter>
        <OwnerAccountSection />
      </LoggedInFooter>
    </LoggedInLayout>
  );
};

export default OwnerAccount;
