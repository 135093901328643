export const Trash = ({ stroke }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.25 3.58398L14.7336 11.9382C14.6016 14.0727 14.5356 15.1399 14.0006 15.9072C13.7361 16.2866 13.3955 16.6067 13.0006 16.8473C12.2018 17.334 11.1325 17.334 8.99395 17.334C6.8526 17.334 5.78192 17.334 4.98254 16.8464C4.58733 16.6054 4.24666 16.2847 3.98224 15.9047C3.4474 15.1361 3.38288 14.0674 3.25384 11.9299L2.75 3.58398"
      stroke={stroke || "#141B34"}
      stroke-width="1.25"
      stroke-linecap="round"
    />
    <path
      d="M1.5 3.58366H16.5M12.3798 3.58366L11.8109 2.4101C11.433 1.63054 11.244 1.24076 10.9181 0.997664C10.8458 0.943742 10.7692 0.895778 10.6892 0.854243C10.3283 0.666992 9.8951 0.666992 9.02877 0.666992C8.14069 0.666992 7.69665 0.666992 7.32974 0.862092C7.24842 0.905332 7.17082 0.955239 7.09774 1.0113C6.76803 1.26424 6.58386 1.66829 6.2155 2.47637L5.71077 3.58366"
      stroke={stroke || "#141B34"}
      stroke-width="1.25"
      stroke-linecap="round"
    />
  </svg>
);
