import React from "react";

const ImageComponent = ({
  src = "",
  name,
  width,
  height,
  alt = "",
  style = {},
  onClick = () => {},
}) => {
  const imageSrc = src || `/${name}.svg`;

  return (
    <img
      src={imageSrc}
      alt={alt}
      width={width || "auto"}
      height={height}
      style={{ objectFit: "fill", ...style }}
      onClick={onClick}
    />
  );
};

export default ImageComponent;