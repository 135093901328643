import React from "react";
import LoggedInLayout from "../LoggedInLayout/LoggedInLayout";
import EditProfileSection from "../../components/sections/EditProfile/EditProfile";

const EditProfile = () => {
  return (
    <LoggedInLayout>
      <EditProfileSection />
    </LoggedInLayout>
  );
};

export default EditProfile;
