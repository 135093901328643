export const UserSquare = () => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.11163 16.2265L6.07668 16.3125H6.16953H15.4398H15.5327L15.4977 16.2265C15.1739 15.4293 14.6534 14.7272 13.9846 14.1858L13.9846 14.1857C13.0841 13.4589 11.9619 13.0624 10.8047 13.0624C9.6475 13.0624 8.52523 13.4589 7.6248 14.1857L7.62473 14.1858C6.95601 14.7272 6.43543 15.4293 6.11163 16.2265ZM17.0547 16.3125H17.1172V16.25V3.75V3.6875H17.0547H4.55469H4.49219V3.75V16.25V16.3125H4.55469H4.84141H4.88728L4.90103 16.2687C5.17615 15.393 5.64292 14.5895 6.26743 13.9167C6.89194 13.2439 7.65849 12.7186 8.51139 12.3792L8.61227 12.339L8.52689 12.2719C7.92378 11.7982 7.48339 11.1482 7.267 10.4125C7.05062 9.67672 7.06903 8.89181 7.31965 8.167C7.57028 7.4422 8.04065 6.81357 8.6653 6.36862C9.28994 5.92368 10.0378 5.68457 10.8047 5.68457C11.5716 5.68457 12.3194 5.92368 12.9441 6.36862C13.5687 6.81357 14.0391 7.4422 14.2897 8.167C14.5403 8.89181 14.5588 9.67672 14.3424 10.4125C14.126 11.1482 13.6856 11.7982 13.0825 12.2719L12.9971 12.339L13.098 12.3792C13.9509 12.7186 14.7174 13.2439 15.3419 13.9167C15.9665 14.5895 16.4332 15.393 16.7083 16.2687L16.7221 16.3125H16.768H17.0547ZM4.55469 2.5625H17.0547C17.3696 2.5625 17.6717 2.68761 17.8944 2.91031C18.1171 3.13301 18.2422 3.43506 18.2422 3.75V16.25C18.2422 16.5649 18.1171 16.867 17.8944 17.0897C17.6717 17.3124 17.3696 17.4375 17.0547 17.4375H4.55469C4.23974 17.4375 3.9377 17.3124 3.715 17.0897C3.4923 16.867 3.36719 16.5649 3.36719 16.25V3.75C3.36719 3.43506 3.4923 3.13301 3.715 2.91031C3.9377 2.68761 4.23974 2.5625 4.55469 2.5625ZM8.67405 7.95135C8.39248 8.37275 8.24219 8.86819 8.24219 9.375C8.24219 10.0546 8.51216 10.7064 8.99273 11.187C9.47329 11.6675 10.1251 11.9375 10.8047 11.9375C11.3115 11.9375 11.8069 11.7872 12.2283 11.5056C12.6497 11.2241 12.9782 10.8239 13.1721 10.3556C13.3661 9.88739 13.4168 9.37216 13.318 8.87508C13.2191 8.378 12.975 7.92141 12.6166 7.56304C12.2583 7.20467 11.8017 6.96061 11.3046 6.86174C10.8075 6.76286 10.2923 6.81361 9.82406 7.00756C9.35583 7.20151 8.95562 7.52995 8.67405 7.95135Z"
      fill="#343330"
      stroke="#202020"
      stroke-width="0.125"
    />
  </svg>
);
