import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import BillingHistoryTable from "./BillingHistoryTable";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getBillingHistory } from "../../../../store/slices/subscriptionsSlice";

const GlobalContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const BillingHistory = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBillingHistory());
  }, [dispatch]);

  return (
    <Box sx={{ px: 3, width: "100%" }}>
      <GlobalContainer>
        <Typography sx={{ color: "#202020" }} variant="h4" gutterBottom>
          {t("Billing History")}
        </Typography>

        <BillingHistoryTable />
      </GlobalContainer>
    </Box>
  );
};

export default BillingHistory;
