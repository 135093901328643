import { Box, IconButton } from "@mui/material";
import ImageComponent from "../Image/Image";
import Avatar from "../avatar/Avatar";
import UpgradePlan from "../upgradePlan/UpgradePlan";
import { useMemo, useState } from "react";
import ProfileDropdown from "./ProfileDropdown";
import { useSelector } from "react-redux";
import { selectSubscriptions } from "../../store/slices/subscriptionsSlice";
import { selectAccount } from "../../store/slices/accountSlice";
import { useTranslation } from "react-i18next";
import { PersonalAccountIcon } from "../personalAccountIcon/personalAccountIcon";
import { Link } from "react-router-dom";

const LoggedInHeader = ({ hideProfile, hideSubscription }) => {
    const { t } = useTranslation();
    const { subscription } = useSelector(selectSubscriptions);
    const { data: userData } = useSelector(selectAccount);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const subscriptionPlan = useMemo(() => {
        return (
            subscription?.data &&
            subscription?.data.find(
                (subscription) => subscription.status === "active"
            )
        );
    }, [subscription]);

    const handleMouseEnter = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMouseLeave = () => {
        setAnchorEl(null);
    };

    const getInitials = (firstName, lastName) => {
        const firstInitial = firstName ? firstName[0].toUpperCase() : "";
        const lastInitial = lastName ? lastName[0].toUpperCase() : "";
        return `${firstInitial}${lastInitial}`;
    };

    const userInitials = useMemo(() => {
        return getInitials(userData?.firstname, userData?.lastname);
    }, [userData]);

    return (
        <Box
            id="LoggedInHeader"
            sx={(theme) => ({
                height: "64px",
                background: "#202020",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                px: "24px",
            })}
        >
            <Link to="/" sx={{ textDecoration: "none" }}>
                <ImageComponent
                    name="logo-white"
                    height={26}
                    alt="HOME AI Logo"
                />
            </Link>

            {!hideProfile && (
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "40px",
                        textDecoration: "none",
                    }}
                >
                    {!hideSubscription &&
                        (!subscriptionPlan ||
                            subscriptionPlan.plan === "free") && (
                            <UpgradePlan
                                title={t("Upgrade Plan")}
                                text={t("Real-Time Market Scan and more")}
                                color="#FFCC00"
                            />
                        )}
                </Box>
            )}
            <PersonalAccountIcon />
        </Box>
    );
};

export default LoggedInHeader;
