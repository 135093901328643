import React from "react";
import {
  Container,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { theme } from "../../../theme/theme";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { faqData } from "../../../faqData";
import { useTranslation } from "react-i18next";
const FAQSection = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: theme.palette.background.lightBlue,
        pt: 8,
        pb: 2,
      }}
    >
      <Container
        maxWidth="md"
        sx={{
          [theme.breakpoints.down("sm")]: {
            px: 2,
          },
        }}
      >
        <Typography
          variant="h5"
          fontWeight={700}
          fontSize={"35px"}
          align="center"
          gutterBottom
        >
          {t("FAQ")}
        </Typography>
        {faqData.map((section, index) => (
          <Box key={index} mb={4}>
            <Typography
              sx={{
                fontSize: "22px",
                fontWeight: 700,
              }}
            >
              {t(section.category)}
            </Typography>
            <List
              sx={{
                pl: 2,
              }}
            >
              {section.questions.map((item, idx) => (
                <ListItem key={idx} disableGutters>
                  <ListItemText
                    primary={
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: 500,
                          color: "black",
                          fontSize: "16px",
                          fontWeight: 700,
                        }}
                      >
                        <FiberManualRecordIcon
                          sx={{
                            fontSize: "8px",
                            mr: 1,
                            ml: -3,
                          }}
                        />{" "}
                        {t(item.question)}
                      </Typography>
                    }
                    secondary={
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          pt: 1,
                          fontSize: "16px",
                          fontWeight: 400,
                        }}
                      >
                        {t(item.answer)}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        ))}
      </Container>
    </Box>
  );
};

export default FAQSection;
