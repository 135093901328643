export const SuitCase = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.875 4.375H13.75V3.75C13.75 3.25272 13.5525 2.77581 13.2008 2.42417C12.8492 2.07254 12.3723 1.875 11.875 1.875H8.125C7.62772 1.875 7.15081 2.07254 6.79917 2.42417C6.44754 2.77581 6.25 3.25272 6.25 3.75V4.375H3.125C2.79348 4.375 2.47554 4.5067 2.24112 4.74112C2.0067 4.97554 1.875 5.29348 1.875 5.625V15.625C1.875 15.9565 2.0067 16.2745 2.24112 16.5089C2.47554 16.7433 2.79348 16.875 3.125 16.875H16.875C17.2065 16.875 17.5245 16.7433 17.7589 16.5089C17.9933 16.2745 18.125 15.9565 18.125 15.625V5.625C18.125 5.29348 17.9933 4.97554 17.7589 4.74112C17.5245 4.5067 17.2065 4.375 16.875 4.375ZM7.5 3.75C7.5 3.58424 7.56585 3.42527 7.68306 3.30806C7.80027 3.19085 7.95924 3.125 8.125 3.125H11.875C12.0408 3.125 12.1997 3.19085 12.3169 3.30806C12.4342 3.42527 12.5 3.58424 12.5 3.75V4.375H7.5V3.75ZM16.875 5.625V11.25H3.125V5.625H16.875ZM16.875 15.625H3.125V12.5H16.875V15.625Z"
      fill="#3358D4"
    />
  </svg>
);
