import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoggedInLayout from "../LoggedInLayout/LoggedInLayout";
import MyAccountSection from "../../components/sections/MyAccount/MyAccount";
import LoggedInFooter from "../LoggedInFooter/LoggedInFooter";
import {
  fetchUserProfile,
  selectAccount,
} from "../../store/slices/accountSlice";
import { getSubscription } from "../../store/slices/subscriptionsSlice";

const MyAccount = () => {
  const dispatch = useDispatch();
  const { isSuccess, data } = useSelector(selectAccount);

  useEffect(() => {
    dispatch(fetchUserProfile());
    dispatch(getSubscription());
  }, [dispatch]);

  return (
    <LoggedInLayout>
      <LoggedInFooter>
        {isSuccess && <MyAccountSection data={data} />}
      </LoggedInFooter>
    </LoggedInLayout>
  );
};

export default MyAccount;
