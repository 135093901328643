import "./App.css";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./routes/AppRoutes";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme/theme";
import { HelmetProvider } from 'react-helmet-async';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <HelmetProvider>
          <AppRoutes></AppRoutes>
        </HelmetProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}
export default App;
