import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import authService from "../../services/authService";

const initialState = {
  createDocument: {
    data: [],
    isLoading: false,
    error: null,
  },
  documents: {
    data: null,
    isLoading: false,
    error: null,
  },
  updateDocument: {
    data: null,
    isLoading: false,
    error: null,
  },
  deleteDocument: {
    data: null,
    isLoading: false,
    error: null,
  },
};

const API_URL = process.env.REACT_APP_BASE_URL;

export const getDocuments = createAsyncThunk(
  "documents/get-documents",
  async (_, thunkAPI) => {
    try {
      const user = authService.getCurrentUser();
      const response = await axios.get(`${API_URL}/documents/`, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const createDocument = createAsyncThunk(
  "documents/create-document",
  async (body, thunkAPI) => {
    try {
      const user = authService.getCurrentUser();
      const response = await axios.post(`${API_URL}/documents/`, body, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateDocument = createAsyncThunk(
  "documents/update-document",
  async ({ documentId, body }, thunkAPI) => {
    try {
      const user = authService.getCurrentUser();
      const response = await axios.put(
        `${API_URL}/documents/${documentId}`,
        body,
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteDocument = createAsyncThunk(
  "documents/delete-document",
  async (documentId, thunkAPI) => {
    try {
      const user = authService.getCurrentUser();
      const response = await axios.delete(
        `${API_URL}/documents/${documentId}`,
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const documentsSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDocuments.pending, (state) => {
        state.documents.isLoading = true;
        state.documents.error = null;
      })
      .addCase(getDocuments.fulfilled, (state, action) => {
        state.documents.isLoading = false;
        state.documents.data = action.payload;
      })
      .addCase(getDocuments.rejected, (state, action) => {
        state.documents.isLoading = false;
        state.documents.error = action.payload;
      })

      .addCase(createDocument.pending, (state) => {
        state.createDocument.isLoading = true;
        state.createDocument.error = null;
      })
      .addCase(createDocument.fulfilled, (state, action) => {
        state.createDocument.isLoading = false;
        state.createDocument.data = action.payload;
      })
      .addCase(createDocument.rejected, (state, action) => {
        state.createDocument.isLoading = false;
        state.createDocument.error = action.payload;
      })

      .addCase(updateDocument.pending, (state) => {
        state.updateDocument.isLoading = true;
        state.updateDocument.error = null;
      })
      .addCase(updateDocument.fulfilled, (state, action) => {
        state.updateDocument.isLoading = false;
        state.updateDocument.data = action.payload;
      })
      .addCase(updateDocument.rejected, (state, action) => {
        state.updateDocument.isLoading = false;
        state.updateDocument.error = action.payload;
      })

      .addCase(deleteDocument.pending, (state) => {
        state.deleteDocument.isLoading = true;
        state.deleteDocument.error = null;
      })
      .addCase(deleteDocument.fulfilled, (state, action) => {
        state.deleteDocument.isLoading = false;
        state.deleteDocument.data = action.payload;
      })
      .addCase(deleteDocument.rejected, (state, action) => {
        state.deleteDocument.isLoading = false;
        state.deleteDocument.error = action.payload;
      });
  },
});

export const selectDocuments = (state) => state.documents;

export default documentsSlice.reducer;
