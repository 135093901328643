import React, { useEffect, useState } from "react";
import {
    Modal,
    Box,
    Typography,
    Button,
    Select,
    MenuItem,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
    updateTakeItOut,
    selectViewing,
} from "../../../../store/slices/viewingSlice";
import { useTranslation } from "react-i18next";

const TakeItOutUpdateModal = ({ open, handleClose, defaultValue }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [viewingsCount, setViewingsCount] = useState("");
    const { getTakeItOut: takeItOutState } = useSelector(selectViewing);

    // Effect to set viewingsCount when the modal opens
    // useEffect(() => {
    //     if (open && takeItOutState.data && takeItOutState.data[0]) {
    //         setViewingsCount(takeItOutState.data[0].number || "");
    //     }
    // }, [open, takeItOutState]);

    const handleViewingsCountChange = (event) => {
        setViewingsCount(event.target.value);
    };

    const handleConfirm = () => {
        if (takeItOutState.data && takeItOutState.data[0].id) {
            dispatch(
                updateTakeItOut({
                    takeItOutId: takeItOutState.data[0].id,
                    body: {
                        number: viewingsCount,
                        status: "active",
                        type: "immediately",
                    },
                })
            );
        }
        handleClose();
        setViewingsCount("");
    };

    const viewingsOptions = Array.from({ length: 20 }, (_, i) => i + 1);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="take-it-out-modal-title"
            aria-describedby="take-it-out-modal-description"
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                }}
            >
                <Typography variant="h6" component="h2" gutterBottom>
                    {t("Take it out after viewings")}
                </Typography>
                <Select
                    fullWidth
                    value={viewingsCount}
                    onChange={handleViewingsCountChange}
                    displayEmpty
                    variant="outlined"
                    sx={{ my: 2 }}
                >
                    <MenuItem value="" disabled selected>
                        {t("Select number of viewings")}
                    </MenuItem>
                    {viewingsOptions.map((number) => (
                        <MenuItem key={number} value={number.toString()}>
                            {number}
                        </MenuItem>
                    ))}
                </Select>
                <Box
                    sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}
                >
                    <Button onClick={handleClose} sx={{ mr: 2 }}>
                        {t("Cancel")}
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleConfirm}
                        disabled={!viewingsCount}
                    >
                        {t("Confirm")}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default TakeItOutUpdateModal;
