import { Box, Typography, Button } from "@mui/material";
import React, { useEffect, useMemo, useRef } from "react";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { theme } from "../../theme/theme";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

const buttonStyling = {
  width: "100%",
  backgroundColor: "transparent",
  color: "#1C2024",
  border: `1px solid #0007149F`,
  height: "40px",
};

export const Insights = ({
  insights,
  adjust,
  emptyInsights,
  title,
  icon,
  isShowInsights,
}) => {
  const { t } = useTranslation();
  const bottomRef = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();

  const totalInsightsLength = useMemo(
    () =>
      insights?.reduce(
        (sum, insightGroup) => sum + insightGroup?.insights.length,
        0
      ),
    [insights]
  );

  useEffect(() => {
    // Scroll to the bottom whenever the total length of nested insights changes
    if (insights && totalInsightsLength > 0) {
      bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [totalInsightsLength, insights]);

  function toggleSearchResults() {
    const params = new URLSearchParams(location.search);

    if (params.has("searchResults")) {
      // If "searchResults" exists, remove it
      params.delete("searchResults");
    } else {
      // If "searchResults" does not exist, add it
      params.set("searchResults", "true");
    }

    // Navigate with updated search parameters
    navigate(`${location.pathname}?${params.toString()}`, { replace: true });
  }

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        pl: 5,
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Box sx={{ pr: 0.5, pt: 3 }}>
        <Typography sx={{ pb: 2, color: "black", fontWeight: 600 }}>
          {title}
        </Typography>
      </Box>

      {isShowInsights && insights && insights.length > 0 && !emptyInsights ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "100%",
          }}
        >
          {insights.map((insightGroup, index) => (
            <Box key={index} sx={{ mb: 3 }}>
              <Typography sx={{ fontWeight: 500, color: "black", mb: 1 }}>
                {t(insightGroup.step)}:
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 1,
                }}
              >
                {insightGroup.insights.map((item, itemIndex) => (
                  <Box
                    id="insightGroup.insights"
                    key={itemIndex}
                    sx={{
                      backgroundColor: theme.palette.background.lightBlue,
                      color: "#4f6ece",
                      border: "1px solid #4f6ece",
                      borderRadius: "5px",
                      padding: "4px 8px",
                      fontSize: "14px",
                    }}
                  >
                    {t(item)}
                  </Box>
                ))}
              </Box>
            </Box>
          ))}
          {adjust && (
            <Button
              onClick={() => toggleSearchResults()}
              fullWidth
              sx={{
                ...buttonStyling,
                lineHeight: "normal",
              }}
            >
              {t("Adjust parameters")}
            </Button>
          )}
          <div ref={bottomRef} /> {/* Ref element for auto-scrolling */}
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          {icon || (
            <AutoAwesomeIcon
              sx={{
                backgroundColor: "#edf2fe",
                color: "#8da4ef",
                height: "30px",
                width: "30px",
                borderRadius: "100%",
                p: 1,
              }}
            />
          )}
          <Typography
            sx={{
              fontWeight: 500,
              color: "black",
              py: 1.5,
            }}
          >
            {t("No insights")}
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
            }}
          >
            {t(
              "Here I will show your priorities based on your answers to help you understand them better and find the perfect apartment."
            )}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
