import { useState, useEffect, useRef } from "react";

export const useRandomUserCounter = () => {
  const maxUsers = 50;
  const [userCount, setUserCount] = useState(getInitialCount);
  const [targetCount, setTargetCount] = useState(getInitialTargetCount);
  const incrementTimeoutRef = useRef(null);
  const resetTimeoutRef = useRef(null);

  useEffect(() => {
    const scheduleMidnightReset = () => {
      // Clear any existing reset timeout
      if (resetTimeoutRef.current) {
        clearTimeout(resetTimeoutRef.current);
      }

      const now = new Date();
      const midnight = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 1,
        0,
        0,
        0,
        0
      );
      const timeUntilMidnight = midnight.getTime() - now.getTime();

      resetTimeoutRef.current = setTimeout(() => {
        resetCountForNewDay();
        scheduleMidnightReset(); // Schedule the next midnight reset
      }, timeUntilMidnight);
    };

    const resetCountForNewDay = () => {
      const newTarget = getRandomTargetCount();
      setTargetCount(newTarget);
      setUserCount(0);
      localStorage.setItem("userCount", 0);
      localStorage.setItem("targetCount", newTarget);
      scheduleNextIncrement(); // Restart incrementing after reset
    };

    const scheduleNextIncrement = () => {
      // Clear any existing increment timeout
      if (incrementTimeoutRef.current) {
        clearTimeout(incrementTimeoutRef.current);
      }

      const now = new Date();
      const midnight = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 1,
        0,
        0,
        0,
        0
      );
      const timeUntilMidnight = midnight.getTime() - now.getTime();

      const incrementsLeft = targetCount - userCount;
      if (incrementsLeft <= 0 || timeUntilMidnight <= 0) {
        return; // No more increments needed
      }

      // Average interval between increments
      const averageInterval = timeUntilMidnight / incrementsLeft;

      // Randomize the interval around the average (±50%)
      const minInterval = averageInterval * 0.5;
      const maxInterval = averageInterval * 1.5;
      const intervalTime =
        Math.random() * (maxInterval - minInterval) + minInterval;

      incrementTimeoutRef.current = setTimeout(() => {
        setUserCount((prevCount) => {
          const increment = Math.floor(Math.random() * 5) + 1; // Increment by 1 to 5
          const newCount = Math.min(
            prevCount + increment,
            maxUsers,
            targetCount
          );
          localStorage.setItem("userCount", newCount);
          return newCount;
        });
        scheduleNextIncrement(); // Schedule the next increment
      }, intervalTime);
    };

    scheduleNextIncrement();
    scheduleMidnightReset();

    return () => {
      // Cleanup timeouts
      if (incrementTimeoutRef.current) {
        clearTimeout(incrementTimeoutRef.current);
      }
      if (resetTimeoutRef.current) {
        clearTimeout(resetTimeoutRef.current);
      }
    };
  }, [targetCount, userCount]);

  // Listen for changes to localStorage in other tabs
  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "userCount") {
        const newCount = parseInt(event.newValue, 10);
        setUserCount(isNaN(newCount) ? 0 : newCount);
      } else if (event.key === "targetCount") {
        const newTarget = parseInt(event.newValue, 10);
        setTargetCount(isNaN(newTarget) ? getRandomTargetCount() : newTarget);
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  function getInitialCount() {
    // Load targetCount from localStorage
    const targetCount = localStorage.getItem("targetCount");
  
    // Load userCount from localStorage if available
    const savedCount = localStorage.getItem("userCount");
  
    // If there is no saved userCount, generate a random number lower than targetCount
    if (!savedCount && targetCount) {
      const randomCount = Math.floor(Math.random() * parseInt(targetCount, 10));
      localStorage.setItem("userCount", randomCount); 
      return randomCount;
    }
  
    return savedCount ; 
  }

  function getInitialTargetCount() {
    // Load from localStorage or set a new target
    const savedTarget = localStorage.getItem("targetCount");
    if (savedTarget) {
      return parseInt(savedTarget, 10);
    } else {
      const newTarget = getRandomTargetCount();
      localStorage.setItem("targetCount", newTarget);
      return newTarget;
    }
  }

  function getRandomTargetCount() {
    // Random target between 300 and 500
    return Math.floor(Math.random() * (50 - 20 + 1)) + 20;
  }

  return userCount;
};
