export const Bank = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.875 8.12461H3.75V13.1246H2.5C2.33424 13.1246 2.17527 13.1905 2.05806 13.3077C1.94085 13.4249 1.875 13.5839 1.875 13.7496C1.875 13.9154 1.94085 14.0743 2.05806 14.1916C2.17527 14.3088 2.33424 14.3746 2.5 14.3746H17.5C17.6658 14.3746 17.8247 14.3088 17.9419 14.1916C18.0592 14.0743 18.125 13.9154 18.125 13.7496C18.125 13.5839 18.0592 13.4249 17.9419 13.3077C17.8247 13.1905 17.6658 13.1246 17.5 13.1246H16.25V8.12461H18.125C18.261 8.12447 18.3932 8.07999 18.5016 7.9979C18.6101 7.91582 18.6887 7.80061 18.7258 7.66976C18.7628 7.5389 18.7561 7.39955 18.7068 7.27283C18.6574 7.14611 18.5681 7.03894 18.4523 6.96758L10.3273 1.96758C10.2289 1.90705 10.1156 1.875 10 1.875C9.88442 1.875 9.77111 1.90705 9.67266 1.96758L1.54766 6.96758C1.4319 7.03894 1.34257 7.14611 1.29323 7.27283C1.24388 7.39955 1.23722 7.5389 1.27424 7.66976C1.31126 7.80061 1.38994 7.91582 1.49836 7.9979C1.60678 8.07999 1.73901 8.12447 1.875 8.12461ZM5 8.12461H7.5V13.1246H5V8.12461ZM11.25 8.12461V13.1246H8.75V8.12461H11.25ZM15 13.1246H12.5V8.12461H15V13.1246ZM10 3.2332L15.9172 6.87461H4.08281L10 3.2332ZM19.375 16.2496C19.375 16.4154 19.3092 16.5743 19.1919 16.6916C19.0747 16.8088 18.9158 16.8746 18.75 16.8746H1.25C1.08424 16.8746 0.925268 16.8088 0.808058 16.6916C0.690848 16.5743 0.625 16.4154 0.625 16.2496C0.625 16.0839 0.690848 15.9249 0.808058 15.8077C0.925268 15.6905 1.08424 15.6246 1.25 15.6246H18.75C18.9158 15.6246 19.0747 15.6905 19.1919 15.8077C19.3092 15.9249 19.375 16.0839 19.375 16.2496Z"
      fill="#3E9B4F"
    />
  </svg>
);
