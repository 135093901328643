import React, { useState } from "react";
import { Modal, Box, Typography, Button, IconButton } from "@mui/material";
import Upload from "../../../../components/chatUpload";
import CloseIcon from "@mui/icons-material/Close";

const SmartViewModal = ({ open, onClose }) => {
  const [file, setFile] = useState(null);
  const [uploadedImages, setUploadedImages] = useState([]);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.size <= 100 * 1024 * 1024) {
      // 100MB limit
      setFile(selectedFile);
    } else {
      alert("Please select a file no larger than 100MB.");
    }
  };

  const handleUpload = () => {
    if (file) {
      // Implement your file upload logic here
      console.log("Uploading file:", file);
      // After successful upload, you might want to close the modal or show a success message
    } else {
      alert("Please select a file to upload.");
    }
  };

  const handleFileSelect = (file) => {
    const index = uploadedImages.length;
    const imageUrl = URL.createObjectURL(file);
    const image = {
      name: file.name,
      type: file.type,
      size: file.size,
      progress: 0,
      url: imageUrl,
    };
    setUploadedImages([...uploadedImages, image]);

    setTimeout(() => {
      setUploadedImages((images) => {
        const res = [...images];
        res[index].progress = 100;
        return res;
      });
      setTimeout(() => {
        setUploadedImages((images) => {
          const res = [...images];
          res[index].completed = true;
          return res;
        });
      }, 500);
    }, 1400);
  };

  const onFileDelete = (index) => {
    setUploadedImages((images) => {
      const res = [...images];
      res.splice(index, 1);
      return res;
    });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="smart-view-modal-title"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 800,
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            pl: 4,
            pr: 4,
            marginTop: "17px",
            marginBottom: "17px",
          }}
        >
          <Typography id="smart-view-modal-title" variant="h6" component="h2">
            SmartView Experience
          </Typography>
          <IconButton onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ border: "1px solid #ddd!important", mb: 2 }} />

        <Typography variant="h6" sx={{ mb: 1, pl: 4, pr: 4 }}>
          Upload a video
        </Typography>
        <Typography variant="body2" sx={{ mb: 3, pl: 4, pr: 4 }}>
          Record a quick video with your smartphone or camera. Highlight
          attractive aspects like the view, modern kitchen, or spacious living
          areas, and upload it to our platform for potential tenants to preview.
        </Typography>

        <Box sx={{ p: 2, mt: 2, pl: 4, pr: 4 }}>
          <Upload
            images={uploadedImages}
            onFileSelect={handleFileSelect}
            onFileDelete={onFileDelete}
          />
        </Box>
        <Box sx={{ border: "1px solid #ddd", marginTop: "24px" }} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            pl: 4,
            pr: 4,
            marginTop: "24px",
            marginBottom: "24px",
          }}
        >
          <Button onClick={onClose} sx={{ color: "primary.main" }}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleUpload}
            sx={{ backgroundColor: "#3E63DD!important" }}
          >
            Next
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default SmartViewModal;
