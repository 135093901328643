import React, { useState } from "react";
import * as Yup from "yup";

import { useTranslation } from "react-i18next";
import { useFormik } from "formik";

import authService from "./../../services/authService";

import {
  Box,
  Button,
  Card,
  CardContent,
  FormLabel,
  Typography,
  TextField,
} from "@mui/material";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { theme } from "../../theme/theme";

export const ForgotPassword = () => {
  const { t } = useTranslation();

  const [isDone, setIsDone] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t("Invalid email address"))
        .required(t("Required")),
    }),
    onSubmit: async (values) => {
      const { email } = values;
      email && handleResetPasswordClick(email);
    },
  });

  async function handleResetPasswordClick(email) {
    if (!email) {
      throw new Error("Email is required.");
    }

    try {
      await authService.requestResetPassword(email);
      setIsDone(true);
    } catch (error) {
      // Handle error (e.g., show error message)
      console.error(error);
    }
  }

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: `linear-gradient(135deg, #ffffff, ${theme.palette.primary.light})`,
        px: 2,
      }}
    >
      <Card
        sx={{
          maxWidth: 400,
          width: "100%",
          boxShadow: 3,
          borderRadius: 2,
          overflow: "hidden",
        }}
      >
        <CardContent sx={{ p: 4 }}>
          {isDone ? (
            <>
              <Box sx={{ textAlign: "center", mb: 2 }}>
                <CheckCircleOutlineIcon
                  sx={{ fontSize: 60, color: theme.palette.success.main }}
                />
              </Box>
              <Typography
                variant="h4"
                gutterBottom
                sx={{ textAlign: "center", color: theme.palette.text.primary }}
              >
                {t("Thank You")}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ mb: 3, textAlign: "center" }}
              >
                {t(
                  "If an account with that email exists, you will receive password reset instructions."
                )}
              </Typography>
            </>
          ) : (
            <>
              <Typography
                variant="h4"
                gutterBottom
                sx={{ textAlign: "center", color: theme.palette.text.primary }}
              >
                {t("Forgot Password")}
              </Typography>

              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ mb: 3, textAlign: "center" }}
              >
                {t("Enter your email to receive password reset instructions.")}
              </Typography>
              <form onSubmit={formik.handleSubmit}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                  <Box id="email">
                    <FormLabel
                      htmlFor="email"
                      sx={{
                        color: theme.palette.text.secondary,
                        mb: 1,
                        fontSize: 12,
                      }}
                    >
                      {t("Email Address")}
                    </FormLabel>
                    <TextField
                      {...formik.getFieldProps("email")}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                      variant="outlined"
                      size="small"
                      fullWidth
                      id="email"
                      name="email"
                      placeholder={t("Enter your Email")}
                    />
                  </Box>

                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    sx={{
                      py: 1.5,
                      boxShadow: 2,
                      textTransform: "none",
                      fontSize: "1rem",
                      "&:hover": { boxShadow: 4 },
                    }}
                    disabled={!formik.isValid || formik.isSubmitting}
                  >
                    {t("Send Reset Link")}
                  </Button>
                </Box>
              </form>
            </>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};
