import React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import { ClientOverview } from "../../../clientSummary/ClientSummary";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelViewing,
  getViewings,
  updateViewing,
} from "../../../../store/slices/viewingSlice";
import { useTranslation } from "react-i18next";
import { selectProperties } from "../../../../store/slices/propertiesSlice";

const DetailsModal = ({ open, handleClose, rowData }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currentProperty } = useSelector(selectProperties);

  if (!rowData) return null;

  const cancelSubscription = () => {
    dispatch(cancelViewing(rowData.viewing_id)).then(() => {
      handleClose();
      dispatch(getViewings({}));
    });
  };

  const confirmSubscription = () => {
    const body = {
      status: "accepted",
    };

    dispatch(updateViewing({ viewingId: rowData.viewing_id, body })).then(
      () => {
        handleClose();
        dispatch(getViewings({}));
      }
    );
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="details-modal-title"
      aria-describedby="details-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 800,
          minHeight: "200px",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 0,
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "16px 40px",
            borderBottom: "1px solid rgba(0, 0, 0, 0.15)",
          }}
        >
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "700",
              lineHeight: "26px",
              color: "#202020",
            }}
          >
            {t("Details")}
          </Typography>
        </Box>
        <Box
          sx={{
            padding: "16px 40px",
            overflowY: "auto",
            maxHeight: "600px",
            flexGrow: 1,
          }}
        >
          <Box sx={{ display: "grid", gap: "24px" }}>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "700",
                lineHeight: "26px",
                color: "#202020",
              }}
            >
              {t("Requested viewing")}
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "14px",
                justifyContent: "flex-start",
              }}
            >
              <Box
                sx={{
                  background: "#D9D9D9",
                  width: "48px",
                  height: "48px",
                  borderRadius: "48px",
                }}
              ></Box>
              <Box
                sx={{
                  display: "grid",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "700",
                    lineHeight: "24px",
                    color: "#1C2024",
                  }}
                >
                  {rowData.user_name}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    color: "rgba(0, 7, 20, 0.62)",
                  }}
                >
                  {rowData.email || "nilson@gmail.com"}
                </Typography>
              </Box>
            </Box>
            <ClientOverview data={currentProperty.data} noPadding />
            <Box>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "26px",
                  color: "#202020",
                }}
              >
                {t("Additional information")}
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  lineHeight: "26px",
                  color: "#202020",
                }}
              >
                {currentProperty?.data?.description ||
                  t(
                    "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English."
                  )}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "16px 40px",
            borderTop: "1px solid rgba(0, 0, 0, 0.15)",
          }}
        >
          <Button
            variant="contained"
            sx={{
              border: "none",
              boxShadow: "none",
              background: "none",
              color: "#CE2C31",
              fontWeight: 400,
              fontSize: "18px",
              "&:hover": {
                backgroundColor: "transparent",
                boxShadow: "none",
              },
            }}
            onClick={cancelSubscription}
          >
            {t("Cancel the viewing")}
          </Button>
          <Button
            onClick={confirmSubscription}
            variant="contained"
            color="primary"
          >
            {t("Confirm the viewing")}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DetailsModal;
