import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Box, Grid, Typography, Button } from '@mui/material';

import { VerticalLinearStepper } from '../../components/stepper/Stepper';
import { Insights } from '../../components/insights/Insights';
import MessageField from '../../components/messageField/MessageField';
import MessageScreen from '../../components/messageScreen/MessageScreen';
import NewChat from '../../components/newChat/NewChat';
import { Gallery } from '../../components/gallery/Gallery';
import { ClientOverview } from '../../components/clientSummary/ClientSummary';
import AIAvatar from '../../assets/svg/AIAvatar';
import { theme } from '../../theme/theme';
import LoggedInLayout from '../LoggedInLayout/LoggedInLayout';
import { groupByStepAndInsights } from './../../utils/groupByStepAndInsights';

import { useTranslation } from 'react-i18next';

import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { jsonData } from '../../db';
import { fromQueryParamValue } from './../../utils/fromQueryParamValue';

const aiResponses = [
  {
    step: 'Location Preferences',
    sender: 'AI',
    text: 'In which city or neighborhood would you like to live?',
    insight: ['Location Horgen']
  },
  {
    step: 'Location Preferences',
    sender: 'AI',
    text: 'Are there specific areas you prefer or want to avoid? Why?',
    insight: ['Lake proximity', 'Quiet area']
  },
  {
    step: 'Type of Property',
    sender: 'AI',
    text: 'Are you looking for an apartment, house, or another type of property?',
    insight: ['Apartment preference']
  },
  {
    step: 'Type of Property',
    sender: 'AI',
    text: 'Do you have a preference for the building type (e.g., modern, historic, high-rise, detached)?  ',
    insight: ['Modern', 'Low-rise']
  },
  {
    step: 'Budget',
    sender: 'AI',
    text: 'What is your budget for rent?',
    insight: ['4,000-5,500 CHF']
  },
  {
    step: 'Budget',
    sender: 'AI',
    text: 'Is this budget flexible, or is it a hard limit?',
    insight: ['Flexible budget']
  },
  {
    step: 'Space and Layout',
    sender: 'AI',
    text: 'How many bedrooms do you need?',
    insight: ['3+ bedrooms']
  },
  {
    step: 'Space and Layout',
    sender: 'AI',
    text: 'Do you require a specific number of bathrooms?',
    insight: ['2 bathrooms']
  },
  {
    step: 'Space and Layout',
    sender: 'AI',
    text: 'Is having a certain amount of living space (in square meters) important to you?',
    insight: ['120+ m²']
  },
  {
    step: 'Space and Layout',
    sender: 'AI',
    text: 'Do you need additional rooms such as an office, guest room, or storage?',
    insight: ['Office space']
  },
  {
    step: 'Space and Layout',
    sender: 'AI',
    text: 'Is having a balcony, terrace, or garden a priority for you?',
    insight: ['Balcony', 'Lake view']
  },
  {
    step: 'Lifestyle and Amenities',
    subTopic: 'Commute and Accessibility',
    sender: 'AI',
    text: 'How far do you want to be from work or your daily commute destination?',
    insight: ['30-min drive']
  },
  {
    step: 'Lifestyle and Amenities',
    subTopic: 'Commute and Accessibility',
    sender: 'AI',
    text: 'Do you prefer to commute by car, public transport, bike, or on foot?',
    insight: ['Car commute']
  },
  {
    step: 'Lifestyle and Amenities',
    subTopic: 'Commute and Accessibility',
    sender: 'AI',
    text: 'Is there a maximum commute time you’re willing to accept?',
    insight: ['Max 30 mins']
  },
  {
    step: 'Lifestyle and Amenities',
    subTopic: 'Proximity to Amenities',
    sender: 'AI',
    text: 'How important is it to be close to shops, grocery stores, or markets?',
    insight: ['Grocery nearby']
  },
  {
    step: 'Lifestyle and Amenities',
    subTopic: 'Proximity to Amenities',
    sender: 'AI',
    text: 'Are there specific amenities (e.g., gyms, parks, restaurants) that you need to have nearby?',
    insight: ['Gym', 'Parks']
  },
  {
    step: 'Lifestyle and Amenities',
    subTopic: 'Proximity to Amenities',
    sender: 'AI',
    text: 'Do you have any preferences for nearby schools, kindergartens, or daycare centers?',
    insight: ['No school priority']
  },
  {
    step: 'Lifestyle and Amenities',
    subTopic: 'Public Transportation',
    sender: 'AI',
    text: 'How close do you need to be to public transport options such as bus stops, train stations, or tram lines?',
    insight: ['Train station', '10-min walk']
  },
  {
    step: 'Lifestyle and Amenities',
    subTopic: 'Public Transportation',
    sender: 'AI',
    text: 'Are there specific public transport routes that are essential for you?',
    insight: ['Access to Zurich']
  },
  {
    step: 'Personal and Family Considerations',
    subTopic: 'Family and Pets',
    sender: 'AI',
    text: 'Are you moving with family? How many people will live with you?',
    insight: ['Couple']
  },
  {
    step: 'Personal and Family Considerations',
    subTopic: 'Family and Pets',
    sender: 'AI',
    text: 'Do you have pets? If so, what kind, and do you need pet-friendly accommodations?',
    insight: ['Pet-friendly']
  },
  {
    step: 'Personal and Family Considerations',
    subTopic: 'Access to Family or Friends',
    sender: 'AI',
    text: 'Do you need to live near family members or close friends?',
    insight: ['Close to family']
  },
  {
    step: 'Personal and Family Considerations',
    subTopic: 'Access to Family or Friends',
    sender: 'AI',
    text: 'How important is the proximity to these individuals for your decision?',
    insight: ['High priority family']
  },
  {
    step: 'Property Features',
    subTopic: 'Furnishing',
    sender: 'AI',
    text: 'Do you need a furnished or unfurnished property?',
    insight: ['Unfurnished']
  },
  {
    step: 'Property Features',
    subTopic: 'Furnishing',
    sender: 'AI',
    text: 'Are there any specific furniture or appliances that you must have?',
    insight: ['Modern kitchen', 'Lake view']
  },
  {
    step: 'Property Features',
    subTopic: 'Parking and Vehicles',
    sender: 'AI',
    text: 'Do you have a car, and do you need a parking space?',
    insight: ['Car parking']
  },
  {
    step: 'Property Features',
    subTopic: 'Parking and Vehicles',
    sender: 'AI',
    text: 'Is having a garage or secure parking important to you?',
    insight: ['Secure parking']
  },
  {
    step: 'Property Features',
    subTopic: 'Building Amenities',
    sender: 'AI',
    text: 'Are there any building amenities that are important to you (e.g., elevator, gym, concierge, swimming pool)?',
    insight: ['Elevator', 'Shared gym']
  },
  {
    step: 'Property Features',
    subTopic: 'Building Amenities',
    sender: 'AI',
    text: 'Do you require wheelchair accessibility or other specific accommodations?',
    insight: ['No special accommodations']
  },
  {
    step: 'Additional Considerations',
    subTopic: 'Noise Levels',
    sender: 'AI',
    text: 'How sensitive are you to noise? Do you prefer a quiet neighborhood or is noise less of an issue?',
    insight: ['Quiet needed']
  },
  {
    step: 'Additional Considerations',
    subTopic: 'Noise Levels',
    sender: 'AI',
    text: 'Do you have any specific concerns about noise (e.g., near a train line, busy street)?',
    insight: ['Avoid noise']
  },
  {
    step: 'Additional Considerations',
    subTopic: 'View and Sunlight',
    sender: 'AI',
    text: 'Is having a good view important to you? If so, what kind of view do you prefer',
    insight: ['Lake view']
  },
  {
    step: 'Additional Considerations',
    subTopic: 'View and Sunlight',
    sender: 'AI',
    text: 'How important is natural light in your home?',
    insight: ['Natural light']
  },
  {
    step: 'Timing and Deadlines',
    subTopic: 'Move-In Date',
    sender: 'AI',
    text: 'When do you need to move in by? Is this date flexible?',
    insight: ['Early January']
  },
  {
    step: 'Timing and Deadlines',
    subTopic: 'Move-In Date',
    sender: 'AI',
    text: 'Are there any hard deadlines or key dates that you need to consider?',
    insight: ['Early January deadline']
  },
  {
    step: 'Timing and Deadlines',
    subTopic: 'Length of Stay',
    sender: 'AI',
    text: 'How long are you planning to stay in this property? Is it a short-term or long-term move?',
    insight: ['Long-term']
  },
  {
    step: 'Flexibility and Prioritization',
    subTopic: 'Hard Requirements',
    sender: 'AI',
    text: 'Which of the features or preferences you’ve mentioned are non-negotiable for you?',
    insight: ['Non-negotiable']
  },
  {
    step: 'Flexibility and Prioritization',
    subTopic: 'Soft Requirements',
    sender: 'AI',
    text: 'What features or preferences are you more flexible about?',
    insight: ['Flexible amenities']
  },
  {
    step: 'Flexibility and Prioritization',
    subTopic: 'Soft Requirements',
    sender: 'AI',
    text: 'If you had to prioritize, which aspects are most important to you?',
    insight: ['Priority list']
  },
  {
    step: 'Flexibility and Prioritization',
    subTopic: 'Deal Breakers',
    sender: 'AI',
    text: 'Are there any factors or features that would be absolute deal-breakers for you?',
    insight: ['Noise', 'Distance deal-breakers']
  },
  {
    step: 'Flexibility and Prioritization',
    subTopic: 'Deal Breakers',
    sender: 'AI',
    text: 'What should the AI avoid in its recommendations?',
    insight: ['Avoid noisy areas']
  },
  {
    step: 'Feedback and Follow-Up',
    sender: 'AI',
    text: 'Would you like to receive updates on new properties that match your criteria?',
    insight: ['Updates requested']
  },
  {
    step: 'Feedback and Follow-Up',
    sender: 'AI',
    text: 'How would you prefer to be notified of new matches (e.g., email, WhatsApp)?',
    insight: ['WhatsApp updates']
  },
  {
    step: 'Feedback and Follow-Up',
    subTopic: 'Viewing and Application',
    sender: 'AI',
    text: 'Are you open to the AI automatically scheduling viewings or applying for properties on your behalf?',
    insight: '"Yes, as long as I’m notified first."'
  },
  {
    step: 'Feedback and Follow-Up',
    subTopic: 'Viewing and Application',
    sender: 'AI',
    text: 'How involved do you want to be in the selection and application process?',
    insight: 'AI assistance requested'
  },
  {
    step: 'Feedback and Follow-Up',
    sender: 'AI',
    text: 'Here is the summary based on your answers:'
  }
];

const initConversation = [
  {
    sender: 'AI',
    text: "Welcome! Our AI real estate service is here to find your perfect home, matching your preferences with the best options and suggesting hidden gems. Let's start your search for the ideal living space!"
  }
];

function scrollToId(id) {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}

const Chat = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const userInputValFromUrl = fromQueryParamValue(searchParams.get('input'));

  const scrollRef = useRef(null);
  const boxRef = useRef(null);
  const currentStepRef = useRef(0);

  const [done, setDone] = useState(false);
  const [conversation, setConversation] = useState(
    userInputValFromUrl ? [] : [initConversation[0]]
  );
  const [gallery, setGallery] = useState([
    {
      images: [
        'https://plus.unsplash.com/premium_photo-1723901831135-782c98d8d8e0?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
      ],
      address: 'Sonnenweg 15, 8810 Horgen, Switzerland',
      beds: 3,
      baths: 3,
      sqrFeet: 125
    },
    {
      images: [
        'https://images.unsplash.com/photo-1523755231516-e43fd2e8dca5?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
      ],
      address: 'Seestrasse 42, 8810 Horgen, Switzerland',
      beds: 3,
      baths: 3,
      sqrFeet: 120
    },
    {
      images: [
        'https://plus.unsplash.com/premium_photo-1663126298656-33616be83c32?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
      ],
      address: 'Blumenweg 8, 8810 Horgen, Switzerland',
      beds: 3,
      baths: 3,
      sqrFeet: 130
    },
    {
      images: [
        'https://images.unsplash.com/photo-1554995207-c18c203602cb?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
      ],
      address: 'Kirchgasse 19, 8810 Horgen, Switzerland',
      beds: 3,
      baths: 3,
      sqrFeet: 120
    }
  ]);
  const [prevVisitedList, setPrevVisitedList] = useState({
    Today: [{ location: 'Horgen', rooms: 3, size: '120+ m²', selected: false }],
    'Previous 7 Days': []
  });
  const [isChatCompleted, setIsChatCompleted] = useState(false);
  const [message, setMessage] = useState({ text: '', sender: 'User' });
  const [isDisableChatInput, setIsDisableChatInput] = useState(false);

  const isLastMessageInConversation = useMemo(
    () => conversation.some((msg) => msg.text === aiResponses[aiResponses?.length - 1]?.text),
    [conversation]
  );

  const _currentStep =
    useMemo(() => {
      if (!conversation || conversation.length === 0 || !jsonData || !jsonData.stepper) {
        return 0;
      } else {
        return jsonData?.stepper?.find(
          (step) => step.label === conversation[conversation?.length - 1].step
        );
      }
    }, [conversation])?.id - 1 || 0;

  const currentStep = _currentStep === 0 ? currentStepRef.current : _currentStep;

  const insightsData = groupByStepAndInsights(conversation);

  function handleScrollBottom() {
    if (scrollRef.current?.scrollIntoView) {
      setTimeout(() => {
        scrollRef.current?.scrollIntoView({
          block: 'center',
          behavior: 'smooth'
        });
      }, 0);
    }
  }

  function handleDone() {
    setDone(true);
    handleScrollBottom();
  }

  const sendHandler = useCallback(
    (msg) => {
      // Append the user's message to the conversation
      setConversation((prev) => [...prev, { ...(msg || message) }]);
      setMessage({ text: '', sender: 'User' }); // Reset the message field

      // Set initial "Thinking" state with dynamic dots
      let dots = '';
      const loadingMessage = { text: 'Thinking', sender: 'AI' };
      setConversation((prev) => [...prev, loadingMessage]);

      const loadingInterval = setInterval(() => {
        dots = dots?.length < 3 ? dots + '.' : '';
        setConversation((prev) =>
          prev.map((msg) => (msg === loadingMessage ? { ...msg, text: `Thinking${dots}` } : msg))
        );
      }, 100);

      // Timeout to simulate delay for AI response
      setTimeout(() => {
        clearInterval(loadingInterval); // Stop the dot animation

        setConversation((prev) => {
          // Calculate the index of the next AI response based on previous messages
          const _nextIndex = prev.filter((msg) => msg.sender === 'AI')?.length - 1;
          const nextIndex = _nextIndex > 0 ? _nextIndex - 1 : 0;
          const nextAIResponse = aiResponses[nextIndex] || {};

          // Replace "Thinking" with actual AI response
          if (nextAIResponse) {
            return [
              ...prev.slice(0, -1), // Remove the last "Thinking" message
              nextAIResponse
            ];
          } else {
            console.log('No more AI responses to display.');
            return prev;
          }
        });
      }, 500);
    },
    [message]
  );

  function onChangeHandler(e) {
    const { name, value } = e.target;
    setMessage({ text: value, sender: name });
  }

  function toggleSearchResults() {
    const params = new URLSearchParams(location.search);

    if (params.has('searchResults')) {
      // If "searchResults" exists, remove it
      params.delete('searchResults');
    } else {
      // If "searchResults" does not exist, add it
      params.set('searchResults', 'true');
    }

    // Navigate with updated search parameters
    navigate(`${location.pathname}?${params.toString()}`, {
      replace: true
    });
  }

  function onChatClick() {
    setPrevVisitedList((prevState) => ({
      ...prevState,
      Today: prevState.Today.map((item) =>
        item.location === 'Horgen' ? { ...item, selected: !item.selected } : item
      )
    }));

    toggleSearchResults();
  }

  function deleteChat() {
    setPrevVisitedList({ Today: [], 'Previous 7 Days': [] });
  }

  useEffect(() => {
    if (userInputValFromUrl && userInputValFromUrl.trim() && conversation.length === 0) {
      setConversation([{ text: userInputValFromUrl, sender: 'User' }]);

      setConversation((prev) => [...prev, { text: 'How can I help you?', sender: 'AI' }]);
    }
  }, [userInputValFromUrl, conversation, sendHandler]);

  useEffect(() => {
    setIsDisableChatInput(isLastMessageInConversation);
  }, [isLastMessageInConversation]);

  // Update the ref only if the calculated currentStep is not 0
  useEffect(() => {
    if (_currentStep !== 0) {
      currentStepRef.current = _currentStep;
    }
  }, [_currentStep]);

  useEffect(() => {
    scrollToId(conversation[conversation.length - 1]?.text);
  }, [conversation.length, conversation]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const isSearchResults = params.has('searchResults');

    setIsChatCompleted(isSearchResults);
  }, [location.search]);

  useEffect(() => {
    isLastMessageInConversation && scrollToId('ClientOverview');
  }, [isLastMessageInConversation]);

  return (
    <LoggedInLayout hideSubscription>
      <Box
        sx={{
          height: 'calc(100vh - 64px)'
        }}>
        <Grid
          sx={{
            px: 5,
            marginTop: 0,
            height: '100%'
          }}
          container
          spacing={2}>
          {/* Stepper Section */}
          <Grid
            sx={{
              borderRight: '1px solid #00002F26',
              height: '100%',
              overflowY: 'scroll',
              scrollbarWidth: 'none',

              '&::-webkit-scrollbar': {
                display: 'none'
              }
            }}
            item
            xs={2.5}>
            {!isChatCompleted ? (
              <Box sx={{ pr: 0.5, pt: 2.5 }}>
                <Typography
                  sx={{
                    pb: 2,
                    color: 'black',
                    fontWeight: 600
                  }}>
                  {t('Search Property')}
                </Typography>

                <VerticalLinearStepper currentStep={currentStep} isDone={isDisableChatInput} />
              </Box>
            ) : (
              <Box sx={{ pr: 5, pt: '20px' }}>
                <NewChat
                  onDeleteClick={deleteChat}
                  onClick={onChatClick}
                  prevVisitedList={prevVisitedList}
                />
              </Box>
            )}
          </Grid>

          {/* MessageScreen Section */}
          {!isChatCompleted ? (
            <Grid
              item
              xs={7}
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                pt: '0!important',
                pr: '16px',
                pb: '16px',
                '& .MuiGrid-item': {
                  paddingLeft: '30px !important',
                  paddingTop: 0
                }
              }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  overflowY: 'scroll',
                  scrollbarWidth: 'none',
                  '&::-webkit-scrollbar': {
                    display: 'none'
                  }
                }}>
                <Box sx={{ padding: 4 }}>
                  <Typography
                    sx={{
                      color: 'black',
                      fontWeight: 600,
                      fontSize: '20px'
                    }}>
                    {t("Hello! I'm here to help you with the best choose.")}
                  </Typography>
                  <Typography sx={{ fontSize: '12px' }}>
                    {t("Hello! I'm here to help you with the best choose.")}
                  </Typography>
                </Box>

                <Box
                  ref={boxRef}
                  sx={{
                    height: '100%'
                  }}>
                  <MessageScreen chat={conversation} />

                  {isLastMessageInConversation && (
                    <div id="ClientOverview">
                      <ClientOverview />
                    </div>
                  )}

                  {isLastMessageInConversation && (
                    <Box p={2}>
                      <Box
                        sx={{
                          display: 'flex'
                        }}>
                        <Box sx={{ mr: 1 }}>
                          <AIAvatar />
                        </Box>

                        <Box>
                          <Typography
                            sx={{
                              fontSize: '16px',
                              color: 'black',
                              fontWeight: 500
                            }}>
                            {t('AI Agent')}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: '14px',
                              color: 'black',
                              mt: 1
                            }}>
                            {t(
                              'Check All your search parameters.Do you want to edit anything or everything is good?'
                            )}{' '}
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: 2,
                          border: `1px solid ${theme.palette.border.grey}`,
                          borderRadius: '10px',
                          my: 5,
                          py: 3,
                          flexWrap: 'wrap',
                          [theme.breakpoints.down('md')]: {
                            px: 2
                          }
                        }}>
                        <Button
                          variant="outlined"
                          sx={{
                            fontWeight: 400
                          }}>
                          {t('I want to edit my search parameters')}
                        </Button>
                        <Button
                          onClick={handleDone}
                          variant="contained"
                          sx={{
                            fontWeight: 400
                          }}>
                          {t("I'm done")}
                        </Button>
                      </Box>
                    </Box>
                  )}

                  {done && (
                    <Box p={2}>
                      <Box
                        sx={{
                          display: 'flex'
                        }}>
                        <Box sx={{ mr: 1 }}>
                          <AIAvatar />
                        </Box>
                        <Box>
                          <Typography
                            sx={{
                              fontSize: '16px',
                              color: 'black',
                              fontWeight: 500
                            }}>
                            {t('AI Agent')}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: 14,
                              color: '#000',
                              mt: 1
                            }}>
                            Just like a personal real estate agent, our AI is dedicated to finding
                            the best match for you, considering all your requirements and
                            preferences. Our approach aims to maximize your satisfaction by not only
                            suggesting options in the areas you initially consider but also
                            proposing properties in other neighborhoods that align with your needs
                            and preferences, even those you may not have thought of initially.
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: '14px',
                              color: 'black',
                              mt: 1
                            }}>
                            {t('Great! Than here is the plan:')}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: '14px',
                              color: 'black',
                              mt: 3
                            }}>
                            {t('chat done step 1')}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: '14px',
                              color: 'black',
                              mt: 1
                            }}>
                            {t('chat done step 2')}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: 2,
                          border: `1px solid ${theme.palette.border.grey}`,
                          borderRadius: '10px',
                          my: 5,
                          py: 3,
                          flexWrap: 'wrap',
                          [theme.breakpoints.down('md')]: {
                            px: 2
                          }
                        }}>
                        <Button
                          onClick={() => {
                            setIsChatCompleted(true);
                            navigate('/chat?searchResults=true');
                          }}
                          variant="contained"
                          sx={{
                            fontWeight: 400
                          }}>
                          {t('Got that, lets see search results')}
                        </Button>
                      </Box>
                    </Box>
                  )}
                  <div ref={scrollRef} />
                </Box>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'center'
                }}>
                <MessageField
                  disabled={isDisableChatInput}
                  onChange={onChangeHandler}
                  onSendMessage={sendHandler}
                />
              </Box>
            </Grid>
          ) : (
            <Grid
              item
              xs={7}
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                pr: 6.5,
                pb: '16px',
                '& .MuiGrid-item': {
                  paddingLeft: '30px !important',
                  paddingTop: 0
                }
              }}>
              <Gallery gallery={gallery} />
            </Grid>
          )}

          {/* Insights Section */}
          <Grid
            sx={{
              borderLeft: '1px solid #00002F26',
              height: '100%',
              overflowY: 'scroll',
              scrollbarWidth: 'none',
              padding: '0 !important',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',

              '&::-webkit-scrollbar': {
                display: 'none'
              }
            }}
            item
            xs={2.5}>
            <Box
              sx={{
                height: '100%',
                width: '100%'
              }}>
              <Insights
                insights={insightsData.slice(0, -1)}
                adjust={isChatCompleted}
                title={t('Insights')}
                isShowInsights={conversation?.some((conv) => conv.sender === 'User')}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </LoggedInLayout>
  );
};

export default Chat;
