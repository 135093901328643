import React, { useState } from "react";
import { Box, Typography, Card, Tooltip, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import SmartViewModal from "./SmartViewModal";

import { Link } from "react-router-dom";

const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    "& .MuiTooltip-tooltip": {
        backgroundColor: "black",
        color: "white",
        padding: "10px 20px",
        fontSize: "14px",
        lineHeight: "20px",
    },
    "& .MuiTooltip-arrow": {
        color: "black",
    },
}));

const StatCard = ({
    icon,
    title,
    subtitle,
    color = "#3F8CFF",
    bgColor,
    button = false,
}) => {
    const [smartViewModal, setSmartViewModal] = useState(false);

    const subscription = JSON.parse(
        localStorage.getItem("persist:root")
    ).subscriptions;

    const parsedSub = JSON.parse(subscription);

    const plan = parsedSub.subscription.data[0].plan;

    console.log(plan);

    return (
        <Card
            sx={{
                maxWidth: "252px",
                width: "100%",
                borderRadius: "8px",
                boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.10)",
                border: "1px solid #C1D0FF",
                background: "#fff",
                overflow: "visible",
                position: "relative",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                padding: "12px 16px",
                flex: "1",
                gap: "16px",
            }}
        >
            {!button ? (
                <>
                    <Box
                        sx={{
                            // display: "flex",
                            // alignItems: "center",
                            // marginBottom: "8px",
                            width: "40px",
                            height: "40px",
                            background: `${bgColor}`,
                            color: `${color}`,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "6px",
                        }}
                    >
                        {icon}
                    </Box>

                    <Box>
                        <Typography
                            variant="body2"
                            sx={{
                                // marginLeft: "8px",
                                color: "#202020",
                                fontSize: "20px",
                                fontWeight: "700",
                                lineHeight: "16px",
                            }}
                        >
                            {title}
                        </Typography>
                        <Typography
                            variant="h4"
                            sx={{
                                color: "#646464",
                                fontSize: "14px",
                                fontWeight: 500,
                                lineHeight: "20px",
                            }}
                        >
                            {subtitle}
                        </Typography>
                    </Box>
                </>
            ) : (
                <StyledTooltip
                    title="SmartView Experience is a paid feature. Please upgrade your plan to use this feature."
                    placement="top"
                    arrow
                >
                    <Link to="/plan" style={{ textDecoration: "none" }}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            // onClick={() => setSmartViewModal(true)}
                            sx={{
                                height: "40px",
                                fontSize: "16px",
                                lineHeight: "24px",
                                filter:
                                    plan === "free" ? "blur(4px)" : "blur(0px)",
                            }}
                        >
                            SmartView Experience
                        </Button>
                    </Link>
                </StyledTooltip>
            )}
            <SmartViewModal
                open={smartViewModal}
                onClose={() => setSmartViewModal(false)}
            />
        </Card>
    );
};

export default StatCard;
