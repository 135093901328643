import * as React from 'react';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
   height: 10,
   borderRadius: 5,
   [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[200],
      ...theme.applyStyles('dark', {
         backgroundColor: theme.palette.grey[800],
      }),
   },
   [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: '#1a90ff',
      ...theme.applyStyles('dark', {
         backgroundColor: '#308fe8',
      }),
   },
}));



// From https://github.com/mui/material-ui/issues/9496#issuecomment-959408221

export default function ProgressBar(props) {
   return (
      <BorderLinearProgress variant="determinate" value={props.value} />
   );
}
