export const faqData = [
  {
    category: "General Platform Features",
    questions: [
      {
        question: "What is the purpose of your platform?",
        answer:
          "Our platform simplifies and enhances the process of finding a flat using advanced AI technology and data integration to provide personalized property recommendations tailored to your lifestyle and preferences.",
      },
      {
        question: "What makes your platform different from other real estate websites?",
        answer:
          "We go beyond basic filters by analyzing property data and user preferences comprehensively. The platform provides detailed recommendations, tools for managing your search, and automates scheduling property viewings.",
      },
      {
        question: "How secure is my data?",
        answer:
          "We prioritize your privacy and employ state-of-the-art security measures to protect your information, using it solely to enhance your property search experience.",
      },
      {
        question: "What devices can I use to access the platform?",
        answer:
          "Our platform is accessible on desktops, tablets, and mobile devices for a seamless experience.",
      }
    ],
  },
  {
    category: "AI-Powered Features",
    questions: [
      {
        question: "How does the AI-driven interview work? ",
        answer:
          "The AI conducts a conversational interview to gather your needs, such as budget, location, commute preferences, and hobbies, enabling highly personalized recommendations.",
      },
      {
        question: "How does the AI analyze property descriptions and photos?",
        answer:
          "The AI evaluates qualitative aspects like view quality, noise levels, and interior design, providing a comprehensive understanding of each property through In-Depth Analysis of Text and Photos.",
      },
      {
        question: "How does the Advanced Data-Driven Search work?",
        answer:
          "This feature uses over 60 data sources to analyze commute times, amenities, taxes, and more. The search evolves in real-time, adjusting criteria to ensure timely, accurate matches as deadlines approach.",
      },
      {
        question: "What is the Real-Time Market Scan feature?",
        answer:
          "This ensures users are the first to know about new listings, providing instant updates to stay ahead in competitive markets.",
      },
      {
        question: "Can the AI help me apply for a property immediately?  ",
        answer:
          "Yes, the Instant Application Submission feature allows the AI to automatically complete and submit applications, ensuring you're among the first applicants.",
      },
    ],
  },
  {
    category: "Tenant Tools and Features",
    questions: [
      {
        question: "How do the automated scheduling and reminders work?",
        answer:
          "Link your calendar to the platform to schedule viewings automatically. Set reminders and take notes for properties, keeping your search organized.",
      },
      {
        question: "How does the platform assist tenants in making compromises?",
        answer:
          "The AI highlights property features that align or deviate from your ideal criteria, helping you prioritize and make informed decisions.",
      },
      {
        question: "Can I customize how the AI prioritizes certain criteria?",
        answer:
          "Yes, you can provide feedback, and the AI will adjust its search criteria to better match your evolving preferences.",
      },
      {
        question: "How do I receive property alerts?",
        answer:
          "Depending on your plan, you can receive alerts via email or WhatsApp in real-time.",
      },
      
    ],
  },
  {
    category: "Landlord Features",
    questions: [
      {
        question: "How does the platform support landlords in managing their properties?",
        answer:
          "We offer a Streamlined Property Management tool to centralize listing management, viewing schedules, and tenant tracking, reducing manual workload.",
      },
      {
        question: "Can landlords automate property viewings?",
        answer:
          "Yes, landlords can use a key box for self-guided tours managed by the AI, eliminating the need for physical presence during viewings.",
      },
      {
        question: "What kind of insights can landlords get about potential tenants?",
        answer:
          "The AI provides Detailed Tenant Insights, offering compatibility ratings based on financial stability, rental history, and lifestyle preferences.",
      },
      {
        question: "How can landlords choose the best tenant from multiple applicants?",
        answer:
          "The AI pre-screens applicants through Enhanced Tenant Screening, ensuring only the most suitable candidates are considered.",
      },
      
    ],
  },
  {
    category: "Investor and Seller Tools",
    questions: [
      {
        question: "How does your platform help buyers looking for investment properties?",
        answer:
          "We provide AI-Driven Real Estate Investment Analysis, simplifying search, valuation, and risk assessment for high-potential properties.",
      },
      {
        question: "Can the AI provide accurate property valuations? ",
        answer:
          "Yes, the AI analyzes historical prices, market trends, and economic indicators to deliver precise valuations.",
      },
      {
        question: "How does the platform assess risks for investors?",
        answer:
          "Our Comprehensive Risk Assessment evaluates factors like crime rates, environmental hazards, and market volatility for each property.",
      },
      {
        question: "Can the platform help with investment strategy and portfolio management?",
        answer:
          "Yes, it offers tailored investment strategies, diversification recommendations, and market alerts for buying or selling opportunities.",
      },
      {
        question: "How does the platform handle real estate selling?",
        answer:
          "For sellers, the AI suggests competitive pricing and optimal listing times, ensuring maximum visibility and faster sales.",
      },
      {
        question: "Is the platform suitable for first-time investors?",
        answer:
          "Absolutely. The AI provides step-by-step guidance, tailored property analysis, and personalized recommendations.",
      },
      
    ],
  },
  {
    category: "Transparency and User Experience",
    questions: [
      {
        question: "How does the platform visualize AI analysis for users?",
        answer:
          "Interactive visualizations show how data points feed into the search algorithm, explain property recommendations, and display progress during applications.",
      },
      {
        question: "How does the platform maintain transparency during the search process?",
        answer:
          "Detailed explanations accompany each recommendation, showing why a property was suggested and how it fits your criteria.",
      }
      
    ],
  },
];
