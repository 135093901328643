import React, { useState } from "react";
import { Hero } from "../../components/sections/heroSection/Hero";
import { Box } from "@mui/material";
import WorkSection from "../../components/sections/workSection/WorkSection";
import Footer from "../../components/footer/Footer";
import LoginDialog from "../../components/sections/socialLogin/LoginDialog";
import TestimonialCarousel from "../../components/sections/testimonialSection/Testimonial";
import PricingPlans from "../../components/sections/planSection/Plan";
import MissionStatements from "../../components/sections/missionStatements/MissionStatements";
import Team from "../../components/sections/teamSection/Team";
import Features from "../../components/sections/featureSection/Feature";
import AICard from "../../components/sections/aiCard/AICard";
import { theme } from "../../theme/theme";
import FAQSection from "../../components/sections/FAQ/FAQ";
import { useTranslation } from "react-i18next";
import authService from "../../services/authService";
import { Helmet } from "react-helmet-async";

const Home = () => {
  const isAuthenticated = authService.isAuthenticated();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Helmet>
        <title>{t("title_home")}</title>
      </Helmet>
      <Box sx={{ maxWidth: "100%" }}>
        <LoginDialog open={open} handleClose={handleClose} />
        <Hero handleClickOpen={handleClickOpen} />
        <WorkSection id="how-it-works" title={t("How It Works")} />
        <AICard
          background={theme.palette.aiCard.blue}
          buttonColor={"#1f2d5c"}
          elementColor={theme.palette.aiCard.darkBlue}
          textColor={"white"}
        />
        <Features id="features" title={t("Features")} />
        <TestimonialCarousel id="testimonials" />
        <PricingPlans id="pricing" />
        <MissionStatements />
        <FAQSection />
        <Footer id="contact-us" />
      </Box>
    </>
  );
};

export default Home;
