import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  styled,
  Typography,
  Grid,
  Link,
  Card,
  CardContent,
  Button,
} from "@mui/material";
import Plan from "../../../plan/plan";
import FeatureCard from "../../planSection/FeatureCard";
import { jsonData } from "../../../../db";
import CancelSubscription from "../../../modals/cancelSubscription";
import { useState } from "react";
import {
  selectSubscriptions,
  getPlans,
  createSubscription,
  updateSubscription,
  cancelSubscription,
  getSubscription,
} from "../../../../store/slices/subscriptionsSlice";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useTranslation } from "react-i18next";

const HeaderContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  textAlign: "left",
  borderRadius: "5px",
  padding: theme.spacing(2),
  border: "1px solid #d7e1ff",
  position: "relative",
}));

const PlansContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  gap: theme.spacing(2),
  alignItems: "flex-start",
  marginTop: "10px",
  marginBottom: "20px",
  paddingLeft: 16,

  [theme.breakpoints.down("lg")]: {
    flexDirection: "column", // Maintain row direction
    flexWrap: "wrap",
    "& > *": {
      // Target children to take 50% width
      width: "100%",
      flexBasis: "100%",

    },
  },
}));

const PriceTag = styled(Typography)(({ theme, selected }) => ({
  color: selected ? "#fff" : theme.palette.text.primary,
  fontSize: "2rem",
  fontWeight: "bold",
}));

const FeatureList = styled("ul")({
  display: "flex",
  flexDirection: "column",
  listStyle: "none",
  padding: 0,
  marginTop: "1rem",
  gap: 15,
});

const checkIconStyle = {
  marginRight: 0.5,
  color: "#4CAF50",
  fontSize: "16px",
  borderRadius: "100%",
};

const Subscription = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const {
    plans,
    subscription,
    createSubscription: createSubscriptionState,
    updateSubscription: updateSubscriptionState,
    cancelSubscription: cancelSubscriptionState,
  } = useSelector(selectSubscriptions);
  const [cancelSubscriptionModal, setCancelSubscriptionModal] = useState(false);

  useEffect(() => {
    dispatch(getPlans());
    dispatch(getSubscription());
  }, [dispatch]);

  const activePlan = useMemo(() => {
    if (!subscription?.data || !plans?.data) {
      return null;
    }

    const activeSubscription = subscription.data.find(
      (item) => item.status === "active"
    );

    if (!activeSubscription) {
      return null;
    }
    return {
      ...plans?.data.find((plan) => plan?.plan === activeSubscription?.plan),
      subscription_id: activeSubscription.subscription_id,
    };
  }, [subscription, plans]);

  const planData = useMemo(() => {
    if (!activePlan) return jsonData.shortPlan;
    return jsonData.shortPlan.filter(
      (plan) => plan?.title !== activePlan?.plan_name
    );
  }, [activePlan]);

  const isFreePlan = activePlan && activePlan.unit_amount === 0;

  const handleUpgrade = (plan) => {
    // if (isFreePlan) {
    //   dispatch(createSubscription({ plan: plan.plan })).then(() => {
    //     dispatch(getSubscription());
    //   });
    // } else {
    dispatch(
      updateSubscription({
        subscriptionId: activePlan.subscription_id,
        body: { plan: plan?.plan },
      })
    ).then(() => {
      dispatch(getSubscription());
    });
    // }
  };

  const handleCancel = () => {
    if (activePlan && activePlan.subscription_id) {
      dispatch(cancelSubscription(activePlan.subscription_id))
        .then(() => {
          setCancelSubscriptionModal(false);
          dispatch(getSubscription());
        })
        .catch((error) => {
          console.error("Failed to cancel subscription:", error);
        });
    }
  };

  return (
    <Box sx={{ px: 3, width: "100%" }}>
      <HeaderContainer>
        <Typography variant="h6" fontWeight="normal">
          {t("Subscription")}
        </Typography>
      </HeaderContainer>
      <PlansContainer>
        {activePlan && (
          <Plan
            name={activePlan?.plan_name}
            price={
              activePlan.unit_amount !== 0 ? activePlan.unit_amount : "Free"
            }
            cancelSubscription={
              !isFreePlan ? () => setCancelSubscriptionModal(true) : undefined
            }
            cancel={!isFreePlan}
            subscription
            keyFeatures={jsonData.currentPlan.keyFeatures}
          />
        )}

        {planData.map((plan, index) => {
          if (plan && plan?.plan !== activePlan?.plan) {
            return (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <StyledCard
                  sx={{
                    backgroundColor: plan?.selected ? "#3a5bc7" : "#e1e9ff",
                    color: plan?.selected ? "#fff" : "#000",
                    boxShadow: "none",
                    minHeight: "575px",
                  }}
                >
                  <CardContent>
                    <Box
                      sx={{
                        height: "200px",
                        paddingBottom: "24px",
                      }}
                    >
                      <Typography variant="h6" gutterBottom>
                        {t(plan?.title)}
                      </Typography>
                      <PriceTag selected={plan?.selected}>
                        {plan?.price}
                      </PriceTag>
                      <Typography
                        sx={{
                          py: 1,
                          color: plan?.selected && "white",
                        }}
                      >
                        {t("per month")}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          color: plan?.selected && "white",
                        }}
                      >
                        {t(plan?.description)}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        width: "100%",
                        height: "1px",
                        backgroundColor: plan?.selected
                          ? "#ffffff33"
                          : "#20202033",
                      }}
                    />
                    <Box
                      sx={{
                        pt: 2,
                      }}
                    >
                      <Typography
                        sx={{
                          py: 3,
                          pt: 1,
                          pb: "8px",
                          color: plan.selected && "white",
                        }}
                      >
                        {t("Key Features")}
                      </Typography>
                    </Box>
                    <FeatureList>
                      {plan.features.map((feature, idx) => (
                        <FeatureCard
                          key={idx}
                          feature={feature}
                          selected={plan.selected}
                        />
                      ))}
                    </FeatureList>
                    {plan.advantages.length > 0 && (
                      <>
                        <Box
                          sx={{
                            width: "100%",
                            height: "1px",
                            marginTop: "28px",
                            backgroundColor: plan.selected
                              ? "#ffffff33"
                              : "#20202033",
                          }}
                        />
                        <Typography
                          sx={{
                            py: 3,
                            color: plan?.selected && "white",
                          }}
                        >
                          {t("Advantages")}
                        </Typography>
                        {plan?.advantages.map((advantage, idx) => (
                          <Box
                            key={idx}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "12px",
                              gap: 1,
                            }}
                          >
                            <CheckCircleOutlineIcon
                              sx={{
                                ...checkIconStyle,
                                color: plan?.selected
                                  ? "white"
                                  : checkIconStyle.color,
                                backgroundColor: plan?.selected
                                  ? "rgba(255, 255, 255, 0.5)"
                                  : "rgba(76, 175, 80, 0.1)",
                              }}
                            />
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: "light" }}
                            >
                              {t(advantage.title)}
                            </Typography>
                          </Box>
                        ))}
                      </>
                    )}

                    <Button
                      variant="outlined"
                      onClick={() => handleUpgrade(plan)}
                      disabled={
                        createSubscriptionState.isLoading ||
                        updateSubscription.isLoading
                      }
                      sx={{
                        position: "absolute",
                        bottom: 40,
                        height: "48px",
                        padding: "0 24px",
                        fontWeight: 500,
                        fontSize: "18px",
                        marginTop: "3rem",
                        color: "black",
                        borderRadius: "7px",
                        backgroundColor: plan?.selected
                          ? "#fcfcfd"
                          : "transparent",
                        border: plan.selected
                          ? "1px solid #fcfcfd"
                          : "1px solid #848a98",
                        "&:hover": {
                          backgroundColor: plan?.selected
                            ? "#fcfcfd"
                            : "transparent",
                          borderColor: "#848a98",
                          boxShadow: "none",
                        },
                        "&:disabled": {
                          backgroundColor: plan?.selected
                            ? "#fcfcfd"
                            : "transparent",
                          borderColor: "#848a98",
                          boxShadow: "none",
                          color: "#848a98",
                        },
                      }}
                    >
                      {t("Upgrade")}
                    </Button>
                  </CardContent>
                </StyledCard>
              </Grid>
            );
          }
        })}
      </PlansContainer>
      <CancelSubscription
        open={cancelSubscriptionModal}
        onClose={() => setCancelSubscriptionModal(false)}
        onConfirm={handleCancel}
        isLoading={cancelSubscriptionState.isLoading}
        name={activePlan?.plan_name}
      />
    </Box>
  );
};

export default Subscription;
