import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "./slices/authSlice";
import counterReducer from "./slices/counterSlice";
import testimonialReducer from "./slices/testimonialSlice";
import teamSlice from "./slices/teamSlice";
import chatSlice from "./slices/chatSlice";
import accountReducer from "./slices/accountSlice";
import propertiesReducer from "./slices/propertiesSlice";
import viewingReducer from "./slices/viewingSlice";
import analyticsReducer from "./slices/analyticsSlice";
import subscriptionsReducer from "./slices/subscriptionsSlice";
import notesReducer from "./slices/notesSlice";
import documentsReducer from "./slices/documentsSlice";

const persistConfig = {
  key: "root",
  storage,
};

const reducers = combineReducers({
  auth: authReducer,
  counter: counterReducer,
  testimonials: testimonialReducer,
  team: teamSlice,
  chat: chatSlice,
  account: accountReducer,
  properties: propertiesReducer,
  viewing: viewingReducer,
  analytics: analyticsReducer,
  subscriptions: subscriptionsReducer,
  notes: notesReducer,
  documents: documentsReducer,
});

export default persistReducer(persistConfig, reducers);
