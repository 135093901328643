import React from 'react'
import Layout from '../Layout/Layout';
import FAQSection from '../../components/sections/FAQ/FAQ';
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet-async';
const FAQ = () => {
   const { t } = useTranslation();
   return (<><Helmet>
      <title>{t('Title FAQ')}</title></Helmet>
      <Layout backgroundColor={"linear-gradient(to bottom, #d2dbf8, #f7f9ff)"} >
         <FAQSection />
      </Layout></>
   )
}

export default FAQ;
