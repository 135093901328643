import React, { useState, useEffect, useRef, useMemo } from "react";
import { toast } from "react-toastify";
import { TextField, InputAdornment, Button, Snackbar } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import { theme } from "../../theme/theme";
import authService from "../../services/authService";
import { toQueryParamValue } from "./../../utils/toQueryParamValue";

export const SearchBar = () => {
  const { t } = useTranslation();
  const timeoutRef = useRef(null);

  const isAuthenticated = authService.isAuthenticated();

  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState("");
  const [isStreaming, setIsStreaming] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false); // Snackbar state

  const navigate = useNavigate();

  const phrases = useMemo(
    () => [
      t("blue kitchen"),
      t("steamer"),
      t("view of the lake"),
      t("luxury bathroom"),
      t("spacious garden"),
      t("cozy fireplace"),
    ],
    [t]
  );

  useEffect(() => {
    let phraseIndex = 0;
    let charIndex = 0;
    let isTyping = true;

    const streamText = () => {
      if (!isStreaming) return;

      const currentPhrase = phrases[phraseIndex];

      if (isTyping) {
        if (charIndex < currentPhrase.length) {
          setValue(currentPhrase.slice(0, charIndex + 1));
          charIndex++;
        } else {
          isTyping = false;
          timeoutRef.current = setTimeout(streamText, 1000); // Pause at end of phrase
          return;
        }
      } else {
        if (charIndex > 0) {
          setValue(currentPhrase.slice(0, charIndex - 1));
          charIndex--;
        } else {
          isTyping = true;
          phraseIndex = (phraseIndex + 1) % phrases.length;
          timeoutRef.current = setTimeout(streamText, 500); // Pause before next phrase
          return;
        }
      }

      timeoutRef.current = setTimeout(streamText, 100);
    };

    streamText();

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isStreaming, phrases]);

  const handleFocus = () => {
    setIsStreaming(false);
    setValue(""); // Clear the input value
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleButtonClick = () => {
    handleSearchAction();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearchAction();
    }
  };

  function redirectToChat() {
    if (!isAuthenticated) {
      setSearchParams({ login: true });
      return toast.error("Please login first");
    }

    // Construct query parameters using URLSearchParams
    const params = new URLSearchParams({ input: toQueryParamValue(value) });

    // Navigate with properly encoded query parameters
    navigate(`/chat?${params.toString()}`);
  }

  const handleSearchAction = () => {
    if (!value.trim()) {
      setSearchParams({ login: true });
      toast.error("Please enter the text.");
    } else {
      redirectToChat();
    }
  };

  return (
    <>
      <TextField
        onKeyDown={handleKeyDown}
        variant="outlined"
        value={value}
        onFocus={handleFocus}
        onBlur={(e) => {
          const relatedTarget = e.relatedTarget || e.nativeEvent.relatedTarget;
          if (relatedTarget && relatedTarget.id === "search-button") {
            // Focus is moving to the button, ignore onBlur logic
            return;
          }
          setIsStreaming(true);
        }}
        onChange={(e) => {
          if (!isStreaming) {
            setValue(e.target.value);
          }
        }}
        sx={{
          display: "flex",
          backgroundColor: "#ffffff",
          borderRadius: "10px",
          py: 0.5,
          width: "90%",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          color: `${theme.palette.primary.main}`,
          "& .MuiOutlinedInput-root": {
            paddingRight: "0",
            "& fieldset": {
              borderColor: "transparent",
            },
            "&:hover fieldset": {
              borderColor: "transparent",
            },
            "&.Mui-focused fieldset": {
              borderColor: "transparent",
            },
          },
          [theme.breakpoints.down("md")]: {
            py: 0,
          },
        }}
        inputProps={{
          style: {
            fontSize: "3vh",
            fontWeight: 700,
            px: "20px",
            color: `#3E63DD`,
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <span style={{ fontSize: "3vh", fontWeight: 400, color: "#000000" }}>
                {t("I want a")}
              </span>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <Button
                id="search-button"
                variant="contained"
                color="primary"
                onClick={handleButtonClick} // Handle button click here
                sx={{
                  height: "auto",
                  maxHeight: "100%",
                  width: "100%",
                  py: 3,
                  m: 2,
                  borderRadius: 1,
                  textTransform: "none",
                  fontSize: "21px",
                  textAlign: "center", // Center the text

                  [theme.breakpoints.down("lg")]: {
                    lineHeight: "normal",
                    minWidth: 140,
                    fontSize: "16px",
                    whiteSpace: "break-spaces", // Allow text to wrap
                  },

                  [theme.breakpoints.down("md")]: {
                    m: 1,
                  },

                  [theme.breakpoints.down("sm")]: {
                    py: 1,
                    m: 0,
                  },
                }}
              >
                {t("Start Your Search")}
              </Button>
            </InputAdornment>
          ),
        }}
      />

      {/* Snackbar to display when input is empty */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        message={"Please enter a search value!"}
      />
    </>
  );
};
