import React from "react";
import { Grid, Typography, Box, Paper, Container, Link } from "@mui/material";
import { styled } from "@mui/system";
import first from "../../../assets/1.svg";
import second from "../../../assets/2.svg";
import third from "../../../assets/3.svg";
import fourth from "../../../assets/4.svg";
import { useTranslation } from "react-i18next";
import authService from "../../../services/authService";
import { NavLink } from "react-router-dom";

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: "#F5F8FF",
  padding: theme.spacing(3),
  borderRadius: "8px",
  textAlign: "left",
  boxShadow: "none",
  minHeight: "180px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "flex-start",
  border: "1px solid #E0E5FF",
  height: "100%",
}));

const FeatureBox = ({ title, isFeature }) => {
  const { t } = useTranslation();

  const isAuthenticated = authService.isAuthenticated();


  
  const featureItems = [
    {
      title: t("Stay Ahead with Real-Time Market Insights"),
      description:
        t("Be the first to know about new listings in competitive markets. Our proactive AI scans the market in real time, giving you a crucial edge in high-demand rental spaces."),
      iconColor: "#DCE4FF", // Color for icon placeholder
      logo: first,
      width: 60,
    },
    {
      title: t("Smart Insights: Beyond the Basics"),
      description:
        t("Our AI dives deep, analyzing photos and text to evaluate details like view quality, noise levels, and interior design—offering insights that go far beyond basic data."),
      iconColor: "#E0E5FF", // Color for icon placeholder
      logo: second,
      width: 40,
    },
    {
      title: t("Lightning-Fast Applications"),
      description:
        t("With instant application submissions, our AI ensures you're among the first to apply for your dream property. Stay proactive and secure your spot ahead of the competition."),
      iconColor: "#DCE4FF", // Color for icon placeholder
      logo: third,
      width: 40,
    },
    {
      title: t("Smarter Searches, Better Matches"),
      description:
        t("Harness the power of over 60 data sources for a truly personalized search. From commute times and family-friendly amenities to market trends and tax implications, our AI adapts as your priorities shift—ensuring timely, tailored results."),
      iconColor: "#E0E5FF", // Color for icon placeholder
      logo: fourth,
      width: 60,
    },
  ];

  return (
    <Container maxWidth="xl">
      <Box id="features" sx={{ flexGrow: 1, padding: "48px 0 80px", mt: 6 }}>
        <Typography
          variant="h5"
          fontWeight={700}
          fontSize={"35px"}
          align="center"
          gutterBottom
        >
          {title}
        </Typography>

        <Grid
          container
          gap={2}
          sx={{
            marginTop: 4,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid
            container
            spacing={3}
            sx={{
              display: {
                xs: "block", // Display items as blocks on extra-small screens (mobile)
                sm: "block", // Display items as blocks on small screens (tablets)
                md: "grid", // Use grid layout on medium and larger screens
              },
              gridAutoRows: "1fr",
            }}
          >
            {featureItems.map((item, index) => {
              const isFirstRow = index < 2;
              return (
                <Grid
                  item
                  key={index}
                  sx={{
                    gridColumn: isFirstRow
                      ? index === 0
                        ? "span 3"
                        : "span 1"
                      : index === 2
                      ? "span 1"
                      : "span 3",
                    gridRow: isFirstRow ? "1 / 2" : "2 / 3",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: {
                      xs: "auto", // Flexible height on extra-small screens
                      sm: "auto", // Flexible height on small screens
                      md: "100%", // Full height on medium and larger screens
                    },
                  }}
                >
                  <StyledPaper
                    elevation={0}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <NavLink to={isAuthenticated ? "/chat" : "?login"} style={{ textDecoration: 'none' }}>
                      <Box>
                        <Typography
                          variant="h6"
                          sx={{
                            fontWeight: 700,
                            color: "black",
                            fontSize: "24px",
                            mb: 2,
                          }}
                        >
                          {item.title}
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{ color: "#6B7280", fontSize: "16px", mb: 3 }}
                        >
                          {item.description}
                        </Typography>
                      </Box>
                      <Box
                        component="img"
                        src={item.logo}
                        alt={item.title}
                        sx={{
                          width: "100%",
                          height: "auto",
                          maxHeight: { xs: "200px", md: "250px" },
                          objectFit: "contain",
                          mt: "auto",
                        }}
                      />
                    </NavLink>
                  </StyledPaper>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default FeatureBox;
