import React, { useState, useRef } from "react";

import * as Yup from "yup";
import emailjs from "@emailjs/browser";

import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import Layout from "../Layout/Layout";
import { theme } from "../../theme/theme";

import {
  Grid,
  Typography,
  TextField,
  Button,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Box,
  Radio,
  Snackbar,
  FormHelperText,
} from "@mui/material";
import { styled } from "@mui/system";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { toast } from "react-toastify";
import { Helmet } from 'react-helmet-async';


const ContactContainer = styled(Box)({
  backgroundColor: "#f7f9ff",
  padding: "20px",
});

const InnerContainer = styled(Grid)({
  backgroundColor: "white",
  borderRadius: "8px",
  display: "flex",
  alignItems: "stretch",
  border: "1px solid #d2dbf8",
  // height: '100%'
});

const InfoPaper = styled(Box)(({ theme }) => ({
  backgroundColor: "#3F63EC",
  padding: "30px",
  flex: 1,
  color: "white",
  display: "flex",
  flexDirection: "column",
  borderRadius: "8px",
  [theme.breakpoints.up("md")]: {
    minHeight: "470px", // Apply minHeight only on screens 900px or above
  },
}));

const InfoPaperBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    marginTop: "60px",
    marginBottom: "60px",
  },
}));

const FormPaper = styled(Box)({
  backgroundColor: "#fff",
  padding: "20px",
  flex: 1,
  display: "flex",
  flexDirection: "column",
});

const InputField = styled(TextField)({
  marginBottom: "20px",

  /* Hide incr/decr btns */
  "& input[type=number]": {
    "-moz-appearance": "textfield",
  },
  "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
  {
    "-webkit-appearance": "none",
    margin: 0,
  },
});

const CustomRadio = styled(Radio)(({ theme }) => ({
  "& .MuiSvgIcon-root": {
    width: "20px",
    height: "20px",
  },
}));

const iconStyle = {
  fontSize: "15px",
  color: "white",
};

const upperIconStyle = {
  fontSize: "25px",
  color: "white",
  paddingRight: "10px",
};

const iconWrapperStyle = {
  backgroundColor: theme.palette.background.iconColor, // Dark blue color for the circle
  borderRadius: "50%",
  width: "30px",
  height: "30px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
};

const Contact = () => {
  const { t } = useTranslation();
  const formRef = useRef();

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
  });

  const emptySpaceValidation = Yup.string()
    .trim("Empty space is not valid")
    .strict(true); // Enforce the trim validation strictly

  const nameValidation = emptySpaceValidation
    .min(2, "Name must be at least 3 characters long") // Minimum length requirement
    .max(20, "Name cannot be more than 20 characters") // Maximum length requirement
    .matches(
      /^[a-zA-Z0-9_.-]*$/,
      "Username can only contain letters, numbers, underscores, dots, or hyphens"
    ) // Name pattern validation
    .required(t("Required"));

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      subject: "",
      message: "",
    },

    validationSchema: Yup.object({
      email: Yup.string()
        .email(t("Invalid email address"))
        .required(t("Required")),
      firstName: nameValidation,
      lastName: nameValidation,
      subject: Yup.string().required("Please select a subject").min(1), // Ensure subject is selected
      message: Yup.string().required(t("Required")),
      phoneNumber: Yup.string()
        .required("Phone number is required")
        .test("is-valid-phone", "Phone number is not valid", (value) => {
          // Allow both international format and local format
          const internationalFormat = /^\+?[1-9]\d{1,14}$/; // International format
          const localFormat = /^\d{3}-\d{3}-\d{4}$/; // Local format
          return (
            value &&
            (internationalFormat.test(value) || localFormat.test(value))
          );
        })
        .min(10, "Phone number must be at least 10 digits") // Adjust if needed
        .max(15, "Phone number can be up to 15 digits"),
    }),
    onSubmit: async (values, formikHelpers) => {
      emailjs
        .sendForm(
          process.env.REACT_APP_EMAIL_SERVICE_ID,
          process.env.REACT_APP_EMAIL_TEMPLATE_ID,
          formRef.current,
          {
            publicKey: process.env.REACT_APP_EMAIL_PUBLIC_KEY,
          }
        )
        .then(
          () => {
            toast.success("Thank you for your request!");
            formikHelpers.resetForm();
          },
          (error) => {
            console.log("FAILED...", error.text);
          }
        );
    },
  });

  function handleCloseSnackbar() {
    setSnackbar({ ...snackbar, open: false });
  }

  return (<><Helmet>
    <title>{t('title_contact')}</title></Helmet>
    <Layout id="ContactLayout">
      <ContactContainer id="ContactContainer">
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={12} md={10} sx={{ paddingBottom: "50px" }}>
            <Typography
              variant="h2"
              sx={{ paddingBottom: "30px" }}
              align="center"
            >
              {t("Contact Us")}
            </Typography>
            <Typography variant="body1" align="center">
              {t("Any question or remarks? Just write us a message!")}
            </Typography>
          </Grid>

          <Grid item xs={12} md={10}>
            <InnerContainer container spacing={2}>
              <Grid item xs={12} md={4}>
                <InfoPaper elevation={3}>
                  <Typography
                    variant="h6"
                    style={{ color: "white", fontWeight: "bold" }}
                    gutterBottom
                  >
                    {t("Contact Information")}
                  </Typography>
                  {/* <Typography
                    variant="body1"
                    style={{ color: "white" }}
                    gutterBottom
                  >
                    {t("Say something to start a live chat!")}
                  </Typography> */}

                  <InfoPaperBox>
                  <Box sx={{ display: "flex", alignItems: "center" }} mt={3}>
                      {/* <EmailOutlinedIcon sx={upperIconStyle} /> */}
                      <Typography
                        variant="body1"
                        style={{ color: "white" }}
                        gutterBottom
                      >
                       LandMarK Lens GMBH
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }} mt={3}>
                      <PhoneOutlinedIcon sx={upperIconStyle} />
                      <Typography
                        variant="body1"
                        style={{ color: "white" }}
                        gutterBottom
                      >
                        +1012 3456 789
                      </Typography>
                    </Box>
                    
                    <Box sx={{ display: "flex", alignItems: "center" }} mt={3}>
                      <LocationOnIcon sx={upperIconStyle} />
                      <Typography
                        variant="body1"
                        style={{ color: "white" }}
                        gutterBottom
                      >
                        Sandbuckstrasse 24, Scheniesingen 5425
                      </Typography>
                    </Box>
                  </InfoPaperBox>
                  {/* <Box
                    mt={3}
                    display="flex"
                    justifyContent="space-between"
                    width="180px"
                  >
                    <Box sx={iconWrapperStyle}>
                      <FacebookIcon sx={iconStyle} />
                    </Box>
                    <Box sx={iconWrapperStyle}>
                      <TwitterIcon sx={iconStyle} />
                    </Box>
                    <Box sx={iconWrapperStyle}>
                      <LinkedInIcon sx={iconStyle} />
                    </Box>
                    <Box sx={iconWrapperStyle}>
                      <InstagramIcon sx={iconStyle} />
                    </Box>
                  </Box> */}
                </InfoPaper>
              </Grid>

              <Grid id="ContactFormGrid" item xs={12} md={8}>
                <FormPaper id="ContactFormFormPaper" elevation={3}>
                  <form
                    ref={formRef}
                    id="ContactForm"
                    style={{ flex: 1 }}
                    onSubmit={formik.handleSubmit}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <FormLabel
                          component="legend"
                          sx={{ color: theme.palette.text.primary, mb: 1 }}
                        >
                          {t("First name")} *
                        </FormLabel>
                        <InputField
                          size="small"
                          placeholder={t("Enter your First name")}
                          variant="outlined"
                          fullWidth
                          required
                          name="firstName"
                          {...formik.getFieldProps("firstName")}
                          error={
                            formik.touched.firstName &&
                            !!formik.errors.firstName
                          }
                          helperText={
                            formik.touched.firstName && formik.errors.firstName
                          }
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <FormLabel
                          component="legend"
                          sx={{ color: theme.palette.text.primary, mb: 1 }}
                        >
                          {t("Last name")} *
                        </FormLabel>
                        <InputField
                          size="small"
                          placeholder={t("Enter your Last name")}
                          variant="outlined"
                          fullWidth
                          required
                          name="lastName"
                          {...formik.getFieldProps("lastName")}
                          error={
                            formik.touched.lastName && !!formik.errors.lastName
                          }
                          helperText={
                            formik.touched.lastName && formik.errors.lastName
                          }
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <FormLabel
                          component="legend"
                          sx={{ color: theme.palette.text.primary, mb: 1 }}
                        >
                          {t("Email")} *
                        </FormLabel>
                        <InputField
                          size="small"
                          placeholder={t("Enter your Email")}
                          type="email"
                          name="email"
                          variant="outlined"
                          fullWidth
                          required
                          {...formik.getFieldProps("email")}
                          error={formik.touched.email && !!formik.errors.email}
                          helperText={
                            formik.touched.email && formik.errors.email
                          }
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        {/* <FormLabel
                          component="legend"
                          sx={{ color: theme.palette.text.primary, mb: 1 }}
                        >
                          {t("Phone number")} *
                        </FormLabel>
                        <InputField
                          type="text"
                          size="small"
                          placeholder="876-987-9876"
                          variant="outlined"
                          fullWidth
                          required
                          name="phoneNumber"
                          {...formik.getFieldProps("phoneNumber")}
                          error={
                            formik.touched.phoneNumber &&
                            !!formik.errors.phoneNumber
                          }
                          helperText={
                            formik.touched.phoneNumber &&
                            formik.errors.phoneNumber
                          }
                        /> */}
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl
                          component="fieldset"
                          required
                          error={!!formik.errors.subject}
                          helperText={formik.errors.subject}
                        >
                          {/* <FormLabel
                            component="legend"
                            sx={{
                              fontWeight: "bold",
                              fontSize: "1.1rem",
                              color: theme.palette.text.primary,
                              "&.Mui-focused": {
                                color: theme.palette.text.primary,
                              },
                              "&:focus": {
                                outline: "none",
                              },
                            }}
                          >
                            {t("Select Subject")}?
                          </FormLabel> */}
                          {/* <RadioGroup
                            required
                            row
                            aria-label="subject"
                            name="subject"
                            value={formik.values.subject}
                            onChange={formik.handleChange}
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <FormControlLabel
                              value="General Inquiry"
                              sx={{ color: theme.palette.text.primary }}
                              control={
                                <CustomRadio
                                  icon={
                                    <CheckBoxOutlineBlankIcon fontSize="small" />
                                  }
                                  checkedIcon={
                                    <CheckBoxIcon fontSize="small" />
                                  }
                                />
                              }
                              label={t("General Inquiry")}
                            />
                            <FormControlLabel
                              value="General Inquiry1"
                              sx={{ color: theme.palette.text.primary }}
                              control={
                                <CustomRadio
                                  icon={
                                    <CheckBoxOutlineBlankIcon fontSize="small" />
                                  }
                                  checkedIcon={
                                    <CheckBoxIcon fontSize="small" />
                                  }
                                />
                              }
                              label={`${t("General Inquiry")} 1`}
                            />
                            <FormControlLabel
                              value="General Inquiry2"
                              sx={{ color: theme.palette.text.primary }}
                              control={
                                <CustomRadio
                                  icon={
                                    <CheckBoxOutlineBlankIcon fontSize="small" />
                                  }
                                  checkedIcon={
                                    <CheckBoxIcon fontSize="small" />
                                  }
                                />
                              }
                              label={`${t("General Inquiry")} 2`}
                            />
                            <FormControlLabel
                              value="General Inquiry3"
                              sx={{ color: theme.palette.text.primary }}
                              control={
                                <CustomRadio
                                  icon={
                                    <CheckBoxOutlineBlankIcon fontSize="small" />
                                  }
                                  checkedIcon={
                                    <CheckBoxIcon fontSize="small" />
                                  }
                                />
                              }
                              label={`${t("General Inquiry")} 3`}
                            />
                          </RadioGroup> */}
                          {formik.errors.subject && (
                            <FormHelperText color="red">
                              {formik.errors.subject}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <FormLabel
                          component="legend"
                          sx={{ color: theme.palette.text.primary, mb: 1 }}
                        >
                          {t("Message")} *
                        </FormLabel>
                        <InputField
                          placeholder={t("Write your Message..")}
                          variant="outlined"
                          multiline
                          rows={4}
                          fullWidth
                          required
                          name="message"
                          {...formik.getFieldProps("message")}
                          error={
                            formik.touched.message && !!formik.errors.message
                          }
                          helperText={
                            formik.touched.message && formik.errors.message
                          }
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Button
                          type="submit"
                          variant="contained"
                          sx={{ float: "right" }}
                        >
                          {t("Send Message")}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </FormPaper>
              </Grid>
            </InnerContainer>
          </Grid>
        </Grid>
      </ContactContainer>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbar.message}
      />
    </Layout></>
  );
};

export default Contact;
