import Home from "../pages/Home/Home";
import Chat from "../pages/Chat/Chat";
import { Navigate, useLocation } from "react-router-dom";
import ChatOwner from "../pages/ChatOwner/ChatOwner";
import HomeDetails from "../pages/HomeDetails/HomeDetails";
import Payment from "../pages/Payment/Payment";
import Plan from "../pages/Plan/Plan";
import HowItWorks from "../pages/HowItWork/HowItWork";
import FeatureBox from "../pages/Features/Features";
import About from "../pages/About/About";
import Contact from "../pages/Contact/Contact";
import FAQ from "../pages/FAQ/FAQ";
import BlogList from "../pages/BlogList/BlogList";
import BlogItemPage from "../pages/BlogList/BlogItemPage";
import Tenants from "../pages/Tenants/Tenants";
import Owners from "../pages/Owners/Owners";
import PaymentSuccessful from "../pages/PaymentSuccess/Payment";
import Welcome from "../pages/Welcome/Welcome";
import EditProfile from "../pages/EditProfile/EditProfile";
import MyAccount from "../pages/MyAccount/MyAccount";
import OwnerAccount from "../pages/OwnerAccount/OwnerAccount";
import authService from "../services/authService";
import { ForgotPassword } from "../pages/ForgotPassword/ForgotPassword";
import { ResetPassword } from "../pages/ResetPassword/ResetPassword";

const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  const isAuthenticated = authService.isAuthenticated();

  if (!isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
};

export const NAVIGATION_ROUTE = [
  { path: "/", element: <Home /> },
  { path: "/google-redirect", element: <Home /> },
  { path: "/how-it-works", element: <HowItWorks /> },
  { path: "/tenants", element: <Tenants /> },
  { path: "/owners", element: <Owners /> },
  { path: "/features", element: <FeatureBox /> },
  { path: "/about-us", element: <About /> },
  { path: "/contact-us", element: <Contact /> },
  { path: "/faq", element: <FAQ /> },
  { path: "/blog-list", element: <BlogList /> },
  { path: "/blog", element: <BlogItemPage /> },
  { path: "/plan", element: <Plan /> },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
  },

  {
    path: "/my-account",
    element: (
      <ProtectedRoute>
        <MyAccount />
      </ProtectedRoute>
    ),
  },
  {
    path: "/chat",
    element: (
      <ProtectedRoute>
        <Chat />
      </ProtectedRoute>
    ),
  },
  {
    path: "/chat-owner",
    element: (
      <ProtectedRoute>
        <ChatOwner />
      </ProtectedRoute>
    ),
  },
  {
    path: "/home-details/:id",
    element: (
      <ProtectedRoute>
        <HomeDetails />
      </ProtectedRoute>
    ),
  },
  {
    path: "/payment/:id",
    element: (
      <ProtectedRoute>
        <Payment />
      </ProtectedRoute>
    ),
  },
  {
    path: "/payment-successful",
    element: (
      <ProtectedRoute>
        <PaymentSuccessful />
      </ProtectedRoute>
    ),
  },
  {
    path: "/welcome",
    element: (
      <ProtectedRoute>
        <Welcome />
      </ProtectedRoute>
    ),
  },
  {
    path: "/edit-profile",
    element: (
      <ProtectedRoute>
        <EditProfile />
      </ProtectedRoute>
    ),
  },

  {
    path: "/owner-account",
    element: (
      <ProtectedRoute>
        <OwnerAccount />
      </ProtectedRoute>
    ),
  },
];
