import React from "react";
import * as Yup from "yup";

import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import { theme } from "../../theme/theme";

import { styled } from "@mui/system";

import {
  Box,
  TextField,
  Button,
  FormLabel,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import { PasswordField } from "../passwordField";

const InputField = styled(TextField)({
  marginBottom: "20px",
});

const SignupForm = ({ onBack, onSubmit, userType }) => {
  const { t, i18n } = useTranslation();
  const emptySpaceValidation = Yup.string()
    .trim("Empty space is not valid")
    .strict(true); // Enforce the trim validation strictly

  const nameValidation = emptySpaceValidation
    .min(2, "Name must be at least 3 characters long") // Minimum length requirement
    .max(20, "Name cannot be more than 20 characters") // Maximum length requirement
    .matches(
      /^[a-zA-Z0-9_.-]*$/,
      "Username can only contain letters, numbers, underscores, dots, or hyphens"
    ) // Name pattern validation
    .required(t("Required"));

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
      firstname: "",
      lastname: "",
      user_type: userType || "tenant",
      status: "active",
      language: "de",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t("Invalid email address"))
        .required(t("Required")),
      password: emptySpaceValidation
        .min(8, t("Must be at least 8 characters"))
        .required(t("Required")),
      confirmPassword: emptySpaceValidation
        .min(8, t("Must be at least 8 characters"))
        .oneOf([Yup.ref("password"), null], t("Passwords must match"))
        .required(t("Required")),
      firstname: nameValidation,
      lastname: nameValidation,
      user_type: Yup.string()
        .oneOf(["tenant", "landlord"])
        .required(t("Required")),
      language: Yup.string()
        .oneOf(["de", "en", "fr", "it"])
        .required(t("Required")),
    }),
    onSubmit: async (values) => {
      const { confirmPassword, ...dataToSend } = values;
      onSubmit(dataToSend);
      // await authService.register(dataToSend);
      console.log("Signup data sent to server:", dataToSend);
      // Here you would typically send the data to your server
      // For example: axios.post('/api/signup', dataToSend)
    },
  });

  const changeLanguage = (e) => {
    const lng = e.target.value;
    i18n.changeLanguage(lng);
    localStorage.setItem("i18nextLng", lng);
  };

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      noValidate
      sx={{ mt: 1 }}
    >
      <FormLabel
        component="legend"
        sx={{ color: theme.palette.text.primary, mb: 1 }}
      >
        {t("Email Address")}
      </FormLabel>
      <InputField
        size="small"
        fullWidth
        id="email"
        name="email"
        placeholder={t("Enter your Email")}
        {...formik.getFieldProps("email")}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
        variant="outlined"
      />

      <FormLabel
        component="legend"
        sx={{ color: theme.palette.text.primary, mb: 1 }}
      >
        {t("Password")}
      </FormLabel>
      <PasswordField
        {...formik.getFieldProps("password")}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password}
      />

      <FormLabel
        component="legend"
        sx={{ color: theme.palette.text.primary, mb: 1 }}
      >
        {t("Confirm Password")}
      </FormLabel>
      <PasswordField
        placeholder={t("Confirm your Password")}
        {...formik.getFieldProps("confirmPassword")}
        error={
          formik.touched.confirmPassword &&
          Boolean(formik.errors.confirmPassword)
        }
        helperText={
          formik.touched.confirmPassword && formik.errors.confirmPassword
        }
      />

      <FormLabel
        component="legend"
        sx={{ color: theme.palette.text.primary, mb: 1 }}
      >
        {t("First Name")}
      </FormLabel>
      <InputField
        size="small"
        fullWidth
        name="firstname"
        placeholder={t("Enter your First Name")}
        {...formik.getFieldProps("firstname")}
        error={formik.touched.firstname && Boolean(formik.errors.firstname)}
        helperText={formik.touched.firstname && formik.errors.firstname}
        variant="outlined"
      />

      <FormLabel
        component="legend"
        sx={{ color: theme.palette.text.primary, mb: 1 }}
      >
        {t("Last Name")}
      </FormLabel>
      <InputField
        size="small"
        fullWidth
        name="lastname"
        placeholder={t("Enter your Last Name")}
        {...formik.getFieldProps("lastname")}
        error={formik.touched.lastname && Boolean(formik.errors.lastname)}
        helperText={formik.touched.lastname && formik.errors.lastname}
        variant="outlined"
      />

      <FormControl fullWidth sx={{ mb: 2 }}>
        <FormLabel
          component="legend"
          sx={{ color: theme.palette.text.primary, mb: 1 }}
        >
          {t("User Type")}
        </FormLabel>
        <Select
          size="small"
          {...formik.getFieldProps("user_type")}
          error={formik.touched.user_type && Boolean(formik.errors.user_type)}
        >
          <MenuItem value="tenant">{t("Tenant")}</MenuItem>
          <MenuItem value="landlord">{t("Owner")}</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth sx={{ mb: 2 }}>
        <FormLabel
          component="legend"
          sx={{ color: theme.palette.text.primary, mb: 1 }}
        >
          {t("Language")}
        </FormLabel>
        <Select
          onChange={(event) => {
            changeLanguage(event);
            formik.setFieldValue("language", event.target.value);
          }}
          value={formik.values.language}
          size="small"
          error={formik.touched.language && Boolean(formik.errors.language)}
        >
          <MenuItem value="de">{t("German")}</MenuItem>
          <MenuItem value="en">{t("English")}</MenuItem>
          <MenuItem value="fr">{t("French")}</MenuItem>
          <MenuItem value="it">{t("Italian")}</MenuItem>
        </Select>
      </FormControl>

      <Button
        type="submit"
        variant="contained"
        sx={{ mt: 3, mb: 2, float: "right", width: "auto" }}
        disabled={!formik.isValid || formik.isSubmitting}
      >
        {t("Sign Up")}
      </Button>
    </Box>
  );
};

export default SignupForm;
