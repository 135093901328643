import React from "react";

import {
  Box,
  TextField,
  Button,
  FormLabel,
  Link,
  Typography,
} from "@mui/material";

import * as Yup from "yup";
import { useFormik } from "formik";
import authService from "../../services/authService";
import { useTranslation } from "react-i18next";

import { theme } from "../../theme/theme";
import { PasswordField } from "../passwordField";

import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";

const InputField = styled(TextField)({
  marginBottom: "20px",
});

const LoginForm = ({ onSubmit }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t("Invalid email address"))
        .required(t("Required")),
      password: Yup.string().required(t("Required")),
    }),
    onSubmit: async (values) => {
      onSubmit(values);
      console.log("Login submitted", values);
    },
  });

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      noValidate
      sx={{ mt: 1 }}
    >
      <FormLabel
        component="legend"
        sx={{ color: theme.palette.text.primary, mb: 1 }}
      >
        {t("Email Address")}
      </FormLabel>
      <InputField
        fullWidth
        size="small"
        id="email"
        name="email"
        placeholder={t("Enter your Email")}
        autoComplete="email"
        autoFocus
        {...formik.getFieldProps("email")}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
        variant="outlined"
      />

      {/* <FormLabel
        component="legend"
        sx={{ color: theme.palette.text.primary, mb: 1 }}
      >
        {t("Password")}
      </FormLabel> */}
      <FormLabel
        component="legend"
        sx={{
          color: theme.palette.text.primary,
          mb: 1,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {t("Password")}
      </FormLabel>

      <PasswordField
        placeholder={t("Enter your Password")}
        id="password"
        autoComplete="current-password"
        {...formik.getFieldProps("password")}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password}
      />

      <Typography
        onClick={() => navigate("/forgot-password")}
        sx={{
          fontSize: "0.875rem",
          color: theme.palette.primary.main,
          cursor: "pointer",

          "&:hover": {
            textDecoration: "underline",
          },
        }}
      >
        {t("Forgot password?")}
      </Typography>

      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2, float: "right", width: "auto" }}
        disabled={!formik.isValid || formik.isSubmitting}
      >
        {t("Sign In")}
      </Button>
    </Box>
  );
};

export default LoginForm;
