import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, Grid, Paper, Chip, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AiSuggestion from '../../../../pages/HomeDetails/subComponents/AiSuggestion';
import LocationMap from '../../../../pages/HomeDetails/subComponents/Location';
import { useDispatch, useSelector } from 'react-redux';
import { getPropertyImages } from '../../../../store/slices/propertiesSlice';
import { numberFormatter } from '../../../../utils';
import { getCurrencySymbol } from '../../../../constants/currencyMapping';
import { useTranslation } from 'react-i18next';

const mockPropertyImages = {
  data: [
    {
      property_id: 2,
      image_url:
        'https://media2.homegate.ch/f_auto/t_web_dp_fullscreen/listings/v2/hgonif/4001637107/image/e4f70d92694d12d06e24fe1ae97eaeb7.jpg',
      is_primary: true,
      id: 1,
      created_at: '2024-10-10T10:17:57'
    }
  ]
};

const PropertyDetailsModal = ({ open, handleClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const propertyImages = useSelector((state) => state.properties.propertyImages);
  const propertyImages = mockPropertyImages;
  console.log('propertyImages', propertyImages);
  const property = open;
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (property) {
      dispatch(getPropertyImages(property.id));
    }
  }, [dispatch, open]);

  useEffect(() => {
    if (propertyImages.data && propertyImages.data.length > 0 && images.length === 0) {
      const newImages = propertyImages.data.map((item) => item.image_url);
      setImages(newImages);
    }
  }, [propertyImages, images]);

  if (!property) return null;

  const mainImage = images[0] || '';
  const thumbnails = images.slice(1, 5);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };
  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="property-details-modal">
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          maxWidth: 900,
          bgcolor: 'background.paper',
          boxShadow: 24,
          maxHeight: '90vh',
          overflowY: 'auto'
        }}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}>
          <CloseIcon />
        </IconButton>

        <Typography
          sx={{ paddingLeft: '40px', paddingTop: '17px' }}
          variant="h6"
          component="h2"
          gutterBottom>
          {t('Property details')}
        </Typography>

        <Box
          sx={{
            border: '1px solid #ddd',
            marginBottom: '45px',
            marginTop: '17px'
          }}
        />

        {/* Image Gallery */}

        <Box
          sx={{
            position: 'relative',
            mb: 3,
            paddingLeft: '40px',
            paddingRight: '40px'
          }}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={8}>
              <Box
                sx={{
                  position: 'relative',
                  height: 310,
                  borderRadius: 2,
                  overflow: 'hidden'
                }}>
                <img
                  src={mainImage}
                  alt="Main Property View"
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
                <Chip
                  label={t('Ad Active')}
                  color="success"
                  size="small"
                  sx={{
                    position: 'absolute',
                    top: 16,
                    left: 16,
                    backgroundColor: '#4CAF50',
                    color: 'white',
                    fontWeight: 'bold'
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={4} container spacing={1}>
              {thumbnails.map((img, index) => (
                <Grid item xs={6} key={index}>
                  <Box
                    sx={{
                      position: 'relative',
                      height: '150px',
                      borderRadius: 2,
                      overflow: 'hidden'
                    }}>
                    <img
                      src={img}
                      alt={`Thumbnail ${index + 1}`}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover'
                      }}
                    />
                    {index === 3 && (
                      <Box
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          backgroundColor: 'rgba(0,0,0,0.5)',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}>
                        <Typography variant="caption" sx={{ color: 'white', fontWeight: 'bold' }}>
                          {t('See all 50 photos')}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Box>

        {/* AI Recommendations */}
        <Box sx={{ marginLeft: '40px', marginRight: '40px' }}>
          <AiSuggestion hideUpgrade fullWidth />
        </Box>

        {/* Property Details */}

        <Box sx={{ mt: 3, marginLeft: '40px', marginRight: '40px' }}>
          <Typography
            variant="h4"
            gutterBottom
            sx={{ fontWeight: 'bold', mb: 3, fontSize: '24px' }}>
            {property.address}, {property.city}, {property.country}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between'
            }}>
            <Box>
              <Box sx={{ display: 'flex', marginBottom: '18px' }}>
                <Typography sx={{ marginRight: '20px' }}>{t('Price')}</Typography>
                <Typography>
                  {numberFormatter(property.price)}
                  {getCurrencySymbol(property.currency)} {t('per month')}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex' }}>
                <Typography sx={{ marginRight: '20px' }}>{t('Rooms')}</Typography>
                <Typography>
                  {property.bedrooms} {t('beds')} / {property.bathrooms} {t('baths')}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Box sx={{ display: 'flex', marginBottom: '18px' }}>
                <Typography sx={{ marginRight: '20px' }}>{t('Size')}</Typography>
                <Typography>{property.square_feet} m2</Typography>
              </Box>
              <Box sx={{ display: 'flex' }}>
                <Typography sx={{ marginRight: '20px' }}>{t('Built')}</Typography>
                <Typography>2015</Typography>
                {/* <Typography>{property.year_built}</Typography> */}
              </Box>
            </Box>
            <Box>
              <Box sx={{ display: 'flex', marginBottom: '18px' }}>
                <Typography sx={{ marginRight: '20px' }}>{t('Past viewing')}</Typography>
                {/* <Typography>{formatDate(property?.last_viewing_date)}</Typography> */}
                <Typography>{'04.01.2025'}</Typography>
              </Box>
              <Box sx={{ display: 'flex' }}>
                <Typography sx={{ marginRight: '20px' }}>{t('Upcoming viewing')}</Typography>
                {/* <Typography>{formatDate(property?.next_viewing_date)}</Typography> */}
                <Typography>{'10.01.2025'}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            px: 5,
            mt: 5
          }}>
          <LocationMap
            hideContact
            padding={5}
            width={'100%'}
            title={t('Location')}
            latitude={property.latitude}
            longitude={property.longitude}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default PropertyDetailsModal;
