export const DotsThreeVertical = ({ fill }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="DotsThreeVertical">
      <path
        id="Vector"
        d="M8.75 8C8.75 8.14834 8.70601 8.29334 8.6236 8.41668C8.54119 8.54001 8.42406 8.63614 8.28701 8.69291C8.14997 8.74967 7.99917 8.76453 7.85368 8.73559C7.7082 8.70665 7.57456 8.63522 7.46967 8.53033C7.36478 8.42544 7.29335 8.2918 7.26441 8.14632C7.23547 8.00083 7.25032 7.85003 7.30709 7.71299C7.36386 7.57594 7.45999 7.45881 7.58332 7.3764C7.70666 7.29399 7.85166 7.25 8 7.25C8.19891 7.25 8.38968 7.32902 8.53033 7.46967C8.67098 7.61032 8.75 7.80109 8.75 8ZM8 4.5C8.14834 4.5 8.29334 4.45601 8.41668 4.3736C8.54001 4.29119 8.63614 4.17406 8.69291 4.03701C8.74968 3.89997 8.76453 3.74917 8.73559 3.60368C8.70665 3.4582 8.63522 3.32456 8.53033 3.21967C8.42544 3.11478 8.2918 3.04335 8.14632 3.01441C8.00083 2.98547 7.85003 3.00032 7.71299 3.05709C7.57594 3.11386 7.45881 3.20999 7.3764 3.33332C7.29399 3.45666 7.25 3.60166 7.25 3.75C7.25 3.94891 7.32902 4.13968 7.46967 4.28033C7.61032 4.42098 7.80109 4.5 8 4.5ZM8 11.5C7.85166 11.5 7.70666 11.544 7.58332 11.6264C7.45999 11.7088 7.36386 11.8259 7.30709 11.963C7.25032 12.1 7.23547 12.2508 7.26441 12.3963C7.29335 12.5418 7.36478 12.6754 7.46967 12.7803C7.57456 12.8852 7.7082 12.9566 7.85368 12.9856C7.99917 13.0145 8.14997 12.9997 8.28701 12.9429C8.42406 12.8861 8.54119 12.79 8.6236 12.6667C8.70601 12.5433 8.75 12.3983 8.75 12.25C8.75 12.0511 8.67098 11.8603 8.53033 11.7197C8.38968 11.579 8.19891 11.5 8 11.5Z"
        fill={fill || "#343330"}
      />
    </g>
  </svg>
);
