import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Chip
} from '@mui/material';
import { View } from '../../../../assets/svg/View';
import { Edit } from '../../../../assets/svg/Edit';
import { Delete } from '../../../../assets/svg/Delete';
import AddIcon from '@mui/icons-material/Add';
import PropertyDetailsModal from './AddProperty';
import DeleteConfirmationModal from './DeleteProperty';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteProperty,
  fetchProperties,
  selectProperties
} from '../../../../store/slices/propertiesSlice';
import { numberFormatter } from '../../../../utils';
import ImageIcon from '@mui/icons-material/Image';
import { getCurrencySymbol } from '../../../../constants/currencyMapping';
import { useTranslation } from 'react-i18next';

const ImagePlaceholder = () => (
  <Box
    sx={{
      width: '200px',
      height: '200px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      bgcolor: '#f0f0f0'
    }}>
    <ImageIcon sx={{ fontSize: 60, color: '#bdbdbd' }} />{' '}
  </Box>
);

const PropertyCard = ({ property, setDeletePropertyModals, setPropertyDetailsModal, navigate }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Card
        sx={{
          display: 'flex',
          maxWidth: 600,
          my: 2,
          position: 'relative',
          border: '1px solid #c1d0ff',
          boxShadow: 'unset'
        }}>
        {property.primary_image_url ? (
          <CardMedia
            component="img"
            sx={{ width: 200, height: 200, objectFit: 'cover' }}
            image={property.primary_image_url}
            alt={property.title}
          />
        ) : (
          <ImagePlaceholder />
        )}
        <Chip
          label={t('Ad Active')}
          color="success"
          size="small"
          sx={{ position: 'absolute', top: 10, left: 10 }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            marginLeft: '5px'
          }}>
          <CardContent>
            <Typography variant="h5" component="div" gutterBottom>
              {numberFormatter(property.price)}
              {getCurrencySymbol(property.currency)}{' '}
              <Typography variant="caption">{t('per month')}</Typography>
            </Typography>
            <Typography variant="body2" color="text.secondary" gutterBottom>
              {property.address}, {property.city}, {property.country}
            </Typography>
            <Box sx={{ marginTop: '15px', display: 'flex' }} variant="body2" color="text.secondary">
              <Typography sx={{ paddingRight: '10px' }}>
                {property.bedrooms} {t('beds')}
              </Typography>
              <Typography sx={{ paddingRight: '10px' }}>
                {property.bathrooms} {t('baths')}{' '}
              </Typography>
              <Typography>
                {numberFormatter(property.square_feet)} m2 {t('size')}
              </Typography>
            </Box>
          </CardContent>
          <CardActions
            sx={{
              padding: 'unset',
              marginLeft: '10px',
              marginTop: '19px',
              lineHeight: 'unset!important'
            }}>
            <Button
              onClick={() => setPropertyDetailsModal(property)}
              sx={{
                lineHeight: 'unset!important',
                color: '#1C2024',
                marginLeft: 'unset!important'
              }}
              startIcon={<View />}
              size="small"></Button>
            <Button
              onClick={() => navigate('/chat-owner?id=2')}
              sx={{
                lineHeight: 'unset!important',
                color: '#1C2024',
                marginLeft: 'unset!important'
              }}
              startIcon={<Edit />}
              size="small"></Button>
            <Button
              onClick={() => setDeletePropertyModals(property)}
              sx={{
                lineHeight: 'unset!important',
                color: '#1C2024',
                marginLeft: 'unset!important'
              }}
              startIcon={<Delete />}
              size="small"></Button>
          </CardActions>
        </Box>
      </Card>
    </Box>
  );
};

const mockProperty = {
  id: 1,
  primary_image_url:
    'https://media2.homegate.ch/f_auto/t_web_dp_fullscreen/listings/v2/hgonif/4001637107/image/e4f70d92694d12d06e24fe1ae97eaeb7.jpg',
  title: 'Beautiful Apartment in the City Center',
  price: 1500,
  currency: 'USD',
  address: 'Sonnenweg 15, 8810',
  city: 'Horgen',
  country: 'Switzerland',
  bedrooms: 2,
  bathrooms: 2,
  square_feet: 1800
};

const ListingManagement = () => {
  const { t } = useTranslation();
  const [propertyDetailsModal, setPropertyDetailsModal] = useState(null);
  const [deletePropertyModals, setDeletePropertyModals] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { properties } = useSelector(selectProperties);

  useEffect(() => {
    dispatch(fetchProperties());
  }, [dispatch]);

  const handleDeleteProperty = () => {
    dispatch(deleteProperty(deletePropertyModals.id)).then(() => {
      setDeletePropertyModals(false);
      dispatch(fetchProperties());
    });
  };

  return (
    <Box sx={{}}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 3
        }}>
        <Typography sx={{ fontSize: '24px', fontWeight: '500' }} variant="h4" component="h1">
          {t('Listing Management')}
        </Typography>
        <Button
          sx={{ width: '150px', height: '37px', boxShadow: 'none' }}
          variant="contained"
          color="primary"
          onClick={() => navigate('/chat-owner')}
          startIcon={<AddIcon />}>
          {t('Add property')}
        </Button>
      </Box>
      <Box sx={{ display: 'flex', width: '100%', gap: '12px', flexWrap: 'wrap' }}>
        {[mockProperty].map((property, index) => (
          <Box>
            <PropertyCard
              key={index}
              property={property}
              setDeletePropertyModals={setDeletePropertyModals}
              setPropertyDetailsModal={setPropertyDetailsModal}
              navigate={navigate}
            />
          </Box>
        ))}
      </Box>
      <PropertyDetailsModal
        open={propertyDetailsModal}
        handleClose={() => setPropertyDetailsModal(null)}
      />
      <DeleteConfirmationModal
        open={deletePropertyModals}
        handleDelete={handleDeleteProperty}
        handleClose={() => setDeletePropertyModals(false)}
      />
    </Box>
  );
};

export default ListingManagement;
