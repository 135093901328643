export const CreditCard = ({ fill }) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.3047 3.75H3.30469C2.97317 3.75 2.65522 3.8817 2.4208 4.11612C2.18638 4.35054 2.05469 4.66848 2.05469 5V15C2.05469 15.3315 2.18638 15.6495 2.4208 15.8839C2.65522 16.1183 2.97317 16.25 3.30469 16.25H18.3047C18.6362 16.25 18.9542 16.1183 19.1886 15.8839C19.423 15.6495 19.5547 15.3315 19.5547 15V5C19.5547 4.66848 19.423 4.35054 19.1886 4.11612C18.9542 3.8817 18.6362 3.75 18.3047 3.75ZM18.3047 5V6.875H3.30469V5H18.3047ZM18.3047 15H3.30469V8.125H18.3047V15ZM17.0547 13.125C17.0547 13.2908 16.9888 13.4497 16.8716 13.5669C16.7544 13.6842 16.5954 13.75 16.4297 13.75H13.9297C13.7639 13.75 13.605 13.6842 13.4877 13.5669C13.3705 13.4497 13.3047 13.2908 13.3047 13.125C13.3047 12.9592 13.3705 12.8003 13.4877 12.6831C13.605 12.5658 13.7639 12.5 13.9297 12.5H16.4297C16.5954 12.5 16.7544 12.5658 16.8716 12.6831C16.9888 12.8003 17.0547 12.9592 17.0547 13.125ZM12.0547 13.125C12.0547 13.2908 11.9888 13.4497 11.8716 13.5669C11.7544 13.6842 11.5954 13.75 11.4297 13.75H10.1797C10.0139 13.75 9.85496 13.6842 9.73775 13.5669C9.62054 13.4497 9.55469 13.2908 9.55469 13.125C9.55469 12.9592 9.62054 12.8003 9.73775 12.6831C9.85496 12.5658 10.0139 12.5 10.1797 12.5H11.4297C11.5954 12.5 11.7544 12.5658 11.8716 12.6831C11.9888 12.8003 12.0547 12.9592 12.0547 13.125Z"
      fill={fill || "#202020"}
    />
  </svg>
);
