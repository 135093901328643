import React, { useLayoutEffect, useState, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Loading from "../Loading/Loading";
import { jsonData } from "../../db";
import { styled } from "@mui/material";
import { useTranslation } from "react-i18next";

const StyledStepper = styled(Stepper)(({ theme }) => ({
  "& .MuiStepConnector-root": {
    marginLeft: "16px!important",
  },
}));

const StyledStepLabel = styled(StepLabel)(({ theme }) => ({
  svg: {
    width: 32,
    height: 32,
  },
  circle: {
    fill: "#D9D9E0",
  },
  text: {
    fill: "#202020",
  },
  "&:not(.Mui-disabled)": {
    circle: {
      fill: "#3E63DD",
    },
    text: {
      fill: "#fff",
    },
  },
}));

export function VerticalLinearStepper({ currentStep, isDone }) {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const scrollRef = useRef(null);
  const currentStepRef = useRef(null);

  useLayoutEffect(() => {
    typeof currentStep === "number" && setActiveStep(currentStep);
  }, [currentStep]);

  // Scroll to the active step when currentStep changes
  useEffect(() => {
    if (scrollRef.current) {
      const stepElement = Array.from(scrollRef.current.children).find(
        (child, index) => index === currentStep
      );

      if (stepElement) {
        stepElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  }, [currentStep]);

  return (
    <>
      {!isLoading ? (
        <Box sx={{ maxWidth: 400, mr: "56px", pb: 2 }}>
          <StyledStepper
            ref={scrollRef}
            id="VerticalLinearStepper"
            activeStep={activeStep}
            orientation="vertical"
          >
            {jsonData.stepper?.map((step, index) => (
              <Step
                {...(isDone ? { completed: isDone } : {})}
                ref={currentStepRef}
                id={`step_${index}`}
                key={index}
              >
                <StyledStepLabel>{t(step.label)}</StyledStepLabel>
              </Step>
            ))}
          </StyledStepper>
        </Box>
      ) : (
        <Loading />
      )}
    </>
  );
}
