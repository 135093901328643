import { Box, Typography } from "@mui/material";
import React from "react";
import GalleryCard from "../galleryCard/GalleryCard";
import AIAvatar from "../../assets/svg/AIAvatar";
import UpgradePlan from "../upgradePlan/UpgradePlan";
import { useTranslation } from "react-i18next";

const options = {
  year: "numeric",
  month: "short",
  day: "numeric",
  weekday: "short",
};

export const Gallery = ({ gallery }) => {
  const { t } = useTranslation();

  const date = new Date(); // Current date and time

  // Format the date
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "35px",
        padding: "20px",
        width: "100%",
        height: "100%",
        overflowY: "scroll",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">{t("Search feed")}</Typography>
          <UpgradePlan
            title={t("Upgrade Plan")}
            text={t("Get upto 10 matches and more")}
            color="#3E63DD"
            dark
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ display: "flex", gap: 1 }}>
            <AIAvatar />
            {t(
              "Available Property for you. You can click on the card for more details."
            )}
          </Typography>
          <Typography variant="body1" sx={{ fontSize: 12, color: "#646464" }}>
            {formattedDate}
          </Typography>
        </Box>
      </Box>

      {/* Card List */}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 3,
        }}
      >
        {gallery.map((item, index) => (
          <Box
            key={index}
            sx={{
              flexGrow: 1,
              position: "relative",
              display: "flex",
            }}
          >
            <GalleryCard index={index} item={item} />
          </Box>
        ))}
      </Box>
    </Box>
  );
};
