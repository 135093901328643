export const testimonialsData = [
    {
        id: 1,
        name: "Alice B.",
        location: "Bahnhofstrasse 10, 8001 Zürich",
        details: "4 Bed / 3 Ba • 3,200.- per month",
        avatar: "https://images.unsplash.com/photo-1519046904884-53103b34b206?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        rating: 5,
        text: "I was able to find the perfect home in no time. The service was exceptional and so easy to use.",
    },
    {
        id: 2,
        name: "Michael K.",
        location: "Rämistrasse 28, 8001 Zürich",
        details: "2 Bed / 2 Ba • 2,200.- per month",
        avatar: "https://images.unsplash.com/photo-1729628475938-54c04980a3fd?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        rating: 4,
        text: "Very efficient and user-friendly. Found a great place that fits my needs perfectly.",
    },
    {
        id: 3,
        name: "Olivia H.",
        location: "Limmatquai 70, 8001 Zürich",
        details: "3 Bed / 2 Ba • 2,800.- per month",
        avatar: "https://images.unsplash.com/photo-1729380137783-3d5803a8e4d1?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        rating: 5,
        text: "A fantastic experience! The AI-driven search made everything so simple and stress-free.",
    },
    {
        id: 4,
        name: "Sarah L.",
        location: "Seefeldstrasse 123, 8008 Zürich",
        details: "1 Bed / 1 Ba • 1,500.- per month",
        avatar: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        rating: 3,
        text: "Good service, though I had some minor issues with the search filters. Overall, a positive experience.",
    },
    {
        id: 5,
        name: "Orlando D.",
        location: "Niederdorfstrasse 66, 8001 Zürich",
        details: "3 Bed / 1 Ba • 2,400.- per month",
        avatar: "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        rating: 4,
        text: "Easy to use and reliable. I found a great apartment that I love.",
    },
    {
        id: 6,
        name: "Liam T.",
        location: "Zurichbergstrasse 112, 8044 Zürich",
        details: "4 Bed / 2 Ba • 3,000.- per month",
        avatar: "https://images.unsplash.com/photo-1729549013233-4035d2019014?q=80&w=1976&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        rating: 5,
        text: "This service saved me so much time. Highly recommended for anyone looking for a new home.",
    },
    {
        id: 7,
        name: "Chloe F.",
        location: "Langstrasse 50, 8004 Zürich",
        details: "2 Bed / 1 Ba • 1,900.- per month",
        avatar: "https://images.unsplash.com/photo-1541963463532-d68292c34b19?q=80&w=1976&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        rating: 4,
        text: "Smooth process from start to finish. The insights were spot-on.",
    },
    {
        id: 8,
        name: "James R.",
        location: "Sihlquai 10, 8005 Zürich",
        details: "3 Bed / 2 Ba • 2,600.- per month",
        avatar: "https://images.unsplash.com/photo-1730055075810-4c7a4ac885a1?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        rating: 5,
        text: "The best way to find a home. Simple, efficient, and accurate.",
    },
    {
        id: 9,
        name: "Isabella N.",
        location: "Europaallee 21, 8004 Zürich",
        details: "1 Bed / 1 Ba • 1,700.- per month",
        avatar: "https://images.unsplash.com/photo-1727161131036-147165bd771c?q=80&w=1894&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        rating: 4,
        text: "I had a great experience. Found a nice place quickly and without any hassle.",
    },
];
