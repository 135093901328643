import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "../../services/authService";
import axios from "axios";
// import i18next from "i18next";

const initialState = {
  isLoading: false,
  isSuccess: false,
  error: null,
  data: null,
};
const API_URL = process.env.REACT_APP_BASE_URL;

export const fetchUserProfile = createAsyncThunk(
  "account/fetchUserProfile",
  async (_, thunkAPI) => {
    try {
      const user = authService.getCurrentUser();
      const response = await axios.get(`${API_URL}/user/profile`, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      });
      // const userLanguage = response.data.language;
      // if (userLanguage) {
      //   i18next.changeLanguage(userLanguage);
      //   localStorage.setItem("i18nextLng", userLanguage);
      // }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const editUserProfile = createAsyncThunk(
  "account/editUserProfile",
  async (userData, thunkAPI) => {
    try {
      const user = authService.getCurrentUser();
      const response = await axios.put(`${API_URL}/user/profile`, userData, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateProfileAvatar = createAsyncThunk(
  "account/updateProfileAvatar",
  async (file, thunkAPI) => {
    try {
      const user = authService.getCurrentUser();
      const formData = new FormData();
      formData.append("file", file);

      const response = await axios.put(
        `${API_URL}/user/profile/avatar`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserProfile.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.data = action.payload;
      })
      .addCase(fetchUserProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.error = action.payload;
      })
      .addCase(editUserProfile.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(editUserProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.data = action.payload;
      })
      .addCase(editUserProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.error = action.payload;
      })

      .addCase(updateProfileAvatar.fulfilled, (state, action) => {
        state.data = { ...state.data, avatar_url: action.payload.avatar_url };
      });
  },
});

export const selectAccount = (state) => state.account;

export default accountSlice.reducer;
