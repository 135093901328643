import React from "react";
import Layout from "../Layout/Layout";
import FeatureBox from "../../components/sections/featureSection/Feature";
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet-async';

const Features = () => {
  const { t } = useTranslation();
  const heading = t("Features");
  const text = t(
    "Explore the innovative features of Home AI that make appartment hunting effortless and efficient"
  );
  return (<><Helmet>
    <title>{t('title_features')}</title></Helmet><Layout heading={heading} text={text}>
      <FeatureBox title={t("Explore the Features For Tenants")} isFeature />
      <FeatureBox title={t("Explore the Features For Owners")} isFeature />
    </Layout></>

  );
};

export default Features;
