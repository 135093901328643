export const Cvv = () => (
  <svg
    width="24"
    height="16"
    viewBox="0 0 24 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 -0.000183105H2C0.895 -0.000183105 0 0.894817 0 1.99982V13.0798C0 14.1848 0.895 15.0798 2 15.0798H22C23.105 15.0798 24 14.1848 24 13.0798V1.99982C24 0.894817 23.105 -0.000183105 22 -0.000183105Z"
      fill="#A38FD8"
    />
    <path d="M24 2.89044H0V5.78044H24V2.89044Z" fill="#7C64BD" />
    <path
      d="M3.17937 9.84392C3.97743 9.84392 4.62438 9.19697 4.62438 8.39892C4.62438 7.60087 3.97743 6.95392 3.17937 6.95392C2.38132 6.95392 1.73438 7.60087 1.73438 8.39892C1.73438 9.19697 2.38132 9.84392 3.17937 9.84392Z"
      fill="white"
    />
  </svg>
);
