import {
    Card,
    CardContent,
    Typography,
    IconButton,
    Collapse,
    CardActions,
    Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useState } from "react";
import { CaretDown } from "../../../assets/svg/CaretDown";
import { useTranslation } from "react-i18next";

const FeatureCard = ({ feature, selected }) => {
    const { t } = useTranslation();
    const [plan, setplans] = useState([]);
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = (e) => {
        setExpanded(!expanded);
        e.preventDefault();
    };

    const checkIconStyle = {
        marginRight: 0.5,
        color: "#4CAF50",
        fontSize: "16px",
        borderRadius: "100%",
    };

    return (
        <li
            key={feature.title}
            style={{
                fontSize: "14px",
                display: "flex",
                alignItems: "center",
            }}
        >
            <Card
                sx={{
                    backgroundColor: "transparent",
                    boxShadow: "unset",
                    width: "100%",
                }}
            >
                <Box
                    onClick={handleExpandClick}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                    }}
                >
                    <CardContent
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            padding: 0,
                        }}
                    >
                        <CheckCircleOutlineIcon
                            sx={{
                                ...checkIconStyle,
                                color: selected
                                    ? "white"
                                    : checkIconStyle.color,
                                backgroundColor: selected
                                    ? "rgba(255, 255, 255, 0.5)"
                                    : "rgba(76, 175, 80, 0.1)",
                            }}
                        />
                        <Typography
                            variant="body2"
                            sx={{
                                color: selected ? "#fff" : "#202020",
                            }}
                        >
                            {t(feature.title)}
                        </Typography>
                    </CardContent>
                    <CardActions sx={{ padding: 0 }} disableSpacing>
                        <IconButton
                            aria-expanded={expanded}
                            aria-label="show more"
                            sx={{ padding: 0 }}
                        >
                            <CaretDown fill={selected ? "#fff" : "#202020"} />
                        </IconButton>
                    </CardActions>
                </Box>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent sx={{ paddingBottom: "0!important" }}>
                        <Typography sx={{ color: selected ? "#fff" : "#202020", marginBottom: 0 }} paragraph>
                            {t(feature.description)}
                        </Typography>
                    </CardContent>
                </Collapse>
            </Card>
        </li>
    );
};

export default FeatureCard;
