import React from "react";
import Layout from "../Layout/Layout";
import PlanSection from "../../components/sections/planSection/Plan";
import { theme } from "../../theme/theme";
import authService from "../../services/authService";
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet-async';

const Plan = () => {
  const { t } = useTranslation();
  const isAuthenticated = authService.isAuthenticated();

  return (<><Helmet>
    <title>{t('title_pricing')}</title></Helmet><Layout backgroundColor={theme.palette.background.lightBlue}>
      <PlanSection isPricing={isAuthenticated} />
    </Layout></>

  );
};

export default Plan;
