import React from "react";
import { Box, Container, Grid, Typography, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import revolutionize from "../../../assets/mission-statements/1.svg";
import power from "../../../assets/mission-statements/2.svg";
import unleash from "../../../assets/mission-statements/3.svg";
import renting from "../../../assets/mission-statements/4.svg";
import personalize from "../../../assets/mission-statements/5.svg";
import equip from "../../../assets/mission-statements/6.svg";
import boost from "../../../assets/mission-statements/7.svg";
import driven from "../../../assets/mission-statements/8.svg";
import { useTranslation } from "react-i18next";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  display: "flex",
  gap: "24px",
  flexDirection: "column",
  justifyContent: "flex-start",
  backgroundColor: "#fff",
  border: "1px solid #C1D0FF",
  borderRadius: "4px",
  boxShadow: "none",
  color: "#202020",
}));

const MissionStatements = () => {
  const { t } = useTranslation();

  const missionStatements = [
    {
      title: t("Revolutionize Swiss Real Estate"),
      description: t(
        "We're here to shake up the renting and selling game in Switzerland — making the process simpler, faster, and smarter for everyone."
      ),
      logo: revolutionize,
    },
    {
      title: t("Power Up with Technology"),
      description: t(
        "We use cutting-edge AI and personalization algorithms to bring landlords, sellers, tenants, and buyers together seamlessly. Because we know that technology is the ultimate game-changer."
      ),
      logo: power,
    },
    {
      title: t("Unleash the Power of Data"),
      description: t(
        "Our platform taps into rich data sources to deliver spot-on property matches, deep market insights, and smart recommendations — helping you make the best moves, every time."
      ),
      logo: unleash,
    },
    {
      title: t("Make Renting and Selling Affordable for All"),
      description: t(
        "We're cutting costs and eliminating hassles with free property ads, automated management, and streamlined processes that save you money and effort."
      ),
      logo: renting,
    },
    {
      title: t("Personalize Every Experience"),
      description: t(
        "Our algorithms are like your personal real estate concierge, curating the perfect matches and experiences tailored to your unique needs and desires."
      ),
      logo: personalize,
    },
    {
      title: t("Equip You with the Coolest Tools"),
      description: t(
        "From instant AI property matching to hands-free viewings and real-time updates using WhatsApp or SMS, we're giving you the ultimate toolkit to navigate the market with ease and confidence."
      ),
      logo: equip,
    },
    {
      title: t("Boost Market Transparency"),
      description: t(
        "By delivering real-time insights and crystal-clear data, we're making the Swiss property market more open, fair, and competitive for everyone."
      ),
      logo: boost,
    },
    {
      title: t("Driven by Bold Innovation"),
      description: t(
        "We launched this venture with one mission in mind: to reinvent the real estate experience in Switzerland through smart technology, deep data, and a relentless drive to make renting and selling smoother, cheaper, and way more exciting."
      ),
      logo: driven,
    },
  ];

  return (
    <Box sx={{ pt: 6, pb: 10, backgroundColor: "#FFFFFF" }}>
      <Container maxWidth="xl">
        <Typography
          variant="h5"
          component="h2"
          align="center"
          fontWeight="700"
          fontSize={"35px"}
          color="#202020"
          gutterBottom
        >
          {t("Mission Statements")}
        </Typography>

        <Box
          sx={{
            display: "grid",
            gap: 3,
            mt: 4,
            gridTemplateColumns: {
              xs: "1fr",
              sm: "repeat(2, 1fr)",
              md: "repeat(3, 1fr)",
              lg: "repeat(4, 1fr)",
            },
          }}
        >
          {missionStatements.map((statement, index) => (
            <StyledPaper key={index}>
              <Box
                sx={{
                  width: "32px",
                  height: "32px",
                }}
              >
                <Box component="img" src={statement.logo} alt="HomeAI" />
              </Box>
              <Typography
                variant="h6"
                component="h3"
                gutterBottom
                fontWeight="700"
                fontSize="20px"
                color="#202020"
              >
                {statement.title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {statement.description}
              </Typography>
            </StyledPaper>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default MissionStatements;
