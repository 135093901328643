export const Pencil = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
  >
    <path
      d="M16.4827 5.15936L13.3412 2.0171C13.2367 1.9126 13.1127 1.82971 12.9762 1.77316C12.8397 1.71661 12.6933 1.6875 12.5456 1.6875C12.3978 1.6875 12.2515 1.71661 12.115 1.77316C11.9785 1.82971 11.8545 1.9126 11.75 2.0171L3.07977 10.688C2.97485 10.7921 2.89167 10.916 2.83506 11.0525C2.77844 11.1891 2.74953 11.3355 2.75001 11.4833V14.6255C2.75001 14.9239 2.86853 15.21 3.07951 15.421C3.29049 15.632 3.57664 15.7505 3.87501 15.7505H7.01727C7.16506 15.751 7.31147 15.7221 7.44799 15.6655C7.58451 15.6089 7.70842 15.5257 7.81251 15.4208L16.4827 6.75053C16.5872 6.64606 16.6701 6.52203 16.7267 6.38553C16.7832 6.24902 16.8123 6.10271 16.8123 5.95495C16.8123 5.80719 16.7832 5.66088 16.7267 5.52437C16.6701 5.38786 16.5872 5.26383 16.4827 5.15936ZM14 7.6421L10.8577 4.50053L12.5452 2.81303L15.6875 5.9546L14 7.6421Z"
      fill="#1C2024"
    />
  </svg>
);

export const PencilOutlined = () => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="PencilSimple">
      <path
        id="Vector"
        d="M16.4827 5.15936L13.3412 2.0171C13.2367 1.9126 13.1127 1.82971 12.9762 1.77316C12.8397 1.71661 12.6933 1.6875 12.5456 1.6875C12.3978 1.6875 12.2515 1.71661 12.115 1.77316C11.9785 1.82971 11.8545 1.9126 11.75 2.0171L3.07977 10.688C2.97485 10.7921 2.89167 10.916 2.83506 11.0525C2.77844 11.1891 2.74953 11.3355 2.75001 11.4833V14.6255C2.75001 14.9239 2.86853 15.21 3.07951 15.421C3.29049 15.632 3.57664 15.7505 3.87501 15.7505H7.01727C7.16506 15.751 7.31147 15.7221 7.44799 15.6655C7.58451 15.6089 7.70842 15.5257 7.81251 15.4208L16.4827 6.75053C16.5872 6.64606 16.6701 6.52203 16.7267 6.38553C16.7832 6.24902 16.8123 6.10271 16.8123 5.95495C16.8123 5.80719 16.7832 5.66088 16.7267 5.52437C16.6701 5.38786 16.5872 5.26383 16.4827 5.15936ZM7.01727 14.6255H3.87501V11.4833L10.0625 5.29577L13.2048 8.43803L7.01727 14.6255ZM14 7.6421L10.8577 4.50053L12.5452 2.81303L15.6875 5.9546L14 7.6421Z"
        fill="#002BB7"
        fill-opacity="0.772549"
      />
    </g>
  </svg>
);
