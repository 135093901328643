import React from "react";
import { Box, Paper, Typography } from "@mui/material";

import Location from "../../pages/HomeDetails/subComponents/Location";
import { theme } from "../../theme/theme";
import ProgressBar from "../Progress/ProgressBar";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import PhotoSizeSelectSmallOutlinedIcon from "@mui/icons-material/PhotoSizeSelectSmallOutlined";
import KingBedOutlinedIcon from "@mui/icons-material/KingBedOutlined";
import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined";

import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { SuitCase } from "../../assets/svg/SuitCase";
import { Coin } from "../../assets/svg/Coin";
import { FlowerLotus } from "../../assets/svg/FlowerLotus";
import { Bank } from "../../assets/svg/Bank";
import { CalendarBlank } from "../../assets/svg/CalendarBlank";
import { useTranslation } from "react-i18next";
import { numberFormatter } from "../../utils";

export const ClientOverview = ({ noPadding, data }) => {
  const { t } = useTranslation();

  const paperHeadings = {
    fontSize: "16px",
    color: "black",
    fontWeight: 500,
  };
  const outerBox = {
    display: "flex",
    flexDirection: "column",
    p: 2,
    mb: 3,
    boxShadow: "none",
    backgroundColor: theme.palette.background.skyBlue,
    border: `1.5px solid ${theme.palette.border.blue}`,
    rowGap: 1,
    borderRadius: "10px",
  };
  const innerBox = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    gap: 1,
  };
  const mainText = {
    ml: 1,
    fontSize: "12px",
    fontWeight: 550,
    color: "black",
  };
  const subText = {
    fontSize: "12px",
    ml: 1,
  };
  const subPaper = {
    p: 1,
    display: "flex",
    alignItems: "center",
    boxShadow: "none",
    border: `1.5px solid ${theme.palette.border.blue}`,
    width: "150px",
    flexGrow: 1,
  };
  const iconStyle = {
    p: 1,
    height: "26px",
    width: "26px",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <Box p={!noPadding ? 2 : 0}>
      {/* Client Overview Section */}
      <Box sx={outerBox}>
        <Typography sx={paperHeadings} gutterBottom>
          {t("Client Overview")}
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: 1,
            flexWrap: "wrap",
          }}
        >
          <Paper sx={subPaper}>
            <Box
              sx={{
                ...iconStyle,
                backgroundColor: theme.palette.background.skyBlue,
              }}
            >
              <SuitCase />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography sx={mainText}>Horgen</Typography>
              <Typography sx={subText}>{t("Location")}</Typography>
            </Box>
          </Paper>
          <Paper variant="outlined" sx={subPaper}>
            <Box
              sx={{
                ...iconStyle,
                backgroundColor: "#daf1db",
              }}
            >
              <Coin />
            </Box>
            <Box>
              <Typography sx={mainText}>4,000-5,500 CHF</Typography>
              <Typography sx={subText}>{t("Budget")}</Typography>
            </Box>
          </Paper>
          <Paper sx={subPaper}>
            <Box sx={{ backgroundColor: "#fffab8", ...iconStyle }}>
              <FlowerLotus />
            </Box>
            <Box>
              <Typography sx={mainText}>
                {t("Lake proximity, Quiet area")}
              </Typography>
              <Typography sx={subText}>{t("Lifestyle")}</Typography>
            </Box>
          </Paper>
        </Box>
        {/* Dummy map */}
        <Box>
          <Paper
            variant="outlined"
            sx={{
              boxShadow: "none",
              border: `1.5px solid ${theme.palette.border.blue}`,
              paddingBottom: 0,
            }}
          >
            <Location latitude={data?.latitude} longitude={data?.longitude} />
          </Paper>
        </Box>
      </Box>

      {/* Budget and Lease Terms */}
      <Box sx={outerBox}>
        <Typography sx={paperHeadings} gutterBottom>
          {t("Budget and Lease Terms")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            gap: 1,
          }}
        >
          <Paper
            variant="outlined"
            sx={{
              ...subPaper,
              flexDirection: "column",
              justifyContent: "space-between",
              gap: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={mainText}>{t("Month Budget")}</Typography>
              <Typography sx={subText}>
                F{data ? numberFormatter(data.price) : "4,500"}
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
              }}
            >
              <ProgressBar value={70} />
            </Box>
          </Paper>
          <Paper variant="outlined" sx={subPaper}>
            <Box></Box>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Box
                sx={{
                  ...iconStyle,
                  backgroundColor: "#daf1db",
                }}
              >
                <Bank />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography sx={mainText}>{t("Standard")}</Typography>
                <Typography sx={subText}>{t("Deposit")}</Typography>
              </Box>
            </Box>
          </Paper>
          <Paper variant="outlined" sx={subPaper}>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Box
                sx={{
                  ...iconStyle,
                  backgroundColor: "#fffab8",
                }}
              >
                <CalendarBlank />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography sx={mainText}>{t("Long-term")}</Typography>
                <Typography sx={subText}>{t("Lease duration")}</Typography>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>

      {/* Apartment Requirements */}
      <Box sx={outerBox}>
        <Typography sx={paperHeadings} gutterBottom>
          {t("Apartment Requirements")}
        </Typography>
        <Box sx={innerBox}>
          <Paper
            sx={{
              ...subPaper,
              flexDirection: "column",
              alignItems: "left",
              gap: 0.5,
            }}
          >
            <Typography
              sx={{
                display: "flex",
                alignItems: "center", // Vertically centers the icon and text
                color: theme.palette.text.secondary,
                fontWeight: 200,
                ...mainText,
              }}
            >
              <PhotoSizeSelectSmallOutlinedIcon
                sx={{ fontSize: 18, marginRight: 0.5 }}
              />
              {t("Room Size")}
            </Typography>
            <Typography sx={{ ...subText, fontWeight: 600, color: "black" }}>
              {data ? data.bathrooms + data.bedrooms : 120} m²
            </Typography>
          </Paper>

          <Paper
            sx={{
              ...subPaper,
              flexDirection: "column",
              alignItems: "left",
              gap: 0.5,
            }}
          >
            <Typography
              sx={{
                display: "flex",
                alignItems: "center", // Vertically centers the icon and text
                color: theme.palette.text.secondary,
                fontWeight: 200,
                ...mainText,
              }}
            >
              {" "}
              <KingBedOutlinedIcon
                sx={{ fontSize: 18, marginRight: 0.5 }}
              />{" "}
              {t("Furnishing")}
            </Typography>
            <Typography sx={{ ...subText, fontWeight: 600, color: "black" }}>
              {t("No")}
            </Typography>
          </Paper>

          <Paper
            sx={{
              ...subPaper,
              flexDirection: "column",
              alignItems: "left",
              gap: 0.5,
            }}
          >
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                color: theme.palette.text.secondary,
                fontWeight: 200,
                ...mainText,
              }}
            >
              <ApartmentOutlinedIcon sx={{ fontSize: 18, marginRight: 0.5 }} />
              {t("Amenities")}
            </Typography>
            <Typography sx={{ ...subText, fontWeight: 600, color: "black" }}>
              Elevator, gym
            </Typography>
            {/* <PetsOutlinedIcon
                sx={{
                  fontSize: 18,
                  fontWeight: 400,
                }}
              />
              <MeetingRoomOutlinedIcon
                sx={{
                  fontSize: 18,
                  fontWeight: 400,
                }}
              />
              <ParkOutlinedIcon
                sx={{
                  fontSize: 18,
                  fontWeight: 400,
                }}
              /> */}
          </Paper>
        </Box>
      </Box>

      {/* Lifestyle and Neighborhood Preferences */}
      <Box sx={outerBox}>
        <Typography sx={paperHeadings} gutterBottom>
          {t("Lifestyle and Neighborhood Preferences")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: 0.5,
          }}
        >
          <ApartmentOutlinedIcon
            sx={{
              fontSize: 18,
              fontWeight: 400,
            }}
          />
          <StorefrontOutlinedIcon
            sx={{
              fontSize: 18,
              fontWeight: 400,
            }}
          />
          <PermIdentityIcon
            sx={{
              fontSize: 18,
              fontWeight: 400,
            }}
          />
        </Box>
      </Box>

      {/* Move-in Timing */}
      <Box sx={outerBox}>
        <Typography sx={paperHeadings} gutterBottom>
          {t("Move-in Timing")}
        </Typography>
        <Box>
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              color: theme.palette.text.secondary,
              fontWeight: 200,
              ...mainText,
            }}
          >
            <CalendarTodayOutlinedIcon
              sx={{ fontSize: 18, marginRight: 0.5, ml: -1 }}
            />{" "}
            Beginning January 25
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
