import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import authService from "../../services/authService";

const initialState = {
  subscription: {
    data: null,
    isLoading: false,
    error: null,
  },
  createSubscription: {
    data: null,
    isLoading: false,
    error: null,
  },
  updateSubscription: {
    data: null,
    isLoading: false,
    error: null,
  },
  cancelSubscription: {
    data: null,
    isLoading: false,
    error: null,
  },
  plans: {
    data: null,
    isLoading: false,
    error: null,
  },
  billingHistory: {
    data: null,
    isLoading: false,
    error: null,
  },
};

const API_URL = process.env.REACT_APP_BASE_URL;

export const getPlans = createAsyncThunk(
  "subscriptions/get-plans",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(`${API_URL}/subscriptions/plans`, {
        headers: {
          // Authorization: `Bearer ${user.access_token}`,
        },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getSubscription = createAsyncThunk(
  "subscriptions/get-subscription",
  async (_, thunkAPI) => {
    try {
      const user = authService.getCurrentUser();
      const response = await axios.get(`${API_URL}/subscriptions/`, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const createSubscription = createAsyncThunk(
  "subscriptions/create-subscription",
  async (body, thunkAPI) => {
    try {
      const user = authService.getCurrentUser();
      const response = await axios.post(`${API_URL}/subscriptions/`, body, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      });
      if (response.data && response.data.checkout_url) {
        window.open(response.data.checkout_url, "_blank");
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateSubscription = createAsyncThunk(
  "subscriptions/update-subscription",
  async ({ subscriptionId, body }, thunkAPI) => {
    try {
      const user = authService.getCurrentUser();
      const response = await axios.put(`${API_URL}/subscriptions/`, body, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      });
      if (response.data && response.data.checkout_url) {
        window.open(response.data.checkout_url);
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const cancelSubscription = createAsyncThunk(
  "subscriptions/cancel-subscription",
  async (subscriptionId, thunkAPI) => {
    try {
      const user = authService.getCurrentUser();
      const response = await axios.delete(
        `${API_URL}/subscriptions/cancel/${subscriptionId}`,
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getBillingHistory = createAsyncThunk(
  "subscriptions/get-billing-history",
  async (_, thunkAPI) => {
    try {
      const user = authService.getCurrentUser();
      const response = await axios.get(
        `${API_URL}/subscriptions/billing/history`,
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const subscriptionsSlice = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSubscription.pending, (state) => {
        state.subscription.isLoading = true;
        state.subscription.error = null;
      })
      .addCase(getSubscription.fulfilled, (state, action) => {
        state.subscription.isLoading = false;
        state.subscription.data = action.payload;
      })
      .addCase(getSubscription.rejected, (state, action) => {
        state.subscription.isLoading = false;
        state.subscription.error = action.payload;
      })

      .addCase(createSubscription.pending, (state) => {
        state.createSubscription.isLoading = true;
        state.createSubscription.error = null;
      })
      .addCase(createSubscription.fulfilled, (state, action) => {
        state.createSubscription.isLoading = false;
        state.createSubscription.data = action.payload;
      })
      .addCase(createSubscription.rejected, (state, action) => {
        state.createSubscription.isLoading = false;
        state.createSubscription.error = action.payload;
      })

      .addCase(updateSubscription.pending, (state) => {
        state.updateSubscription.isLoading = true;
        state.updateSubscription.error = null;
      })
      .addCase(updateSubscription.fulfilled, (state, action) => {
        state.updateSubscription.isLoading = false;
        state.updateSubscription.data = action.payload;
      })
      .addCase(updateSubscription.rejected, (state, action) => {
        state.updateSubscription.isLoading = false;
        state.updateSubscription.error = action.payload;
      })

      .addCase(cancelSubscription.pending, (state) => {
        state.cancelSubscription.isLoading = true;
        state.cancelSubscription.error = null;
      })
      .addCase(cancelSubscription.fulfilled, (state, action) => {
        state.cancelSubscription.isLoading = false;
        state.cancelSubscription.data = action.payload;
      })
      .addCase(cancelSubscription.rejected, (state, action) => {
        state.cancelSubscription.isLoading = false;
        state.cancelSubscription.error = action.payload;
      })

      .addCase(getPlans.pending, (state) => {
        state.plans.isLoading = true;
        state.plans.error = null;
      })
      .addCase(getPlans.fulfilled, (state, action) => {
        state.plans.isLoading = false;
        state.plans.data = action.payload;
      })
      .addCase(getPlans.rejected, (state, action) => {
        state.plans.isLoading = false;
        state.plans.error = action.payload;
      })

      .addCase(getBillingHistory.pending, (state) => {
        state.billingHistory.isLoading = true;
        state.billingHistory.error = null;
      })
      .addCase(getBillingHistory.fulfilled, (state, action) => {
        state.billingHistory.isLoading = false;
        state.billingHistory.data = action.payload;
      })
      .addCase(getBillingHistory.rejected, (state, action) => {
        state.billingHistory.isLoading = false;
        state.billingHistory.error = action.payload;
      });
  },
});

export const selectSubscriptions = (state) => state.subscriptions;

export default subscriptionsSlice.reducer;
