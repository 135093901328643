import React, { useState, useRef, useCallback } from "react";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
  Container,
  IconButton,
} from "@mui/material";
import Join from "../joinSection/Join";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import { useNavigate } from "react-router-dom";
import Header from "../../header/Header";
import { SearchBar } from "../../searchBar/SearchBar";
import { motion, AnimatePresence } from "framer-motion";
import debounce from "lodash/debounce";
import { useTranslation } from "react-i18next";

export const Hero = ({ handleClickOpen }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);
  const [error, setError] = useState(null);

  const features = [
    t("Personalized Property Matches"),
    t("Instant Insights and Recommendations"),
    t("24/7 Availability, Anytime, Anywhere"),
  ];

  const handlePlayPause = useCallback(
    debounce(() => {
      if (videoRef.current) {
        if (isPlaying) {
          try {
            const pauseResult = videoRef.current.pause();
            if (pauseResult !== undefined) {
              pauseResult.catch((e) => {
                console.error("Error pausing video:", e);
                setError(t("Failed to pause video. Please try again."));
              });
            }
          } catch (e) {
            console.error("Error calling pause:", e);
            setError(t("Failed to pause video. Please try again."));
          }
        } else {
          try {
            const playResult = videoRef.current.play();
            if (playResult !== undefined) {
              playResult.catch((e) => {
                console.error("Error playing video:", e);
                setError(t("Failed to play video. Please try again."));
              });
            }
          } catch (e) {
            console.error("Error calling play:", e);
            setError(t("Failed to play video. Please try again."));
          }
        }
        setIsPlaying(!isPlaying);
      } else {
        console.error("Video element not found");
        setError(t("Video element not found. Please try again later."));
      }
    }, 300),
    [isPlaying]
  );

  return (
    <Box>
      <Box
        sx={{
          height: "100vh",
          maxWidth: "100%",
          display: "flex",
          flexDirection: "column",
          background:
            "linear-gradient(180deg, rgba(207,216,247,1) 20%, rgba(247,249,255,1) 100%)",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Box sx={{ height: "10%" }}>
          <Header handleClickOpen={handleClickOpen} />
        </Box>

        <AnimatePresence>
          {!isPlaying && (
            <motion.div
              initial={{ opacity: 1 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
              style={{ height: "90%", width: "100%" }}
            >
              <Container
                maxWidth="xl"
                sx={{ height: "100%", position: "relative" }}
              >
                <Box
                  sx={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "left",
                      flexDirection: "column",
                      width: { xs: "100%", md: "60%" },
                      zIndex: 3,
                    }}
                  >
                    <Typography
                      variant="h1"
                      sx={{
                        fontWeight: "bold",
                        fontSize: { xs: "40px", md: "80px" },
                        lineHeight: 1.2,
                        mb: 2,
                        color: "black",
                      }}
                    >
                      {t("Your AI-Powered")}
                      <br /> {t("Real Estate Agent")}
                    </Typography>

                    <Typography variant="h5" sx={{ mb: 4 }}>
                      {t(
                        "Find your perfect home faster and smarter with AI-driven recommendations tailored just for you."
                      )}
                    </Typography>

                    {!isMobile ? (
                      <Box sx={{ py: 4 }}>
                        <SearchBar />
                      </Box>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{
                          borderRadius: 1,
                          width: "200px",
                          textTransform: "none",
                          my: 2,
                        }}
                        onClick={() => navigate("/chat")}
                      >
                        {t("Start Your Search")}
                      </Button>
                    )}

                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        gap: 2,
                        flexDirection: { xs: "column", lg: "row" },
                      }}
                    >
                      {features.map((feature, index) => (
                        <Typography
                          key={index}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "12px",
                          }}
                        >
                          <CheckCircleOutlineIcon
                            sx={{ mr: 1, color: "#4CAF50", fontSize: "20px" }}
                          />
                          {feature}
                        </Typography>
                      ))}
                    </Box>
                  </Box>
                </Box>
              </Container>
            </motion.div>
          )}
        </AnimatePresence>
        <Box
          sx={{
            position: "absolute",
            right: 0,
            top: 0,
            width: isPlaying ? "100%" : "40%",
            height: "100%",
            display: { xs: "none", md: "flex" },
            justifyContent: "flex-end",
            alignItems: "center",
            transition: "all 0.5s ease-in-out",
          }}
        >
          <motion.div
            animate={{
              width: isPlaying ? "100%" : "100%",
              height: isPlaying ? "100%" : "70%",
              borderRadius: isPlaying ? "0%" : "3% 0 0 3%",
            }}
            transition={{ duration: 0.5 }}
            style={{
              position: "relative",
              overflow: "hidden",
              boxShadow: "1px -1px 34px -10px rgba(0,0,0,1)",
              zIndex: isPlaying ? 1000 : 0,
            }}
          >
            <video
              ref={videoRef}
              style={{
                width: "100%",
                height: isPlaying ? "auto" : "100%",
                objectFit: "cover",
              }}
            >
              <source
                src="https://fe-landmarklens-website.s3.eu-central-1.amazonaws.com/code.mp4"
                type="video/mp4"
              />
              {t("Your browser does not support the video tag.")}
            </video>
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: isPlaying ? "transparent" : "rgba(0,0,0,0.3)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {!isPlaying && (
                <IconButton
                  onClick={handlePlayPause}
                  sx={{
                    backgroundColor: "#000",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#000",
                    },
                    width: 64,
                    height: 64,
                  }}
                >
                  <PlayArrowIcon fontSize="large" />
                </IconButton>
              )}
            </Box>
            {isPlaying && (
              <IconButton
                onClick={handlePlayPause}
                sx={{
                  position: "absolute",
                  bottom: "20px",
                  right: "20px",
                  backgroundColor: "rgba(0,0,0,0.5)",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "rgba(0,0,0,0.7)",
                  },
                }}
              >
                <PauseIcon />
              </IconButton>
            )}
            {error && (
              <Typography
                sx={{
                  position: "absolute",
                  bottom: "20px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  color: "red",
                  backgroundColor: "rgba(255,255,255,0.7)",
                  padding: "5px",
                  borderRadius: "4px",
                }}
              >
                {error}
              </Typography>
            )}
          </motion.div>
        </Box>
      </Box>
      <Join />
    </Box>
  );
};
