import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { theme } from "../../theme/theme";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import ImageComponent from "../Image/Image";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Footer = () => {
  const { t } = useTranslation();

  const linkStyle = {
    color: theme.palette.primary.footer,
    fontSize: "16px",
    fontWeight: 500,
    cursor: "pointer",
    textDecoration: "none",
  };

  const outerBox = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    py: "100px",
  };

  const innerBox = {
    display: "flex",
    flexGrow: 1,
    gap: "20px",
    flexDirection: "column ",
  };

  const iconStyle = {
    mr: 1,
    fontSize: "16px",
    color: "#202020",
    cursor: "pointer",
  };

  return (<>
    <Container maxWidth="xl">
      <Box>
        <Box sx={outerBox}>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              rowGap: 2,
              columnGap: 2,
            }}
            container
          >
            <Grid xs={12} sm={12} md={4} lg={2.3}>
              <Box sx={innerBox}>
                <Link
                  to="/"
                  style={{
                    textDecoration: "none",
                    fontSize: "16px",
                    fontWeight: 500,
                    color: theme.palette.primary.footer,
                  }}
                >
                  <ImageComponent name="logo" height={30} alt="HOME AI Logo" />
                </Link>
              </Box>
            </Grid>

            <Grid xs={6} sm={6} md={4} lg={1.3}>
              <Box sx={innerBox}>
                <Link to="/tenants" style={linkStyle}>
                  {t("For Tenants")}
                </Link>
                <Link to="/owners" duration={500} style={linkStyle}>
                  {t("For Owners")}
                </Link>
              </Box>
            </Grid>
            <Grid xs={6} sm={6} md={4} lg={1.3}>
              <Box sx={innerBox}>
                <Link to="/features" style={linkStyle}>
                  {t("Features")}
                </Link>
                <Link to="/plan" style={linkStyle}>
                  {t("Pricing")}
                </Link>
              </Box>
            </Grid>
            <Grid xs={12} sm={6} md={4} lg={1.3}>
              <Box sx={innerBox}>
                <Link to="/blog-list" style={linkStyle}>
                  {t("Blog")}
                </Link>
                {/* <Link to="/about-us" style={linkStyle}>
                  {t("About Us")}
                </Link> */}
              </Box>
            </Grid>
            <Grid xs={12} sm={6} md={4} lg={1.3}>
              <Box sx={innerBox}>
                <Link to="/faq" style={linkStyle}>
                  {t("FAQ")}
                </Link>
                <Link to="/contact-us" style={linkStyle}>
                  {t("Contact Us")}
                </Link>
              </Box>
            </Grid>

            <Grid xs={12} sm={6} md={4} lg={2}>
              <Box
                sx={{
                  ...innerBox,
                  gap: "5px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Typography
                  sx={{
                    ...linkStyle,
                    color: "black",
                    fontWeight: 500,
                    cursor: "auto",
                  }}
                >
                  {t("Have questions?")}
                </Typography>

                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <EmailIcon sx={{ ...iconStyle, cursor: "auto" }} />
                  <Typography
                    sx={{ ...linkStyle, fontSize: "14px", cursor: "auto" }}
                  >
                    contact@homeai.ch
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <PhoneIcon sx={{ ...iconStyle, cursor: "auto" }} />
                  <Typography
                    sx={{ ...linkStyle, fontSize: "14px", cursor: "auto" }}
                  >
                    0793054286
                  </Typography>
                </Box>

                {/* <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mt: 2,
                  }}
                >
                  <Typography
                    sx={{ ...linkStyle, mr: 1, fontSize: "14px", cursor: "auto" }}
                  >
                    {t("Follow Us:")}
                  </Typography>
                  <FacebookIcon sx={iconStyle} />
                  <TwitterIcon sx={iconStyle} />
                  <LinkedInIcon sx={iconStyle} />
                  <InstagramIcon sx={iconStyle} />
                </Box> */}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        // backgroundColor: theme.palette.background.lightBlue,
        borderTop: "1px solid #ddd",
        height: "50px",
        gap: 2,
      }}
    >
      <Container maxWidth="xl">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >

          <Box
            sx={{
              [theme.breakpoints.down("sm")]: {
                display: "none",
              },
            }}
          >
            <Typography
              noWrap={true}
              sx={{
                fontSize: "12px",
              }}
            >
              {t("© Copyright 2024, All Rights Reserved")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              color: theme.palette.primary,
            }}
          >
            <Link
              to="/privacy-policy"
              style={{ ...linkStyle, marginRight: "15px", fontSize: "10px" }}
            >
              {t("Privacy Policy")}
            </Link>
            <Link
              to="/terms-of-service"
              style={{ ...linkStyle, fontSize: "10px" }}
            >
              {t("Terms of Service")}
            </Link>
          </Box>

        </Box>
      </Container>
    </Box></>
  );
};

export default Footer;
