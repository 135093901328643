export const UniteSquare = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="UniteSquare">
      <path
        id="Vector"
        d="M17.5 11.875V7.5C17.5 7.33424 17.4342 7.17527 17.3169 7.05806C17.1997 6.94085 17.0408 6.875 16.875 6.875H13.125V3.125C13.125 2.95924 13.0592 2.80027 12.9419 2.68306C12.8247 2.56585 12.6658 2.5 12.5 2.5H3.125C2.95924 2.5 2.80027 2.56585 2.68306 2.68306C2.56585 2.80027 2.5 2.95924 2.5 3.125V8.125V12.5C2.5 12.6658 2.56585 12.8247 2.68306 12.9419C2.80027 13.0592 2.95924 13.125 3.125 13.125H6.875V16.875C6.875 17.0408 6.94085 17.1997 7.05806 17.3169C7.17527 17.4342 7.33424 17.5 7.5 17.5H16.875C17.0408 17.5 17.1997 17.4342 17.3169 17.3169C17.4342 17.1997 17.5 17.0408 17.5 16.875V11.875ZM12.1336 16.25L3.75 7.86641V4.63359L15.3664 16.25H12.1336ZM4.63359 3.75H7.86641L16.25 12.1336V15.3664L4.63359 3.75ZM16.25 10.3664L14.0086 8.125H16.25V10.3664ZM11.875 5.99141L9.63359 3.75H11.875V5.99141ZM3.75 9.63359L5.99141 11.875H3.75V9.63359ZM8.125 14.0086L10.3664 16.25H8.125V14.0086Z"
        fill="#3358D4"
      />
    </g>
  </svg>
);
