import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import authService from "../../services/authService";
import { jsonData } from "../../db";

const initialState = {
  properties: {
    data: [],
    isLoading: false,
    error: null,
  },
  createProperty: {
    data: null,
    isLoading: false,
    error: null,
  },
  currentProperty: {
    data: null,
    isLoading: false,
    error: null,
  },
  updateProperty: {
    data: null,
    isLoading: false,
    error: null,
  },
  deleteProperty: {
    data: null,
    isLoading: false,
    error: null,
  },
  propertyImages: {
    data: null,
    isLoading: false,
    error: null,
  },
  addPropertyImage: {
    data: null,
    isLoading: false,
    error: null,
  },
  propertyDashboard: {
    data: null,
    isLoading: false,
    error: null,
  },
};

const API_URL = process.env.REACT_APP_BASE_URL;

// const addPropertiesImage = async (propertyId, dispatch) => {
//   for (const [idx, { image }] of jsonData.blogPosts.entries()) {
//     try {
//       const response = await axios.get(image, {
//         responseType: "arraybuffer", // Important: set responseType to arraybuffer
//       });
//       const formData = new FormData();
//       // Create a Blob from the array buffer
//       const blob = new Blob([response.data], { type: 'image/jpeg' });
//       formData.append("file", blob);

//       await dispatch(
//         addPropertyImage({
//           propertyId,
//           is_primary: idx === 0,
//           file: formData,
//         })
//       );
//     } catch (error) {
//       console.error("Error fetching the image:", error);
//     }
//   }
// };

export const fetchProperties = createAsyncThunk(
  "properties/fetchProperties",
  async (_, thunkAPI) => {
    try {
      const user = authService.getCurrentUser();
      const response = await axios.get(`${API_URL}/property/`, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      });
      // addPropertiesImage(response.data[0].id, thunkAPI.dispatch);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getPropertyDashboard = createAsyncThunk(
  "properties/get-property-dashboard",
  async (propertyId, thunkAPI) => {
    try {
      const user = authService.getCurrentUser();
      const response = await axios.get(
        `${API_URL}/property/${propertyId}/dashboard`,
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const createProperty = createAsyncThunk(
  "properties/create-property",
  async (body, thunkAPI) => {
    try {
      const user = authService.getCurrentUser();
      const response = await axios.post(`${API_URL}/property/`, body, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getPropertyImages = createAsyncThunk(
  "properties/get-property-images",
  async (property_id, thunkAPI) => {
    try {
      const user = authService.getCurrentUser();
      const response = await axios.get(
        `${API_URL}/property/${property_id}/images`,
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const addPropertyImage = createAsyncThunk(
  "properties/add-property-image",
  async ({ propertyId, is_primary = false, file }, thunkAPI) => {
    try {
      const user = authService.getCurrentUser();
      const response = await axios.post(
        `${API_URL}/property/images/?property_id=${propertyId}&is_primary=${is_primary}`,
        file,
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getProperty = createAsyncThunk(
  "properties/get-property",
  async (id, thunkAPI) => {
    try {
      const user = authService.getCurrentUser();
      const response = await axios.get(`${API_URL}/property/${id}`, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateProperty = createAsyncThunk(
  "properties/update-property",
  async ({ id, body }, thunkAPI) => {
    try {
      const user = authService.getCurrentUser();
      const response = await axios.put(`${API_URL}/property/${id}`, body, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteProperty = createAsyncThunk(
  "properties/delete-property",
  async (id, thunkAPI) => {
    try {
      const user = authService.getCurrentUser();
      const response = await axios.delete(`${API_URL}/property/${id}`, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const propertiesSlice = createSlice({
  name: "properties",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProperties.pending, (state) => {
        state.properties.isLoading = true;
        state.properties.error = null;
      })
      .addCase(fetchProperties.fulfilled, (state, action) => {
        state.properties.isLoading = false;
        state.properties.data = action.payload;
      })
      .addCase(fetchProperties.rejected, (state, action) => {
        state.properties.isLoading = false;
        state.properties.error = action.payload;
      })

      .addCase(createProperty.pending, (state) => {
        state.createProperty.isLoading = true;
        state.createProperty.error = null;
      })
      .addCase(createProperty.fulfilled, (state, action) => {
        state.createProperty.isLoading = false;
        state.createProperty.data = action.payload;
      })
      .addCase(createProperty.rejected, (state, action) => {
        state.createProperty.isLoading = false;
        state.createProperty.error = action.payload;
      })

      .addCase(getProperty.pending, (state) => {
        state.currentProperty.isLoading = true;
        state.currentProperty.error = null;
      })
      .addCase(getProperty.fulfilled, (state, action) => {
        state.currentProperty.isLoading = false;
        state.currentProperty.data = action.payload;
      })
      .addCase(getProperty.rejected, (state, action) => {
        state.currentProperty.isLoading = false;
        state.currentProperty.error = action.payload;
      })

      .addCase(updateProperty.pending, (state) => {
        state.updateProperty.isLoading = true;
        state.updateProperty.error = null;
      })
      .addCase(updateProperty.fulfilled, (state, action) => {
        state.updateProperty.isLoading = false;
        state.updateProperty.data = action.payload;
      })
      .addCase(updateProperty.rejected, (state, action) => {
        state.updateProperty.isLoading = false;
        state.updateProperty.error = action.payload;
      })

      .addCase(deleteProperty.pending, (state) => {
        state.deleteProperty.isLoading = true;
        state.deleteProperty.error = null;
      })
      .addCase(deleteProperty.fulfilled, (state, action) => {
        state.deleteProperty.isLoading = false;
        state.deleteProperty.data = action.payload;
      })
      .addCase(deleteProperty.rejected, (state, action) => {
        state.deleteProperty.isLoading = false;
        state.deleteProperty.error = action.payload;
      })

      .addCase(getPropertyImages.pending, (state) => {
        state.propertyImages.isLoading = true;
        state.propertyImages.error = null;
      })
      .addCase(getPropertyImages.fulfilled, (state, action) => {
        state.propertyImages.isLoading = false;
        state.propertyImages.data = action.payload;
      })
      .addCase(getPropertyImages.rejected, (state, action) => {
        state.propertyImages.isLoading = false;
        state.propertyImages.error = action.payload;
      })

      .addCase(addPropertyImage.pending, (state) => {
        state.addPropertyImage.isLoading = true;
        state.addPropertyImage.error = null;
      })
      .addCase(addPropertyImage.fulfilled, (state, action) => {
        state.addPropertyImage.isLoading = false;
        state.addPropertyImage.data = action.payload;
      })
      .addCase(addPropertyImage.rejected, (state, action) => {
        state.addPropertyImage.isLoading = false;
        state.addPropertyImage.error = action.payload;
      })
      .addCase(getPropertyDashboard.pending, (state) => {
        state.propertyDashboard = {
          ...state.propertyDashboard,
          isLoading: true,
          error: null,
        };
      })
      .addCase(getPropertyDashboard.fulfilled, (state, action) => {
        state.propertyDashboard = {
          ...state.propertyDashboard,
          isLoading: false,
          data: action.payload,
        };
      })
      .addCase(getPropertyDashboard.rejected, (state, action) => {
        state.propertyDashboard = {
          ...state.propertyDashboard,
          isLoading: false,
          error: action.payload,
        };
      });
  },
});

export const selectProperties = (state) => state.properties;

export default propertiesSlice.reducer;
