export const SearchIcon = () => (
  <svg
    width="28"
    height="40"
    viewBox="0 0 28 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="28" height="40" fill="white" fill-opacity="0.01" />
    <path
      d="M22.3539 27.6464L19.2245 24.5176C20.1315 23.4287 20.5838 22.032 20.4873 20.6181C20.3908 19.2042 19.7528 17.8819 18.7062 16.9264C17.6596 15.9708 16.2849 15.4556 14.8681 15.4878C13.4512 15.52 12.1014 16.0971 11.0993 17.0993C10.0971 18.1014 9.51995 19.4512 9.48775 20.8681C9.45555 22.2849 9.97082 23.6596 10.9264 24.7062C11.8819 25.7528 13.2042 26.3908 14.6181 26.4873C16.032 26.5838 17.4287 26.1315 18.5176 25.2245L21.6464 28.3539C21.6928 28.4003 21.748 28.4372 21.8087 28.4623C21.8694 28.4875 21.9344 28.5004 22.0001 28.5004C22.0658 28.5004 22.1309 28.4875 22.1916 28.4623C22.2523 28.4372 22.3074 28.4003 22.3539 28.3539C22.4003 28.3074 22.4372 28.2523 22.4623 28.1916C22.4875 28.1309 22.5004 28.0658 22.5004 28.0001C22.5004 27.9344 22.4875 27.8694 22.4623 27.8087C22.4372 27.748 22.4003 27.6928 22.3539 27.6464ZM10.5001 21.0001C10.5001 20.1101 10.7641 19.2401 11.2585 18.5001C11.753 17.7601 12.4558 17.1833 13.2781 16.8427C14.1003 16.5021 15.0051 16.413 15.878 16.5866C16.751 16.7602 17.5528 17.1888 18.1821 17.8182C18.8115 18.4475 19.24 19.2493 19.4137 20.1222C19.5873 20.9952 19.4982 21.9 19.1576 22.7222C18.817 23.5445 18.2402 24.2473 17.5002 24.7418C16.7602 25.2362 15.8902 25.5001 15.0001 25.5001C13.8071 25.4988 12.6633 25.0243 11.8196 24.1807C10.976 23.337 10.5015 22.1932 10.5001 21.0001Z"
      fill="#00051D"
      fill-opacity="0.454902"
    />
  </svg>
);
