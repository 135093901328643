export const Note = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.71143 10.5834L1.4166 7.28857L8.30579 0.399374C8.57204 0.133125 8.88821 0 9.25431 0C9.6204 0 9.93657 0.133125 10.2028 0.399374L11.6006 1.79718C11.8669 2.06343 12 2.3796 12 2.74569C12 3.11179 11.8669 3.42796 11.6006 3.69421L4.71143 10.5834ZM0.797572 11.9812C0.571261 12.0345 0.371574 11.9746 0.198512 11.8015C0.0254502 11.6284 -0.0344557 11.4287 0.0187941 11.2024L0.637823 8.18716L3.79287 11.3422L0.797572 11.9812Z"
      fill="#3E63DD"
    />
  </svg>
);
