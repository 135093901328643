import React from "react";
import Layout from "../Layout/Layout";
import AboutUs1 from "../../components/sections/AboutUs1/AboutUs1";
import AboutUs2 from "../../components/sections/AboutUs2/AboutUs2";
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet-async';

const About = () => {
  const { t } = useTranslation();
  const heading = "About The Home AI";
  const text =
    "We offers best solutions with the help of in-depth analysis of artificial intelligence to make life easier for our users.";
  return (<><Helmet>
    <title>{t('title_about')}</title></Helmet>
    <Layout heading={heading} text={text} isAbout>
      <AboutUs1 />
      <AboutUs2 />
    </Layout></>

  );
};

export default About;
