import React from 'react';
import { Card, CardContent, CardMedia, Typography, Box } from '@mui/material';
import { theme } from '../../theme/theme';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { createStringId } from './../../utils/createIdFromAddress';

const GalleryCard = ({ item, index }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const cardStyling = {
    width: '250px',
    flexGrow: 1,
    filter: index > 0 ? 'blur(4px)' : 'none',
    pointerEvents: index > 0 ? 'none' : 'auto',
    borderRadius: '8px',
    border: `1px solid #C1D0FF`,
    boxShadow: 'none',
    p: 0,
    transition: 'transform 0.3s ease-in-out',
    '& .MuiCardContent-root:last-child': {
      paddingBottom: 0
    },
    '&:hover': {
      transform: 'scale(1.02)',
      cursor: 'pointer',
      boxShadow: '1px 1px 9px rgba(0,0,0,0.4)'
    }
  };

  // const id = createStringId(item.address);
  const id = 10;

  const navigateHandler = () => {
    id && navigate(`/home-details/${id}`);
  };

  return (
    <Card onClick={navigateHandler} sx={cardStyling}>
      {/* Image */}
      <CardMedia
        component="img"
        height="150"
        image={item.images[0]}
        alt={`Property image of ${item?.address}`}
      />

      {/* Content */}
      <CardContent
        sx={{
          p: 0,
          pb: 0
        }}>
        <Box
          sx={{
            borderBottom: `1px solid #C1D0FF`,
            px: 1.5,
            py: 0.5
          }}>
          {/* Price per month */}
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 600
            }}
            variant="h6"
            gutterBottom>
            £{item.price || '5,500 CHF'}{' '}
            <span
              style={{
                fontSize: '12px',
                color: theme.palette.text.secondary,
                fontWeight: 400
              }}>
              {t('per month')}
            </span>
          </Typography>

          {/* Address */}
          <Typography
            sx={{
              fontSize: '12px',
              mt: -1,
              color: theme.palette.text.primary
            }}
            variant="body1"
            color="textSecondary"
            gutterBottom>
            {item?.address}
          </Typography>
        </Box>

        {/* Beds, Baths, Square Feet */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: '30px',
            px: 1.5,
            py: 0.5,
            gap: 2
          }}>
          <Typography fontSize="12px" variant="body1">
            {item.beds} {t('beds')}
          </Typography>
          <Typography fontSize="12px" variant="body1">
            {item.baths} {t('baths')}
          </Typography>
          <Typography fontSize="12px" variant="body1">
            {item.sqrFeet} {t('m2')}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default GalleryCard;
