export const Visa = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_664_41453)">
      <path
        d="M10.6565 15.1674H9.00834L10.0384 8.83699H11.6867L10.6565 15.1674ZM7.62184 8.83699L6.05059 13.1911L5.86466 12.2535L5.86483 12.2538L5.31026 9.40697C5.31026 9.40697 5.24321 8.83699 4.52845 8.83699H1.93087L1.90039 8.94418C1.90039 8.94418 2.69473 9.10945 3.62438 9.66774L5.05626 15.1675H6.77348L9.39561 8.83699H7.62184ZM20.5852 15.1674H22.0985L20.7791 8.83682H19.4542C18.8424 8.83682 18.6934 9.30859 18.6934 9.30859L16.2353 15.1674H17.9534L18.297 14.2271H20.3921L20.5852 15.1674ZM18.7716 12.9281L19.6376 10.5591L20.1248 12.9281H18.7716ZM16.3642 10.3593L16.5994 8.99989C16.5994 8.99989 15.8736 8.72388 15.1171 8.72388C14.2992 8.72388 12.3569 9.08134 12.3569 10.8196C12.3569 12.455 14.6365 12.4753 14.6365 13.3343C14.6365 14.1934 12.5918 14.0394 11.917 13.4977L11.672 14.9191C11.672 14.9191 12.4079 15.2766 13.5323 15.2766C14.657 15.2766 16.3537 14.6942 16.3537 13.1093C16.3537 11.4634 14.0536 11.3101 14.0536 10.5945C14.0538 9.87873 15.6589 9.97068 16.3642 10.3593Z"
        fill="#2566AF"
      />
      <path
        d="M5.86483 12.2537L5.31026 9.40683C5.31026 9.40683 5.24321 8.83685 4.52845 8.83685H1.93087L1.90039 8.94404C1.90039 8.94404 3.14889 9.20278 4.34642 10.1722C5.49145 11.0988 5.86483 12.2537 5.86483 12.2537Z"
        fill="#E6A540"
      />
      <path
        d="M1.47368 4.5H22.5263C23.0764 4.5 23.5 4.93118 23.5 5.4359V18.5641C23.5 19.0688 23.0764 19.5 22.5263 19.5H1.47368C0.92361 19.5 0.5 19.0688 0.5 18.5641V5.4359C0.5 4.93118 0.923611 4.5 1.47368 4.5Z"
        stroke="#D9D9D9"
      />
    </g>
    <defs>
      <clipPath id="clip0_664_41453">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
