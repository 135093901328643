export const SignOut = () => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.1797 16.875C10.1797 17.0408 10.1138 17.1997 9.99663 17.3169C9.87942 17.4342 9.72045 17.5 9.55469 17.5H4.55469C4.38893 17.5 4.22996 17.4342 4.11275 17.3169C3.99554 17.1997 3.92969 17.0408 3.92969 16.875V3.125C3.92969 2.95924 3.99554 2.80027 4.11275 2.68306C4.22996 2.56585 4.38893 2.5 4.55469 2.5H9.55469C9.72045 2.5 9.87942 2.56585 9.99663 2.68306C10.1138 2.80027 10.1797 2.95924 10.1797 3.125C10.1797 3.29076 10.1138 3.44973 9.99663 3.56694C9.87942 3.68415 9.72045 3.75 9.55469 3.75H5.17969V16.25H9.55469C9.72045 16.25 9.87942 16.3158 9.99663 16.4331C10.1138 16.5503 10.1797 16.7092 10.1797 16.875ZM18.7469 9.55781L15.6219 6.43281C15.5046 6.31554 15.3455 6.24965 15.1797 6.24965C15.0138 6.24965 14.8548 6.31554 14.7375 6.43281C14.6202 6.55009 14.5543 6.70915 14.5543 6.875C14.5543 7.04085 14.6202 7.19991 14.7375 7.31719L16.7961 9.375H9.55469C9.38893 9.375 9.22996 9.44085 9.11275 9.55806C8.99554 9.67527 8.92969 9.83424 8.92969 10C8.92969 10.1658 8.99554 10.3247 9.11275 10.4419C9.22996 10.5592 9.38893 10.625 9.55469 10.625H16.7961L14.7375 12.6828C14.6202 12.8001 14.5543 12.9591 14.5543 13.125C14.5543 13.2909 14.6202 13.4499 14.7375 13.5672C14.8548 13.6845 15.0138 13.7503 15.1797 13.7503C15.3455 13.7503 15.5046 13.6845 15.6219 13.5672L18.7469 10.4422C18.805 10.3841 18.8511 10.3152 18.8825 10.2393C18.914 10.1635 18.9302 10.0821 18.9302 10C18.9302 9.91787 18.914 9.83654 18.8825 9.76066C18.8511 9.68479 18.805 9.61586 18.7469 9.55781Z"
      fill="#C40006"
      fill-opacity="0.827451"
    />
    <path
      d="M5.17969 3.6875H5.11719V3.75V16.25V16.3125H5.17969H9.55469C9.70387 16.3125 9.84695 16.3718 9.95244 16.4773C10.0579 16.5827 10.1172 16.7258 10.1172 16.875C10.1172 17.0242 10.0579 17.1673 9.95244 17.2727C9.84695 17.3782 9.70387 17.4375 9.55469 17.4375H4.55469C4.4055 17.4375 4.26243 17.3782 4.15694 17.2727C4.05145 17.1673 3.99219 17.0242 3.99219 16.875V3.125C3.99219 2.97582 4.05145 2.83274 4.15694 2.72725C4.26243 2.62176 4.4055 2.5625 4.55469 2.5625H9.55469C9.70387 2.5625 9.84695 2.62176 9.95244 2.72725C10.0579 2.83274 10.1172 2.97582 10.1172 3.125C10.1172 3.27418 10.0579 3.41726 9.95244 3.52275C9.84695 3.62824 9.70387 3.6875 9.55469 3.6875H5.17969ZM18.7027 9.60201L18.7027 9.60203C18.755 9.65427 18.7965 9.71631 18.8248 9.7846C18.8531 9.85288 18.8677 9.92608 18.8677 10C18.8677 10.0739 18.8531 10.1471 18.8248 10.2154C18.7965 10.2837 18.755 10.3457 18.7027 10.398L18.7027 10.398L15.5777 13.523C15.4721 13.6285 15.329 13.6878 15.1797 13.6878C15.0304 13.6878 14.8872 13.6285 14.7817 13.523C14.6761 13.4174 14.6168 13.2743 14.6168 13.125C14.6168 12.9757 14.6761 12.8326 14.7817 12.727C14.7817 12.727 14.7817 12.727 14.7817 12.727L16.8403 10.6692L16.947 10.5625H16.7961H9.55469C9.4055 10.5625 9.26243 10.5032 9.15694 10.3977C9.05145 10.2923 8.99219 10.1492 8.99219 10C8.99219 9.85082 9.05145 9.70774 9.15694 9.60225C9.26243 9.49676 9.4055 9.4375 9.55469 9.4375H16.7961H16.947L16.8403 9.3308L14.7817 7.27299C14.6761 7.16744 14.6168 7.02428 14.6168 6.875C14.6168 6.72572 14.6761 6.58256 14.7817 6.47701C14.8872 6.37145 15.0304 6.31215 15.1797 6.31215C15.329 6.31215 15.4721 6.37145 15.5777 6.47701L18.7027 9.60201Z"
      stroke="#C40006"
      stroke-opacity="0.827451"
      stroke-width="0.125"
    />
  </svg>
);
