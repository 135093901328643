import React, { useState } from "react";
import { Box, TextField } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useTranslation } from "react-i18next";

const MessageField = ({ onSendMessage, onChange, disabled }) => {
  const { t } = useTranslation();

  const [message, setMessage] = useState("");

  const handleChange = (e) => {
    setMessage(e.target.value);
    onChange(e);
  };

  const handleSend = () => {
    if (message.trim()) {
      onSendMessage();
      setMessage("");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Box
        sx={{
          flex: 1,
        }}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#ecf1fd",
          borderRadius: "5px",
          padding: "8px",
        }}
      >
        <TextField
          disabled={disabled}
          autoComplete="off"
          name="User"
          sx={{
            flex: 1,
            alignSelf: "flex-end",
            "& .MuiOutlinedInput-root": {
              borderRadius: "4px",
              backgroundColor: "#ffffff",
            },
            "& .MuiInputBase-input": {
              padding: "8px",
              height: "20px",
            },
            "&:hover fieldset": {
              outline: "none",
            },
          }}
          placeholder={t("Type Something....")}
          value={message}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#3e63dd",
            color: "white",
            cursor: "pointer",
            borderRadius: "6px",
            height: "100%",
            padding: "8px",
            ml: "4px",
          }}
        >
          <SendIcon disabled={disabled} fontSize="small" onClick={handleSend} />
        </Box>
      </Box>
    </Box>
  );
};

export default MessageField;
