export const ArrowUpRight = () => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5547 4V10.5C13.5547 10.6989 13.4756 10.8897 13.335 11.0303C13.1943 11.171 13.0036 11.25 12.8047 11.25C12.6057 11.25 12.415 11.171 12.2743 11.0303C12.1337 10.8897 12.0547 10.6989 12.0547 10.5V5.8125L5.33529 12.5306C5.19439 12.6715 5.00329 12.7507 4.80404 12.7507C4.60478 12.7507 4.41368 12.6715 4.27279 12.5306C4.13189 12.3897 4.05273 12.1986 4.05273 11.9994C4.05273 11.8001 4.13189 11.609 4.27279 11.4681L10.9922 4.75H6.30466C6.10575 4.75 5.91498 4.67098 5.77433 4.53033C5.63368 4.38968 5.55466 4.19891 5.55466 4C5.55466 3.80109 5.63368 3.61032 5.77433 3.46967C5.91498 3.32902 6.10575 3.25 6.30466 3.25H12.8047C13.0036 3.25 13.1943 3.32902 13.335 3.46967C13.4756 3.61032 13.5547 3.80109 13.5547 4Z"
      fill="#3E63DD"
    />
  </svg>
);
