import React from "react";
import { Modal, Typography, IconButton, Divider, Box } from "@mui/material";
import { styled } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import Plan from "../plan/plan";
import AIAvatar from "../../assets/svg/AIAvatar";
import { useTranslation } from "react-i18next";

// Styled Box for the modal container
const StyledModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%", // Adjust width as needed
  maxWidth: 828, // Maximum width for the modal
  maxHeight: "90vh", // Limit height to 90% of the viewport
  backgroundColor: theme.palette.background.paper,
  borderRadius: "8px",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column", // Flex layout to allow the content to grow/shrink
  outline: "none",
}));

// Modal Header (fixed at the top)
const ModalHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "10px 40px",
}));

// Content section (scrollable if content overflows)
const ModalContent = styled(Box)(({ theme }) => ({
  flexGrow: 1, // Allows the content to grow
  padding: "32px 40px",
  maxHeight: "calc(100vh - 200px)", // Ensure the content stays within the modal (header + some space)
}));

const UpgradeModal = ({
  currentPlan,
  translate,
  upgradePlan,
  open,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <StyledModalBox>
        {/* Modal Header */}
        <ModalHeader>
          <Typography
            sx={{ fontWeight: 700, fontSize: 18 }}
            id="modal-title"
            variant="h6"
          >
            {t("Upgrade Plan")}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </ModalHeader>

        <Divider />

        {/* Scrollable Content */}
        <ModalContent>
          <Box display="flex" gap={2} alignItems="center">
            <AIAvatar />
            <Typography
              variant="body3"
              sx={{ fontWeight: 500, color: "#202020", mb: 2 }}
            >
              {t("AI Agent")}
            </Typography>
          </Box>
          <Typography variant="body3" sx={{ mb: 4, ml: "44px" }}>
            {t(
              "You have reached your limit of chats. Upgrade your plan and enjoy more chats."
            )}
          </Typography>

          <Box display="flex" gap={2} mt={4}>
            <Plan
              wrapperStyles={{ width: "100%", height: "100%" }}
              name={currentPlan.name}
              price={currentPlan.price}
              keyFeatures={currentPlan.keyFeatures}
              translate={translate}
            />
            <Plan
              wrapperStyles={{ width: "100%" }}
              name={upgradePlan.name}
              price={upgradePlan.price}
              keyFeatures={upgradePlan.keyFeatures}
              isUpgrade={true}
              translate={translate}
            />
          </Box>
        </ModalContent>
      </StyledModalBox>
    </Modal>
  );
};

export default UpgradeModal;
