import { Box, Grid, Typography, Button, styled } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { VerticalLinearStepper } from '../../components/stepper/Stepper';
import { Insights } from '../../components/insights/Insights';
import MessageField from '../../components/messageField/MessageField';
import NewChat from '../../components/newChat/NewChat';
import Upload from '../../components/chatUpload';
import { getChat, insight } from '../../store/slices/chatSlice';
import { errorHandler } from '../../utils';
import { useDispatch } from 'react-redux';
import { AIAvatarOutlined } from '../../assets/svg/AIAvatar';
import LoggedInLayout from '../LoggedInLayout/LoggedInLayout';
import { PencilOutlined } from '../../assets/svg/Pencil';
import MessageItem from '../../components/messageItem/messageItem';
import ChatButtons from '../../components/chatButtons/ChatButtons';
import BudgetCard from '../../components/budgetCard/BudgetCard';
import ChatFilter from '../../components/chatFilter/ChatFilter';
import PropertySummary from '../../components/propertySummary/PropertySummary';
import { useLocation, useNavigate } from 'react-router-dom';
import { blogPosts } from '../../blogData';
import { useTranslation } from 'react-i18next';
import { getProperty, getPropertyImages } from '../../store/slices/propertiesSlice';

import { toast } from 'react-toastify';

const ButtonsBox = styled(Box)({
  width: 'calc(100% - 32px)',
  margin: '0 auto',
  height: 40,
  padding: '24px 0',
  display: 'flex',
  justifyContent: 'center',
  gap: 16,
  borderRadius: 8,
  border: '1px solid rgba(0, 0, 51, 0.06)'
});

const StyledButton = styled(Button)(({ bgColor }) => ({
  boxShadow: 'none',
  backgroundColor: bgColor === 'standard' ? 'rgba(0, 71, 241, 0.07)!important' : '#3E63DD',
  color: bgColor === 'standard' ? '#002BB7C5' : '#ffffff',
  borderRadius: 6,
  display: 'flex',
  alignItems: 'center',
  gap: 12
}));

const ChatOwner = () => {
  const { t } = useTranslation();
  const scrollRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const resetFilter = useRef();

  const [done, setDone] = useState(false);
  const [steps, setSteps] = useState(0);
  const [uploadedImages, setUploadedImages] = useState([]);

  const [isEdit, setIsEdit] = useState(false);
  const [editedDescription, setEditedDescription] = useState(
    `Here your property description based on your uploaded photos:

    The spacious property is located near the city center with a beautiful landscape and everything you need in the environment. Even though it's 5 minutes further from your work, it offers a larger kitchen, making it a good compromise.`
  );

  const moreDetailsProperty = `${editedDescription}
  This property has a very convenient location, quality renovations and furnishings that provide comfort that you will enjoy every day when you wake up in the morning and when you go to bed at night.`;

  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id') || '';

  const [conversation, setConversation] = useState([
    { sender: 'AI', text: 'Hello! How can I assist you today?' },
    { sender: 'User', text: 'I need help with my account.' }
  ]);

  const [prevVisitedList, setPrevVisitedList] = useState({
    Today: [
      {
        location: 'Zurich',
        rooms: 2,
        size: '2000-2500CHF',
        selected: true
      },
      { location: 'Zurich', rooms: 2, size: '2000-2500CHF' }
    ],
    'Previous 7 Days': [
      { location: 'Bern', rooms: 2, size: '2000-2500CHF' },
      { location: 'Geneva', rooms: 2, size: '2000-2500CHF' },
      { location: 'Chur', rooms: 2, size: '2000-2500CHF' }
    ]
  });

  const [isChatCompleted, setIsChatCompleted] = useState(false);
  const [message, setMessage] = useState({ text: '', sender: 'User' });

  const dispatch = useDispatch();
  const boxRef = useRef(null);

  useEffect(() => {
    handleScrollBottom();
    handleScrollToOptimalPricing();
    // getInsights();
  }, [conversation, done, uploadedImages, steps]);

  function handleEditDescription(text) {
    setEditedDescription(text);
  }

  const handleScrollBottom = () => {
    if (scrollRef.current?.scrollIntoView) {
      setTimeout(() => {
        scrollRef.current.scrollIntoView({
          block: 'start',
          behavior: 'smooth'
        });
      }, 0);
    }
  };
  const handleScrollToOptimalPricing = () => {
    const optimalPricingElement = document.getElementById('optimal_pricing');

    if (optimalPricingElement) {
      setTimeout(() => {
        optimalPricingElement.scrollIntoView({
          block: 'start',
          behavior: 'smooth'
        });
      }, 0);
    }
  };

  useEffect(() => {
    //  getQuestion();
  });

  const getQuestion = async (id) => {
    try {
      const data = await dispatch(getChat({ id }));
      if (data.payload.status === 200 && data.meta.requestStatus === 'fulfilled') {
        setConversation((prev) => [...prev, ...data.payload.data]);
      }
    } catch (error) {
      errorHandler(error);
    }
  };
  const getInsights = async () => {
    try {
      const data = await dispatch(insight());
      if (data.payload.status === 200 && data.meta.requestStatus === 'fulfilled') {
        // setInsights(data.payload.data);
      }
    } catch (error) {
      errorHandler(error);
    }
  };
  const handleDone = () => {
    setDone(true);
  };
  const sendHandler = () => {
    setConversation((prev) => [...prev, { ...message }]);
  };
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setMessage({ ...message, [name]: value });
  };

  const handleFileSelect = (file) => {
    const index = uploadedImages.length;
    const imageUrl = URL.createObjectURL(file);
    const image = {
      name: file.name,
      type: file.type,
      size: file.size,
      progress: 0,
      url: imageUrl
    };
    setUploadedImages([...uploadedImages, image]);

    if (!uploadedImages.length) {
      setSteps(1);
    }

    setTimeout(() => {
      setUploadedImages((images) => {
        const res = [...images];
        res[index].progress = 100;
        return res;
      });
      setTimeout(() => {
        setUploadedImages((images) => {
          const res = [...images];
          res[index].completed = true;
          return res;
        });
      }, 500);
    }, 1400);
  };

  const onFileDelete = (index) => {
    setUploadedImages((images) => {
      const res = [...images];
      res.splice(index, 1);
      return res;
    });
  };

  const handleSaveImages = () => {
    if (uploadedImages.length === 0) {
      toast.error('Please upload at least one photo.');
      return;
    }
    let recommendationsText;

    if (uploadedImages.length > 1) {
      recommendationsText = `Great! After analyzing your photos, I have some recommendations to improve your chances.\n1. Adjust lighting on ${uploadedImages[0].name}.\n2. Focus on specific features on ${uploadedImages[1].name}.`;
    } else if (uploadedImages.length === 1) {
      recommendationsText = `Great! After analyzing your photos, I have some recommendations to improve your chances.\n1. Adjust lighting on ${uploadedImages[0].name}.`;
    } else {
      recommendationsText = `No photos uploaded. Please upload at least one photo for analysis.`;
    }

    setConversation([
      ...conversation,
      {
        sender: 'AI',
        text: `Upload high quality photos of your property. Drag and drop or select photos from your device. It must be:\n- JPG, PNG or JPEG\n- file size no more than 10MB\n- limit of photos is 50.`,
        images: uploadedImages
      },
      {
        sender: 'AI',
        text: recommendationsText
      }
    ]);

    setSteps(2);
  };

  const approveUploads = () => {
    setSteps(3);
    setConversation([
      ...conversation,
      {
        sender: 'AI',
        text: editedDescription
      }
    ]);
  };

  const deleteAllButtonsFromChat = () => {
    setConversation(conversation.filter((message) => !message.buttons));
  };

  const finishUploads = () => {
    setSteps(4);
    setConversation([
      ...conversation,
      {
        buttons: [
          {
            variant: 'standard',
            text: 'Re-generate',
            icon: <AIAvatarOutlined />
          },
          {
            variant: 'standard',
            text: 'Edit',
            icon: <PencilOutlined />,
            onClick: () => {
              alert('test');
            }
          },
          {
            variant: 'contained',
            text: 'Approve'
          }
        ]
      },
      {
        sender: 'User',
        text: 'Add more details about the propperty'
      },
      {
        sender: 'AI',
        text: moreDetailsProperty
      },
      {
        buttons: [
          {
            variant: 'standard',
            text: 'Re-generate',
            icon: <AIAvatarOutlined />
          },
          {
            variant: 'standard',
            text: 'Edit',
            icon: <PencilOutlined />,
            onClick: () => {
              setIsEdit(true);
            }
          },
          {
            variant: 'contained',
            text: 'Approve',
            onClick: () => {
              setSteps(5);
              deleteAllButtonsFromChat();
              setConversation([
                ...conversation,
                {
                  sender: 'User',
                  text: `Yep. I like it!`
                },
                {
                  sender: 'AI',
                  text: `Your preferred criteria for pet policy?`
                },
                {
                  sender: 'User',
                  text: `No smoking`
                },
                {
                  sender: 'AI',
                  text: `The relevant market insights based on your answers:`
                },
                {
                  budget: true,
                  price: '2,500.-',
                  properties: [
                    {
                      address: 'PLZ 8057 Zürich',
                      price: '2,500.-',
                      progress: 70
                    },
                    {
                      address: 'PLZ 8057 Zürich',
                      price: '2,300.-',
                      progress: 60
                    },
                    {
                      address: 'PLZ 8057 Zürich',
                      price: '1,800.-',
                      progress: 40
                    }
                  ]
                },
                {
                  sender: 'User',
                  text: `OK, I agree`
                },
                {
                  sender: 'AI',
                  text: `Set up application filters to help you refine your tenant search:`
                },
                {
                  filter: true,
                  income: [9500, 12500],
                  creditScore: [9500, 12500],
                  employment: [
                    'fullTime',
                    'partTime',
                    'apprenticeship',
                    'traineeship',
                    'internship',
                    'casualEmployment',
                    'employmentOnCommission',
                    'contract',
                    'probation',
                    'seasonal',
                    'leased',
                    'contingent'
                  ]
                }
              ]);
            }
          }
        ]
      }
    ]);
  };

  const showOverview = () => {
    setSteps(6);
    setConversation([
      ...conversation,
      {
        sender: 'AI',
        text: 'Here is the summary based on your answers:'
      },
      {
        overview: true
      },
      {
        sender: 'AI',
        text: 'Check all your parameters. Do you want to edit anything or everything is good?'
      }
    ]);
  };

  useEffect(() => {
    const getPropertyData = async () => {
      const [_, propertyImagesData] = await Promise.all([
        dispatch(getProperty(id)),
        dispatch(getPropertyImages(id))
      ]);
      const propertyImages = propertyImagesData.payload;

      setSteps(6);
      setConversation([
        {
          sender: 'AI',
          text: 'Here is the summary based on your answers:'
        },
        {
          overview: true
        },
        {
          sender: 'AI',
          text: 'Check all your parameters. Do you want to edit anything or everything is good?'
        }
      ]);
      setUploadedImages(
        [...propertyImages].splice(0, 5).map(({ image_url }) => ({
          url: image_url,
          name: 'text.png'
        }))
      );
    };

    if (id) {
      getPropertyData();
    }
  }, [location, id, dispatch]);

  const buttons = {
    1: [
      {
        variant: 'standard',
        text: 'I want to delete all',
        onClick: () => setUploadedImages([])
      },
      {
        variant: 'contained',
        text: "I'm done",
        onClick: handleSaveImages
      }
    ],
    2: [
      {
        variant: 'standard',
        text: 'I want to replace photos',
        onClick: () => setSteps(1)
      },
      {
        variant: 'contained',
        text: "I'm fine, don't want to change anything",
        onClick: approveUploads
      }
    ],
    3: [
      {
        variant: 'standard',
        text: 'Re-generate',
        icon: <AIAvatarOutlined />
      },
      {
        variant: 'standard',
        text: 'Edit',
        icon: <PencilOutlined />,
        onClick: () => {
          setSteps(1);
          setIsEdit(true);
        }
      },
      {
        variant: 'contained',
        text: 'Approve',
        onClick: finishUploads
      }
    ],
    5: [
      {
        variant: 'standard',
        text: 'Reset filters',
        onClick: () => {
          if (resetFilter.current) {
            resetFilter.current.resetFilter([9500, 12500], [9500, 12500], {
              fullTime: false,
              partTime: false,
              apprenticeship: false,
              traineeship: false,
              internship: false,
              casualEmployment: false,
              employmentOnCommission: false,
              contract: false,
              probation: false,
              seasonal: false,
              leased: false,
              contingent: false
            });
          }
        }
      },
      {
        variant: 'contained',
        text: "I'm done",
        onClick: showOverview
      }
    ],
    6: [
      {
        variant: 'standard',
        text: 'I want to edit'
      },
      {
        variant: 'contained',
        text: 'Confirming and publishing the ad',
        onClick: () => navigate('/owner-account?section=listing')
      }
    ]
  };

  return (
    <LoggedInLayout hideSubscription hideProfile={!isChatCompleted}>
      <Box
        sx={{
          height: 'calc(100vh - 64px)'
        }}>
        <Grid
          sx={{
            px: 5,
            marginTop: 0,
            height: '100%'
          }}
          container
          spacing={2}>
          <Grid
            sx={{
              borderRight: '1px solid #00002F26',
              height: '100%',
              overflowY: 'scroll',
              scrollbarWidth: 'none',
              '&::-webkit-scrollbar': {
                display: 'none'
              }
            }}
            item
            xs={2.5}>
            {!isChatCompleted ? (
              <Box sx={{ pr: 0.5, pt: 2.5 }}>
                <Typography
                  sx={{
                    pb: 2,
                    color: 'black',
                    fontWeight: 600
                  }}>
                  {t('Search Property')}
                </Typography>
                <VerticalLinearStepper />
              </Box>
            ) : (
              <Box sx={{ pr: 5, pt: '20px' }}>
                <NewChat prevVisitedList={prevVisitedList} />
              </Box>
            )}
          </Grid>

          {/* MessageScreen Section */}
          <Grid
            item
            xs={7}
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              pt: '0!important',
              pr: '16px',
              pb: '16px',
              '& .MuiGrid-item': {
                paddingLeft: '30px !important',
                paddingTop: 0
              }
            }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                overflowY: 'scroll',
                scrollbarWidth: 'none',
                '&::-webkit-scrollbar': {
                  display: 'none'
                }
              }}>
              <Box sx={{ padding: 4 }}>
                <Typography
                  sx={{
                    color: 'black',
                    fontWeight: 600,
                    fontSize: '20px'
                  }}>
                  {t("Hello! I'm here to help you with the best choose.")}
                </Typography>
                <Typography sx={{ fontSize: '12px' }}>
                  {t("Hello! I'm here to help you with the best choose.")}
                </Typography>
              </Box>
              <Box
                ref={boxRef}
                sx={{
                  height: '100%'
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '30px',
                    padding: '25px'
                  }}>
                  {conversation.map((message, index) => {
                    return message.buttons ? (
                      <ChatButtons buttons={message.buttons} />
                    ) : message.budget ? (
                      <BudgetCard budget={message} />
                    ) : message.filter ? (
                      <ChatFilter key={index} filter={message} ref={resetFilter} />
                    ) : message.overview ? (
                      <PropertySummary uploadedImages={uploadedImages} noPadding />
                    ) : (
                      <MessageItem
                        key={index}
                        message={message}
                        isEdit={(() => {
                          return (
                            isEdit &&
                            (index === conversation.length - 1 ||
                              message.text.startsWith(
                                'Here your property description based on your uploaded photos'
                              ))
                          );
                        })()}
                        onEditDescription={handleEditDescription}
                      />
                    );
                  })}
                </Box>

                {[0, 1].includes(steps) && (
                  <Box sx={{ p: 2, mt: 2 }}>
                    <Upload
                      images={uploadedImages}
                      onFileSelect={handleFileSelect}
                      onFileDelete={onFileDelete}
                    />
                  </Box>
                )}
                <div ref={scrollRef} />
              </Box>
            </Box>

            {buttons[steps] ? (
              <ButtonsBox
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                {buttons[steps].map(({ variant, icon, text, onClick }) => (
                  <StyledButton
                    sx={{ height: 40 }}
                    onClick={onClick}
                    bgColor={variant}
                    variant="contained">
                    {icon} {text}
                  </StyledButton>
                ))}
              </ButtonsBox>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'center'
                }}>
                <MessageField onChangeHandler={onChangeHandler} sendHandler={sendHandler} />
              </Box>
            )}
          </Grid>

          {/* Insights Section */}
          <Grid
            sx={{
              borderLeft: '1px solid #00002F26',
              height: '100%',
              overflowY: 'scroll',
              scrollbarWidth: 'none',
              '&::-webkit-scrollbar': {
                display: 'none'
              }
            }}
            item
            xs={2.5}>
            <Box
              sx={{
                height: '100%'
              }}>
              <Insights adjust={isChatCompleted} title={t('Insights')} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </LoggedInLayout>
  );
};

export default ChatOwner;
