import React, { useRef, useState } from "react";
import { Box, Typography, Avatar, Button, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import MyProperties from "./MyProperties/MyProperties";
import { Note } from "../../../assets/svg/Note";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import MyContact from "./MyContact/MyContact";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Notes from "./Notes/Notes";
import Subscription from "./Subscription/Subscription";
import BillingHistory from "./BillingHistory/BillingHistory";
import { useDispatch } from "react-redux";
import { updateProfileAvatar } from "../../../store/slices/accountSlice";
import { useTranslation } from "react-i18next";

const Sidebar = styled(Box)(({ theme }) => ({
  width: 250,
  minWidth: "250px",
  padding: theme.spacing(3),
  borderRight: `1px solid ${theme.palette.divider}`,
}));

const NavItem = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(1, 0),
  cursor: "pointer",
  "&:hover": {
    color: theme.palette.primary.main,
  },
}));

const AvatarWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  width: 80,
  height: 80,
  margin: "auto",
  marginBottom: theme.spacing(1),
  "&:hover .avatarOverlay": {
    opacity: 1,
  },
}));

const AvatarOverlay = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
  opacity: 0,
  transition: "opacity 0.3s",
}));

const MyAccount = ({ data }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dispatch = useDispatch();
  const searchTerm = queryParams.get("section") || "property";
  const avatarRef = useRef();
  const [avatarHovered, setAvatarHovered] = useState(false);

  const handleClick = (link) => {
    navigate(link);
  };

  const onAvatarUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      dispatch(updateProfileAvatar(file));
    }
  };

  return (
    <Box sx={{ display: "flex", minHeight: "100vh" }}>
      <Sidebar>
        <Box sx={{ mt: "25px" }}>
          <Typography
            sx={{ color: "#202020", fontWeight: 500, fontSize: "16px" }}
          >
            {t("Search Property")}
          </Typography>
          <Box
            sx={{
              marginTop: "32px",
              marginBottom: "32px",
              border: "1px solid #ddd",
            }}
          />
        </Box>
        <Box sx={{ textAlign: "center", mb: 3 }}>
          <AvatarWrapper
            onMouseEnter={() => setAvatarHovered(true)}
            onMouseLeave={() => setAvatarHovered(false)}
          >
            <IconButton
              sx={{
                width: "100%",
                height: "100%",
                padding: 0,
              }}
              onClick={() => avatarRef.current.click()}
            >
              <Avatar
                src={
                  data?.avatar_url
                    ? data.avatar_url
                    : "https://via.placeholder.com/100"
                }
                sx={{
                  width: "100%",
                  height: "100%",
                }}
              />
              <AvatarOverlay className="avatarOverlay">
                <CameraAltIcon sx={{ color: "white" }} />
              </AvatarOverlay>
            </IconButton>
          </AvatarWrapper>
          <input
            accept="image/png, image/jpeg, image/svg+xml"
            onChange={onAvatarUpload}
            ref={avatarRef}
            type="file"
            hidden
          />
          <Box
            onClick={() => handleClick("/edit-profile")}
            sx={{
              textAlign: "center",
              mb: 2,
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <Typography variant="h6">{`${data.firstname} ${data.lastname}`}</Typography>
            <Box sx={{ marginLeft: "12px", marginTop: "8px" }}>
              <Note />
            </Box>
          </Box>
          {data.country && (
            <Typography variant="body2" color="text.secondary">
              {data.country}
            </Typography>
          )}
        </Box>
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          fullWidth
          color="primary"
          sx={{
            backgroundColor: "transparent",
            color: "#1C2024",
            borderColor: "#ddd",
            marginBottom: "40px",
          }}
          onClick={() => handleClick("/chat")}
        >
          {t("New chat")}
        </Button>
        <NavItem
          onClick={() => handleClick("/my-account?section=property")}
          variant="body1"
          color={searchTerm.includes("property") ? "primary" : ""}
        >
          {t("My Properties")}
        </NavItem>
        <NavItem
          onClick={() => handleClick("/my-account?section=contact")}
          variant="body1"
          color={searchTerm.includes("contact") ? "primary" : ""}
        >
          {t("Contact Manager")}
        </NavItem>
        <NavItem
          onClick={() => handleClick("/my-account?section=notes")}
          color={searchTerm.includes("notes") ? "primary" : ""}
          variant="body1"
        >
          {t("Notes")}
        </NavItem>
        <NavItem
          onClick={() => handleClick("/my-account?section=subscription")}
          color={searchTerm.includes("subscription") ? "primary" : ""}
          variant="body1"
        >
          {t("Subscription")}
        </NavItem>
        {/* <NavItem
          onClick={() => handleClick("/my-account?section=payments")}
          color={searchTerm.includes("payments") ? "primary" : ""}
          variant="body1"
        >
          Payments
        </NavItem> */}
        <NavItem
          onClick={() => handleClick("/my-account?section=billing-history")}
          color={searchTerm.includes("billing-history") ? "primary" : ""}
          variant="body1"
        >
          {t("Billing History")}
        </NavItem>
      </Sidebar>
      {searchTerm === "property" ? (
        <MyProperties />
      ) : searchTerm === "notes" ? (
        <Notes userId={data.id} />
      ) : searchTerm === "billing-history" ? (
        <BillingHistory />
      ) : searchTerm === "subscription" ? (
        <Subscription />
      ) : (
        // : searchTerm === "payments" ? (
        //   <Payments />
        // )
        <MyContact userId={data.id} />
      )}
    </Box>
  );
};

export default MyAccount;
