import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { theme } from "../../theme/theme";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { jsonData } from "../../db";
import UpgradeModal from "../modals/upgradeModal";
import { useTranslation } from "react-i18next";

import { useHover } from "./../../hooks/useHover";

const buttonStyling = {
  width: "100%",
  backgroundColor: "transparent",
  color: theme.palette.text.primary,
  border: `1px solid #848a98`,
  height: "48px",
};

const NewChat = ({ prevVisitedList, onClick, onDeleteClick }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const [hoverRef, isHovered] = useHover();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box ref={hoverRef} id="NewChat">
      <Box>
        <Button
          fullWidth
          sx={buttonStyling}
          onClick={handleOpen}
          startIcon={<AddIcon />}
        >
          {t("New Chat")}
        </Button>
      </Box>
      <UpgradeModal
        translate
        currentPlan={jsonData.currentPlan}
        upgradePlan={jsonData.upgradePlan}
        open={open}
        onClose={handleClose}
      />

      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, pt: 5 }}>
        {Object.keys(prevVisitedList).map((key) => (
          <Box key={key} sx={{ mb: 3 }}>
            <Typography sx={{ fontWeight: 500, color: "black", mb: 1 }}>
              {key}
            </Typography>

            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              {prevVisitedList[key].map((item, index) => (
                <Box
                  id="prevVisitedList[key].map"
                  onClick={onClick}
                  key={index}
                  sx={{
                    backgroundColor: item.selected && "#edf2fe",
                    color: "#4f6ece",
                    borderRadius: "6px",
                    padding: "6px 10px",
                    fontSize: "14px",
                    display: "flex",
                    gap: 1,
                    position: "relative",
                    cursor: "pointer",

                    "&:hover": {
                      backgroundColor: "#d0e4fd", // Adjust to your desired hover color
                    },
                  }}
                >
                  <Box>
                    <ChatBubbleOutlineIcon
                      sx={{
                        fontSize: "16px",
                        mt: 0.3,
                        color: theme.palette.text.secondary,
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      position: "relative",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "black",
                      }}
                    >
                      {item.location}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "10px",
                        }}
                      >
                        {`${t("Rooms")}: `}
                        {item.rooms}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "10px",
                        }}
                      >
                        {item.size}
                      </Typography>
                    </Box>
                  </Box>

                  {(item.selected || isHovered) && (
                    <Box>
                      <DeleteOutlineIcon
                        onClick={(e) => {
                          e.stopPropagation();
                          onDeleteClick();
                        }}
                        sx={{
                          position: "absolute",
                          top: 8,
                          right: 8,
                          fontSize: "15px",
                          color: theme.palette.text.secondary,

                          transition: "transform 0.2s ease", // Smooth transition effect
                          "&:hover": {
                            transform: "scale(1.2)", // Increases size by 1.2x on hover
                          },
                        }}
                      />
                    </Box>
                  )}
                </Box>
              ))}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default NewChat;
