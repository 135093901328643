import React from "react";
import { AppBar, Toolbar, Typography, Button, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import ImageComponent from "../Image/Image";
import { useTranslation } from "react-i18next";

// You'll need to import your actual logo
// import LogoIcon from "./path-to-your-logo-icon";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: "white",
  boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.1)",
}));

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0 16px",
});

const NavLinks = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
}));

const NavLink = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  textTransform: "none",
  "&:hover": {
    backgroundColor: "transparent", // Prevent hover background
  },
}));

const LoggedInFooter = () => {
  const { t } = useTranslation();

  return (
    <StyledAppBar position="static" sx={{ borderTop: `1px solid #ddd}` }}>
      <StyledToolbar>
        <Box display="flex" alignItems="center">
          <ImageComponent name="logo" height={26} alt="HOME AI Logo" />
        </Box>

        <NavLinks className='mobile-links'>
          <NavLink sx={{ color: "#1F2D5C" }}>
            {t("Search property")}
          </NavLink>
          <NavLink sx={{ color: "#1F2D5C" }}>
            {t("Privacy Policy")}
          </NavLink>
          <NavLink sx={{ color: "#1F2D5C" }}>
            {t("Terms and Conditions")}
          </NavLink>
          <NavLink sx={{ color: "#1F2D5C" }}>
            {t("Support")}
          </NavLink>
        </NavLinks>

        <Typography variant="body2" color="text.secondary">
          © 2024 Home AI, LLC
        </Typography>
      </StyledToolbar>
    </StyledAppBar>
  );
};

export default LoggedInFooter;
